<!-- inicio modals -->
<div class="backdrop" [ngStyle]="{'display':visible?'block':'none'}"></div>
<div class="basic modal" tabindex="-1" role="dialog" [ngStyle]="{'display':visible?'block':'none'}" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered chris-modal" role="document" [style.max-width]="maxWidth">
        <div class="modal-content">
        <div class="modal-body">
            <button type="button" (click)="close()" class="close white-close" data-dismiss="modal"
            aria-label="Close">
            <i style="background: #BA1C2D;height: 20px;width: 20px; border-radius: 50px;font-size: 9pt;line-height: 20px;" class="fa fa-times"></i>
            </button>
                <h4 style="color:#727272" class="modal-title text-center">{{title}}</h4>
                <hr>
                <ng-content select="[body]"></ng-content>
        </div>
        </div>
    </div>
</div>