<div class="container-fluid">
<div class="row">
<div class="datatable col-12">
    <ngx-datatable #myTable class="admin-table data-table selection-cell" [ngClass]="tablestyle" [rows]="rows"
        [columnMode]="'force'" (sort)="sortEvent($event)" [externalSorting]="true" [rowHeight]="'auto'" [reorderable]="false" [headerHeight]="50"
        [footerHeight]="50" [limit]="limit" [scrollbarH]="true" [count]="rows?rows.length:0" [offset]="0"
        >

        <ngx-datatable-row-detail [rowHeight]="150" #myDetailRow (toggle)="onDetailToggle($event)" *ngIf="detailTemplate">
          <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
            <ng-template
            [ngTemplateOutlet]="detailTemplate"
              [ngTemplateOutletContext]="{ row: row }"
            ></ng-template>
          </ng-template>
        </ngx-datatable-row-detail>

        <!-- Column Templates -->
        <ngx-datatable-column *ngIf="detailTemplate"
          [width]="50"
          [resizeable]="false"
          [sortable]="false"
          [draggable]="false"
          [canAutoResize]="false"
        >
          <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
            <a
              href="javascript:void(0)"
              [class.datatable-icon-right]="!expanded"
              [class.datatable-icon-down]="expanded"
              title="Expandir / Contraer"
              (click)="toggleExpandRow(row)"
            >
            </a>
          </ng-template>
        </ngx-datatable-column>
        <!-- Row Detail Template -->
        <!-- <ngx-datatable-row-detail
          [rowHeight]="100"
          #myDetailRow
          (toggle)="onDetailToggle($event)">
          <ng-template
            let-row="row"
            let-expanded="expanded"
            ngx-datatable-row-detail-template>
            <div style="padding-left:35px;">
              =====Open Detail=====

            </div>
          </ng-template>
        </ngx-datatable-row-detail> -->

        <!-- Column Templates -->
        <!-- <ngx-datatable-column
        [width]="50"
        [resizeable]="false"
        [sortable]="false"
        [draggable]="false"
        [canAutoResize]="false"
        [frozenLeft]="true"
        name ="'open'">
        <ng-template
          let-row="row"
          let-rowIndex="rowIndex"
          let-expanded="expanded"
          ngx-datatable-cell-template>
          <a
           *ngIf="isCheck"
            href="javascript:void(0)"
            [class.datatable-icon-right]="!expanded"
            [class.datatable-icon-down]="expanded"
            title="Expand/Collapse Row"
            (click)="toggleExpandRow(row)">open
          </a>
        </ng-template>
      </ngx-datatable-column> -->


        <ng-container *ngFor="let column of columnList">
            <ng-container *ngIf="column.isDate==undefined;else isDate">
                <ngx-datatable-column name="{{column.text}}" prop="{{column.name}}" [width]="column.width || 150">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <span [ngSwitch]="column.name">
                        <span *ngSwitchCase="'voucher_state_name'">
                          <span class="estados-adm" [ngClass]="{'estados-adm-activo': row[column.name]=='Activada', 'estados-adm-inactivo': row[column.name]=='Desactivada'}">{{row[column.name]}}</span>
                        </span>
                        <span *ngSwitchCase="'proceso'">
                          <span class="proceso" [ngClass]="{'encurso': row[column.name]=='En curso', 'terminado': row[column.name]=='Terminado', 'pendiente': row[column.name]=='Pendiente'}">{{row[column.name]}}</span>
                        </span>
                        <span *ngSwitchCase="'vigencia'">
                          <span class="vigencia" [ngClass]="{'activo': row[column.name]=='Activo', 'inactivo': row[column.name]=='Inactivo', 'problemas': row[column.name]=='Problemas'}">{{row[column.name]}}</span>
                        </span>
                        <span *ngSwitchCase="'completado'">
                          <div class="progress" role="progressbar">
                            <div class="progress-bar bg-primary" role="progressbar" [style.width]="row[column.name] + '%'">{{row[column.name]}}%</div>
                          </div>
                        </span>
                        <span *ngSwitchDefault>
                          <ng-container *ngIf="row['_styles'] &&
                          row['_styles'][column['name']] &&
                          row['_styles'][column['name']]['label'] &&
                          (row[column.name] &&
                          ((column.transformFcn != undefined &&
                          column.transformFcn(row) != undefined)  ||
                          row[column.name].length>0) ); else default">
                            <div class="label-outline">
                              <span [ngClass]="row['_styles'][column['name']]['label']['class']">
                                {{column.transformFcn != undefined? column.transformFcn(row): row[column.name]}}
                              </span>
                            </div>
                          </ng-container>
                          <ng-template #default>
                            {{column.transformFcn != undefined? column.transformFcn(row): row[column.name]}}

                          </ng-template>
                        </span>
                      </span>
                    </ng-template>
                </ngx-datatable-column>
            </ng-container>

            <ng-template #isDate>
                <ngx-datatable-column name="{{column.text}}" [width]="column.width || 150">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row[column.name]|date:'dd/MM/yyyy'}}
                    </ng-template>
                </ngx-datatable-column>
            </ng-template>

        </ng-container>

        <ngx-datatable-column name="Opciones">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <!-- <div class="btn-group" role="group">
                    <button type="button" (click)="editar(row)" class="btn edit-btn-solo btn-sm"><i
                            class="fa fa-trash"></i>Editar</button> -->
                    <!--<button type="button" (click)="eliminar(row.name)" class="btn del-btn fix-btn btn-sm"><i class="fa fa-trash"></i>Eliminar</button>-->
                <!-- </div> -->
                <app-button-group [buttonList]="getNotHiddenActionBtnList(row)" (onClick)="onClickActionBtn(row, $event)">
                </app-button-group>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
            <!-- <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
                <div class="page-count">
                    <span *ngIf="selectedMessage">
                        {{selectedCount.toLocaleString()}} {{selectedMessage}} /
                    </span>
                    {{rowCount.toLocaleString()}} {{totalMessage}}
                </div>
                <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                    [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
                    [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
                    [hidden]="!((rowCount / pageSize) > 1)" (change)="table.onFooterPage($event)">
                </datatable-pager>
            </ng-template> -->


        </ngx-datatable-footer>
    </ngx-datatable>
    <app-point-cross-loader *ngIf="isLoading"></app-point-cross-loader>
    <span style="border-radius:20px;background:#CF2C42;padding:5px 15px;color:#fff;font-size: 9pt">Total: {{totalCount}}</span>
    <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center">
          <li class="page-item disabled" [ngClass]="{'disabled': currentPage==1}">
            <button class="page-link" (click)="clickPaginationBtn('prev')">Anterior</button>
          </li>
          <li class="page-item" [ngClass]="{'active': currentPage==page}" *ngFor="let page of pageList">
              <button class="page-link" (click)="clickPaginationBtn(page)">{{page}}</button>
            </li>
          <li class="page-item" [ngClass]="{'disabled': currentPage==lastPage}">
            <button class="page-link" (click)="clickPaginationBtn('next')">Siguiente</button>
          </li>
        </ul>
    </nav>

</div>
</div>
</div>
