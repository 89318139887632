import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {EChartsOption, EChartsType} from "echarts";
import _ from "lodash";
import * as echarts from "echarts";
import {D3JS_SCHEME, ColorGenerator} from "../../../../core/util/color.util";

@Component({
  selector: 'app-estacked-bars',
  templateUrl: './estacked-bars.component.html',
  styleUrl: './estacked-bars.component.scss'
})
export class EstackedBarsComponent implements OnInit, OnChanges{
  @ViewChild('wrapper', {static: true}) wrapper!: ElementRef;
  @Input() data: any[] = [];
  @Input() categories: any[] = [];
  @Input() legends: string[] = [];
  @Input() colorMap: string[] = D3JS_SCHEME;
  @Input() loading: boolean = false;

  loadingOption = {
    graphic: {
      elements: [
        {
          type: 'group',
          left: 'center',
          top: 'center',
          children: new Array(7).fill(0).map((val, i) => ({
            type: 'rect',
            x: i * 20,
            shape: {
              x: 0,
              y: -40,
              width: 10,
              height: 80
            },
            style: {
              fill: '#5470c6'
            },
            keyframeAnimation: {
              duration: 1000,
              delay: i * 200,
              loop: true,
              keyframes: [
                {
                  percent: 0.5,
                  scaleY: 0.3,
                  easing: 'cubicIn'
                },
                {
                  percent: 1,
                  scaleY: 1,
                  easing: 'cubicOut'
                }
              ]
            }
          }))
        }
      ]
    }
  };

  chartOption: EChartsOption = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      /*formatter: function (params: any) {
        console.log("formatter params: ", params)
        params = params[0];
        console.log("formatter param: ", params)
        return `hola ${params.data}`;
      },*/
    },
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: 'none'
        },
        restore: {},
        saveAsImage: {}
      }
    },
    dataZoom: [
      {
        type: 'slider',
        start: 0,
        end: 100
      },
    ],
    legend: {
      // Try 'horizontal'
      orient: 'horizontal',
      top: 30
      //right: 10,
      //top: 'center'
    },
    title: {
      left: 'center',
      text: ''
    },
    xAxis: {
      type: 'category',
      //data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    },
    yAxis: {
      type: 'value',
    },
    series: [
      /*{
        data: [820, 932, 901, 934, 1290, 1330, 1320],
        type: 'bar',
        label: {
          // Options: 'left', 'right', 'top', 'bottom', 'inside', 'insideTop', 'insideLeft', 'insideRight', 'insideBottom', 'insideTopLeft', 'insideTopRight', 'insideBottomLeft', 'insideBottomRight'
          position: 'top',
          distance: 10,

          show: true,
          formatter: ['Label Text'].join('\n'),
          backgroundColor: '#eee',
          borderColor: '#555',
          borderWidth: 2,
          borderRadius: 5,
          padding: 10,
          //fontSize: 18,
          //shadowBlur: 3,
          //shadowColor: '#888',
          //shadowOffsetX: 0,
          //shadowOffsetY: 3,
          textBorderColor: '#000',
          textBorderWidth: 3,
          color: '#fff'
        }
      },*/
    ],
    grid: {
      containLabel: true,
      left: '3%',
      right: '4%',
      bottom: '50',
    },
    graph: {
      color: D3JS_SCHEME
    }
  };
  private chart!: EChartsType;
  constructor(){

  }

  ngOnInit(){
    this.buildChart()
  }

  buildChart(){
    console.log("LINE BUILD CHART")
    this.chart = echarts.init(this.wrapper.nativeElement);
    this.chart.setOption(this.chartOption)
  }

  rebuildChart(){
    this.removeChart();
    this.buildChart();
  }

  removeChart(){
    try {
      this.chart.dispose();
    }catch(err){console.log(err)}
  }

  showLoading(){
    console.log("LINE SHOW LOADING")
    this.removeChart();
    this.chart = echarts.init(this.wrapper.nativeElement);
    this.chart.setOption(this.loadingOption);
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['data']){
      const colorGen = ColorGenerator(this.colorMap);
      console.log("BAR DATA CHANGES ", changes['data'].currentValue)
      this.chartOption.xAxis['data'] = _.get(changes, 'categories.currentValue', this.categories) as any;
      const data: any[] = _.get(changes, '["data"].currentValue', []) as any;
      const legends = Array.from(this.legends);
      const series: any = data.map((d: any)=>{//se asume data es matriz
        return {
          data: d,
          type: 'bar',
          stack: 'total',
          label: {
            show: false
          },
          /*emphasis: {
            focus: 'series'
          },*/
          name: legends.shift(),
          itemStyle: { normal: { color: colorGen.generate() } },
        }
      })
      if(this.chart) {
        this.chart.setOption({
          series: series,
        });
      }
      this.chartOption.series = series;
      console.log("chart options: ", this.chartOption)
    }
    if(changes['categories']){
      this.chartOption.xAxis['data'] = _.get(changes, 'categories.currentValue', this.categories) as any;
      if(this.chart) {
        this.chart.setOption({
          xAxis: Object.assign({}, this.chartOption.xAxis)
        });
      }
    }
    if(changes['loading']){
      if(changes['loading'].currentValue == true) {
        this.showLoading();
      }else{
        this.rebuildChart();
      }
    }
  }

  resize(){
    if(this.chart)
      this.chart.resize();
  }
}
