import { Component, Input} from '@angular/core';
@Component({
    selector: 'app-filled-field',
    templateUrl: './filled-field.component.html',
    styleUrls: ['./filled-field.component.scss',

    ]
})
export class FilledFieldComponent{
    @Input() text!: string;
    @Input() label!: string;

    // columnClass: string = "col-sm-6";

    constructor(
    ) {

    }
}