import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {EChartsOption, EChartsType} from "echarts";
import * as echarts from "echarts";
import _ from "lodash";
import {ColorGenerator, D3JS_SCHEME} from "../../../../core/util/color.util";

@Component({
  selector: 'app-eline',
  templateUrl: './eline.component.html',
  styleUrl: './eline.component.scss'
})
export class ElineComponent implements OnInit, OnChanges{
  @ViewChild('wrapper', {static: true}) wrapper!: ElementRef;
  @Input() data: any[] = [];
  @Input() categories: any[] = [];
  @Input() legends: string[] = [];
  @Input() colorMap: string[] = D3JS_SCHEME;
  @Input() loading: boolean = false;

  loadingOption: any = {
    graphic: {
      elements: [
        {
          type: 'group',
          left: 'center',
          top: 'center',
          children: new Array(7).fill(0).map((val, i) => ({
            type: 'rect',
            x: i * 20,
            shape: {
              x: 0,
              y: -40,
              width: 10,
              height: 80
            },
            style: {
              fill: '#5470c6'
            },
            keyframeAnimation: {
              duration: 1000,
              delay: i * 200,
              loop: true,
              keyframes: [
                {
                  percent: 0.5,
                  scaleY: 0.3,
                  easing: 'cubicIn'
                },
                {
                  percent: 1,
                  scaleY: 1,
                  easing: 'cubicOut'
                }
              ]
            }
          }))
        }
      ]
    }
  };

  chartOption: EChartsOption = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      // Try 'horizontal'
      orient: 'horizontal',
      top: 30,
      icon: 'circle'
      //right: 10,
      //top: 'center'
    },
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: 'none'
        },
        restore: {},
        saveAsImage: {}
      }
    },
    dataZoom: [
      {
        start: 0,
        end: 100
      }
    ],
    title: {
      left: 'center',
      text: ''
    },
    xAxis: {
      type: 'category',
      //data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    },
    yAxis: {
      type: 'value',
    },
    series: [
      /*{
        data: [820, 932, 901, 934, 1290, 1330, 1320],
        type: 'line',
        label: {
          // Options: 'left', 'right', 'top', 'bottom', 'inside', 'insideTop', 'insideLeft', 'insideRight', 'insideBottom', 'insideTopLeft', 'insideTopRight', 'insideBottomLeft', 'insideBottomRight'
          position: 'top',
          distance: 10,

          show: true,
          formatter: ['Label Text'].join('\n'),
          backgroundColor: '#eee',
          borderColor: '#555',
          borderWidth: 2,
          borderRadius: 5,
          padding: 10,
          //fontSize: 18,
          //shadowBlur: 3,
          //shadowColor: '#888',
          //shadowOffsetX: 0,
          //shadowOffsetY: 3,
          textBorderColor: '#000',
          textBorderWidth: 3,
          color: '#fff'
        }
      },*/
    ],
  };
  private chart!: EChartsType;
  constructor(){

  }

  ngOnInit(){
    this.buildChart()
  }

  buildChart(){
    console.log("LINE BUILD CHART")
    this.chart = echarts.init(this.wrapper.nativeElement);
    this.chart.setOption(this.chartOption)
  }

  rebuildChart(){
    this.removeChart();
    this.buildChart();
  }

  removeChart(){
    try {
      this.chart.dispose();
    }catch(err){console.log(err)}
  }

  showLoading(){
    console.log("LINE SHOW LOADING")
    this.removeChart();
    this.chart = echarts.init(this.wrapper.nativeElement);
    this.chart.setOption(this.loadingOption);
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['data']){
      const colorGen = ColorGenerator(this.colorMap);
      console.log("LINE DATA CHANGES ", changes['data'].currentValue)
      const data = _.get(changes, '["data"].currentValue', []);
      let series: any [] = [];
      const legends = Array.from(this.legends);
      if(Array.isArray(data) && Array.isArray(_.get(data, '[0]', undefined))){// Es una matriz!
        series = data.map((d: any)=>{
          return {
            data: d,
            type: 'line',
            stack: 'x',
            name: legends.shift(),
            lineStyle: {color: colorGen.generate()},
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
          }
        })
      }else{
        series = [
          {
            data: data,
            type: 'line',
            name: legends.shift(),
            lineStyle: {color: colorGen.generate()},
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
          }
        ];
      }
      if(this.chart) {
        this.chart.setOption({
          series: series,
        });
      }
      this.chartOption.series = series;
    }
    if(changes['categories']){
      this.chartOption.xAxis['data'] = _.get(changes, 'categories.currentValue', this.categories) as any;
      if(this.chart) {
        this.chart.setOption({
          xAxis: Object.assign({}, this.chartOption.xAxis)
        });
      }
    }
    if(changes['legends']){
      this.chartOption.legend['data'] = _.get(changes, 'legends.currentValue', this.legends) as any;
      if(this.chart) {
        this.chart.setOption({
          legend: Object.assign({}, this.chartOption.legend)
        });
      }
    }
    console.log("chart options: ", this.chartOption)
    if(changes['loading']){
      if(changes['loading'].currentValue == true) {
        this.showLoading();
      }else{
        this.rebuildChart();
      }
    }
  }
}
