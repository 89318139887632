<div *ngIf="form; else noForm" [formGroup]="form">
    <span class="label" *ngIf="label">{{label}}</span>
    <ng-select *ngIf="allOptions.length>0" class="ng-select modal-ng-select" [formControlName]="name?name: null"
        [items]="allOptions" bindLabel="label" bindValue="value" [multiple]="multiple"
        (deselected)="onDeselected($event)" (change)="onChange_($event)"
         [dropdownPosition]="position" [placeholder]="placeholder" >
    </ng-select>
</div>
<ng-template #noForm>
    <span class="label" *ngIf="label">{{label}}</span>
    <ng-select *ngIf="allOptions.length>0" class="ng-select modal-ng-select" [ngClass]="customClass"
        [items]="allOptions" bindLabel="label" bindValue="value" [multiple]="multiple"
        (deselected)="onDeselected($event)" (change)="onChange_($event)" [placeholder]="placeholder"
         [dropdownPosition]="position">
    </ng-select>
</ng-template>
