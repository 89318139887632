import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import {DropdownPosition} from "@ng-select/ng-select";
@Component({
    selector: 'app-selectable',
    templateUrl: './selectable.component.html',
    styleUrls: ['./selectable.component.scss',

    ]
})
export class SelectableComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

    private subscriptions: Subscription[] = [];
    @Input() optionList: {label: string, value: any}[] = [];
    @Input() name: string|null = null;
    // @Input() formControlName: string = null;
    @Input() label: string|null|undefined = null;
    @Input() multiple: boolean = false;
    @Input() placeholder: string|null|undefined = null;
    @Input() position: DropdownPosition = 'bottom';
    @Output() onChange: EventEmitter<any> = new EventEmitter();
    // @Input() firstOption: {label: string, value: any} = {
    //     // label: "Seleccione un item", value: "null"
    // };
    @Input() form!: UntypedFormGroup;
    @Input() customClass: string = '';
    allOptions: {label:string, value:any}[] = [
        // this.firstOption
    ]
    constructor(
        // private cdr: ChangeDetectorRef
    ) {

    }
    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    ngOnInit() {
        this.allOptions = this.optionList;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.allOptions = this.optionList;
    }

    ngAfterViewInit() {
    }

    onSelected(event: any){
        console.dir(event);
        this.onChange.emit(event)
    }

    onDeselected(event: any){
        console.log("DESELECTED")
        console.dir(event);
        this.onChange.emit({value: -1, label: ''})
    }

    onChange_(event: any){
        console.dir(event);
        if(event && event.value) this.onSelected(event);
        else this.onDeselected(event);
    }
}
