import { environment } from "../../../../../environments/environment";
import { BeneficiaryPermission, PdiAdminPermission, PermissionBase, PlatformAdminPermission, ROLE } from "../models/permission.model";

export class PermissionFactory {
  public static instance: PermissionBase|null = null;
  private constructor() {}
  public static clean(){
    this.instance = null;
  }
  public static getInstance() {
    if (this.instance==null) {
      const role = localStorage.getItem(environment.role);
      console.log("permission factory role: ",role)
      console.dir({role})
      switch(role) {
        case ROLE.PLATFORM_ADMIN:
          this.instance = new PlatformAdminPermission();
          break;
        case ROLE.BENEFICIARY:
          this.instance = new BeneficiaryPermission();
          break;
        case ROLE.ADMIN:
          this.instance = new PdiAdminPermission();
          break;
        default:
          this.instance = new BeneficiaryPermission();
          break;
      }
    }
    return this.instance;
  }
}