<ng-content></ng-content>
<a class="type-toggle" (click)="toggleShow()">
	<i class="fa fa-eye" aria-hidden="true" *ngIf="!show"></i>
  	<i class="fa fa-eye-slash" aria-hidden="true" *ngIf="show"></i>
	<!-- <ion-icon class="show-option" [hidden]="show" src="./assets/sample-icons/auth/eye-show.svg"></ion-icon>
	<ion-icon class="hide-option" [hidden]="!show" src="./assets/sample-icons/auth/eye-hide.svg"></ion-icon> -->
  <!-- In case you want to use text instead of icons -->
	<!--
  <span class="show-option" [hidden]="show">show</span>
	<span class="hide-option" [hidden]="!show">hide</span>
  -->
</a>
