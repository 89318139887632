<!-- <div class="btn-group" role="group">
    <ng-container  *ngFor="let button of buttonList">
        <app-basic-button [iconClass]="button.iconClass"
            (onClickEvent)="onClickButton(button)"
            [classList]="button.classList">
            {{button.text|titlecase}}
        </app-basic-button>
    </ng-container>
</div> -->
<div class="btn-group" dropdown container="body" *ngIf="buttonList && buttonList.length>0">
    <button id="button-container" dropdownToggle type="button" class="btn btn-crear-action"
        aria-controls="dropdown-container">Acciones <i class="fa-solid fa-angle-down ms-2"></i></button>
    <ul id="dropdown-container" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-container">
        <li id="one" role="menuitem" *ngFor="let button of buttonList">
            <a class="dropdown-item" (click)="onClickButton(button)" href="javascript::">
                {{button.text}}
            </a>
        </li>
    </ul>
</div>