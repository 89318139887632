<div class="col-md-12">
    <!-- <div *ngIf="errorForm == 10" class="alert alert-danger">
      <div class="text-center">
        <p><strong>Nombre de la empresa</strong><code>no válido</code> ó <strong>Vacío</strong></p>
      </div>
    </div> -->
    <ng-container *ngFor="let alert of alertList" [ngTemplateOutlet]="alertTemplate"
        [ngTemplateOutletContext]="{ alert: alert }"></ng-container>
</div>
<div class="col-sm-12 p-30">
    <form>
        <fieldset>
            <div class="row">
                <!-- <div class="col-sm-4 p-10"> -->
                    <ng-container *ngFor="let input of mainInputList">
                        <div class="form-group col-sm-12 col-md-6" *ngIf="input.hidden == undefined || input.hidden === false">
                            <!-- <div [ngClass]="columnClass"> -->
                            <app-gen-ngb-input [input]="input" [form]="form" >

                            </app-gen-ngb-input>
                            <!-- </div> -->
                        </div>
                    </ng-container>
                <!-- </div> -->
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6" *ngFor="let filledField of filledFieldList">
                    <app-filled-field [label]="filledField.label" [text]="filledField.text">

                    </app-filled-field>
                </div>
            </div>
            <ul ngbNav #nav="ngbNav" class="nav-tabs" [ngClass]="['col-12 tab-seguridad']">
                @for (tab of tabList; track tab; let  i = $index) {    
                    <li [ngbNavItem]="i">
                        <a ngbNavLink><i class="fa p-r-10" [ngClass]="tab.iconClass"></i>{{tab.name}}</a>
                        <ng-template ngbNavContent>
                            <div class="tab-seguridad">
                                <div class="col-sm-12 m-b-20 tab-content px-3">
                                    <span class="tab-title-modal m-b-30">{{tab.title}}</span>
                                    <div class="row">
                                        <ng-container *ngFor="let input of tab.inputList">
                                            <div class="form-group" [ngClass]="tab.columnClass" *ngIf="input.hidden == undefined || input.hidden === false">
                                                <app-gen-ngb-input [input]="input" [form]="form">
        
                                                </app-gen-ngb-input>
                                            </div>
                                        </ng-container>
                                    </div>
        
                                </div>
                            </div>
                        </ng-template>
                    </li>
                }
                
            </ul>
            <!-- <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs" [ngClass]="['col-12 tab-seguridad']">
                <li [ngbNavItem]="1">
                  <a ngbNavLink>One</a>
                  <ng-template ngbNavContent>
                    <p>Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth
                      master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher retro
                      keffiyeh dreamcatcher synth. Cosby sweater eu banh mi, qui irure terry richardson ex squid. Aliquip placeat
                      salvia cillum iphone. Seitan aliquip quis cardigan american apparel, butcher voluptate nisi qui.</p>
                  </ng-template>
                </li>
              
                <li [ngbNavItem]="2">
                  <a ngbNavLink>Two</a>
                  <ng-template ngbNavContent>
                    <p>Exercitation +1 labore velit, blog sartorial PBR leggings next level wes anderson artisan four loko
                      farm-to-table craft beer twee. Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts
                      ullamco ad vinyl cillum PBR. Homo nostrud organic, assumenda labore aesthetic magna delectus mollit. Keytar
                      helvetica VHS salvia yr, vero magna velit sapiente labore stumptown. Vegan fanny pack odio cillum wes anderson
                      8-bit, sustainable jean shorts beard ut DIY ethical culpa terry richardson biodiesel. Art party scenester
                      stumptown, tumblr butcher vero sint qui sapiente accusamus tattooed echo park.</p>
                  </ng-template>
                </li>
              </ul> -->
              <!-- <div [ngbNavOutlet]="nav"></div> -->
            <div [ngbNavOutlet]="nav"></div>
            <!-- <ngb-tabset [ngClass]="['col-12 tab-seguridad']">
                <ngb-tab *ngFor="let tab of tabList">
                    <ng-template ngbTabTitle><i class="fa p-r-10" [ngClass]="tab.iconClass"></i>{{tab.name}}
                    </ng-template>
                    <ng-template ngbTabContent>
                        <div class="col-sm-12 m-b-20">
                            <span class="tab-title-modal m-b-30">{{tab.title}}</span>
                            <div class="row">
                                <ng-container *ngFor="let input of tab.inputList">
                                    <div class="form-group" [ngClass]="tab.columnClass" *ngIf="input.hidden == undefined || input.hidden === false">
                                        <app-gen-ngb-input [input]="input" [form]="form">

                                        </app-gen-ngb-input>
                                    </div>
                                </ng-container>
                            </div>

                        </div>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset> -->
        </fieldset>
    </form>
</div>