<div (click)="onContainerClicked($event)" class="basic modal fade" tabindex="-1"
     [ngStyle]="{'display': visible ? 'block' : 'none', 'opacity': visible && visibleAnimate ? 1 : 0}">
  <div class="modal-dialog" [ngClass]="dialogClass" [id]="id">
    <div class="modal-content">
       <div *ngIf="!hideHeader" class="modal-header">
        <ng-content select=".app-modal-header"></ng-content>
      </div>


        <!--<button class="close basic-close fix-modal" type="button" (click)="close()">
          <span aria-hidden="true">×</span>
        </button> -->
      <div class="modal-body">
        <ng-content select=".app-modal-body"></ng-content>
      </div>
      <div *ngIf="!hideFooter" class="modal-footer">
        <ng-content select=".app-modal-footer"></ng-content>
      </div>
    </div>
  </div>
</div>
