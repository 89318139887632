<label class="dt-cust-search">
    <div class="input-group">
      <span class="input-group-prepend" id="basic-addon1"><label class="input-group-text"><i class="icofont" [ngClass]="icon"></i></label></span>
      <input
        type='text'
        class="form-control input-sm normal-input"
        placeholder="{{placeholder}}"
        (keyup)='keyup($event)'
      />
    </div>
</label>