<aw-wizard #wizard class="arc-wizard" navBarLayout="large-filled-symbols">
  <aw-wizard-step stepTitle="{{step.title}}" [navigationSymbol]="{symbol: '&#xf02a;', fontFamily: 'FontAwesome'}" *ngFor="let step of steps">
    <div class="col-sm-12 p-30">
      <div class="row">
        <div class="col-sm-12">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <h3 class="text-primary">{{step.subtitle}}</h3>
              <img src="{{step.img || 'assets/images/card-5.png'}}" alt="">
            </div>

            <div class="col-sm-12 col-md-6">
              <ng-container [ngSwitch]="step.type">
                <ng-container *ngSwitchCase="'form'">
                  <app-gen-form
                    [inputList]="step.inputList"
                    [tabList]="step.tabList"
                    [filledFieldList]="step.filledFieldList"
                    [maxWidth]="step.maxWidth"
                    [maxHeight]="step.maxHeight"
                    [fileInput]="step.fileInput"
                    [templateName]="step.templateName"
                    [alertList]="step.alertList"
                    [loading]="loading"
                    [form]="form"
                    [columnsPerRow]="step.columnsPerRow"
                    [csvColumnList]="step.csvColumnList"
                    [csvData]="step.csvData"
                  ></app-gen-form>
                </ng-container>
                <ng-container *ngSwitchCase="'table'">
                  <app-basic-table class="col-sm-12" style="padding:0;"
                    [columnList]="step.columnList"
                    [rowsObs] = "step.rowsObs"
                    [actionBtnList] = "step.getActionBtnList()"
                    (getRowsEvent) = "step.getRows($event)"
                    (clickActionBtnEvent) = "step.onClickActionBtn($event)"
                    [totalCount]="step.totalCount"
                    [shouldHideActionBtn]="step.shouldHideActionBtn"
                    (onSort)="step.onSort($event)"
                  ></app-basic-table>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="btn-group mt-10">
            <button type="button" class="btn btn-secondary btn-lg" resetWizard>Cancelar</button>
            <button *ngIf="!step.validated" type="button" class="btn btn-primary btn-lg" (click)="validateStep(step)">Continuar</button>
            <button *ngIf="step.validated" type="button" class="btn btn-primary btn-lg" nextStep>Continuar</button>
          </div>
        </div>
      </div>
    </div>
  </aw-wizard-step>  
  <aw-wizard-step [stepTitle]="'Completado con Exito'" [navigationSymbol]="{symbol: '&#xf00c;', fontFamily: 'FontAwesome'}">
    <div class="centered-content">
      <div>
        <h3>{{successText}}</h3>
      </div>

      <div class="btn-group mt-10">
        <button type="button" class="btn btn-danger btn-sm" resetWizard>Volver</button>
      </div>
    </div>
  </aw-wizard-step>
</aw-wizard>