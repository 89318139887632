import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgbDatepickerModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
// import {ToggleFullScreenDirective} from './fullscreen/toggle-fullscreen.directive';
import {AccordionAnchorDirective} from './accordion/accordionanchor.directive';
import {AccordionLinkDirective} from './accordion/accordionlink.directive';
import {AccordionDirective} from './accordion/accordion.directive';
import {HttpClientModule} from '@angular/common/http';
// import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {TitleComponent} from './title/title.component';
import {CardComponent} from './card/card.component';
import {CardToggleDirective} from './card/card-toggle.directive';
import {ModalBasicComponent} from './modal-basic/modal-basic.component';
import {ModalAnimationComponent} from './modal-animation/modal-animation.component';
import {SpinnerComponent} from './spinner/spinner.component';
// import {ClickOutsideModule} from 'ng-click-outside';
import {LinkTriggerDirective} from '../layout/base/base.component';
import { TranslateBooleanPipe } from './translate-boolean.pipe';
//import { FileSelectDirective } from 'ng2-file-upload';
import { CaptchaDirective } from './captcha.directive';
import { BasicTableComponent } from './basic-table/basic-table.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BasicButtonComponent } from './basic-button/basic-button.component';
import { ModalFormComponent } from './modal-form/modal-form.component';
import { BasicFormComponent } from './basic-form/basic-form.component';
import { BasicModalComponent } from './basic-modal/basic-modal.component';
import { WhiteModalComponent } from './white-modal/white-modal.component';
import { GenNgbInputComponent } from './gen-ngb-input/gen-ngb-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import { ButtonGroupComponent } from './button-group/button-group.component';
import { PointCrossLoaderComponent } from './point-cross-loader/point-cross-loader.component';
import { BasicAlertComponent } from './basic-alert/basic-alert.component';
import { GenTablePageComponent } from './gen-table-page/gen-table-page.component';
import { RouterModule } from '@angular/router';
import { TabsetFormComponent } from './tabset-form/tabset-form.component';
import { ModalTabsetFormComponent } from './modal-tabset-form/modal-tabset-form.component';
import { FilledFieldComponent } from './filledfield/filled-field.component';
import { SanitizeHtmlPipe } from '../../core/pipes/sanitizer.pipe';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { GenPageComponent } from './gen-page/gen-page.component';
import { SelectableComponent } from './selectable/selectable.component';
import { CsvFileUploadFormComponent } from './csv-file-upload-form/csv-file-upload-form.component';
import { ByteaImageComponent } from './bytea-image/bytea-image.component';
import { BarcodeImageComponent } from './barcode-image/barcode-image.component';
import { TextFilterInputComponent } from './text-filter-input/text-filter-input.component';
import { IsGrantedDirective } from '../../core/_base/permissions/directives/permission.directive';
import { ShowHidePasswordComponent } from './show-hide-password/show-hide-password.component';
import { PstackedBarsComponent } from './charts/pstacked-bars/pstacked-bars.component';
import { PieComponent } from './charts/pie/pie.component';
import { WizzardComponent } from './wizzard/wizzard.component';
// import { ArchwizardModule } from 'ng2-archwizard/dist';
import { ArchwizardModule } from '@y3krulez/angular-archwizard';
import { GenFormComponent } from './gen-form/gen-form.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { ShowLastCharsPipe } from '../../core/_base/pipes/show-last-chars.pipe';
import { PrometheusLineComponent } from './charts/prometheus-line/prometheus-line.component';
import {ReportHeaderComponent} from './report/layout/header/header.component';
import {ReportContentComponent} from './report/layout/content/content.component';
import {ReportPageComponent} from './report/layout/page/page.component';
import {ReportCardComponent} from "./report/card/card.component";
import { FilterCardComponent } from './report/filter-card/filter-card.component';
import { ReportCardHorizontalTitleComponent } from './report/card/title/horizontal-title/horizontal-title.component';
import { ReportCardFilterTitleComponent} from './report/card/title/filter-title/filter-title.component';
import {ReportCardTitleComponent} from "./report/card/title/title/title.component";
import { DataBoxComponent } from './report/data-box/data-box.component';
import { ReportCardSimpleTitleComponent } from './report/card/title/simple-title/simple-title.component';
import { EstackedBarsComponent } from './charts/estacked-bars/estacked-bars.component';
import { EpieComponent } from './charts/epie/epie.component';
import { ElineComponent } from './charts/eline/eline.component';

declare var Chart: any;
declare var ChartDatasourcePrometheusPlugin: any;
document.addEventListener("DOMContentLoaded", ()=>{
  Chart.registry.plugins.register(ChartDatasourcePrometheusPlugin as any);
})
// const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
//   suppressScrollX: true
// };

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    HttpClientModule,
    NgScrollbarModule,
    NgClickOutsideDirective,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    ArchwizardModule,
    // NgbDatepickerModule
  ],
    exports: [
        NgbModule,
        // ToggleFullScreenDirective,
        AccordionAnchorDirective,
        AccordionLinkDirective,
        AccordionDirective,
        LinkTriggerDirective,
        CardToggleDirective,
        HttpClientModule,
        // PerfectScrollbarModule,
        NgScrollbarModule,
        TitleComponent,
        CardComponent,
        ModalBasicComponent,
        ModalAnimationComponent,
        SpinnerComponent,
        NgClickOutsideDirective,
        TranslateBooleanPipe,
        CaptchaDirective,
        BasicTableComponent,
        BasicButtonComponent,
        ModalFormComponent,
        BasicFormComponent,
        BasicModalComponent,
        WhiteModalComponent,
        GenNgbInputComponent,
        ButtonGroupComponent,
        PointCrossLoaderComponent,
        GenTablePageComponent,
        TabsetFormComponent,
        ModalTabsetFormComponent,
        FilledFieldComponent,
        SanitizeHtmlPipe,
        GenPageComponent,
        SelectableComponent,
        CsvFileUploadFormComponent,
        ByteaImageComponent,
        BarcodeImageComponent,
        TextFilterInputComponent,
        IsGrantedDirective,
        ShowHidePasswordComponent,
        PstackedBarsComponent,
        PieComponent,
        WizzardComponent,
        ShowLastCharsPipe,
        PrometheusLineComponent,
        ReportPageComponent,
        ReportHeaderComponent,
        ReportContentComponent,
        ReportCardComponent,
        ReportCardFilterTitleComponent,
        ReportCardTitleComponent,
        FilterCardComponent,
        DataBoxComponent,
        ReportCardSimpleTitleComponent,
        ReportCardHorizontalTitleComponent,
      EstackedBarsComponent,
      EpieComponent,
      ElineComponent
    ],
  declarations: [
    // ToggleFullScreenDirective,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    LinkTriggerDirective,
    CardToggleDirective,
    TitleComponent,
    CardComponent,
    ModalBasicComponent,
    ModalAnimationComponent,
    SpinnerComponent,
    TranslateBooleanPipe,
    //FileSelectDirective,
    CaptchaDirective,
    BasicTableComponent,
    BasicButtonComponent,
    ModalFormComponent,
    BasicFormComponent,
    BasicModalComponent,
    WhiteModalComponent,
    GenNgbInputComponent,
    ButtonGroupComponent,
    PointCrossLoaderComponent,
    BasicAlertComponent,
    GenTablePageComponent,
    TabsetFormComponent,
    ModalTabsetFormComponent,
    FilledFieldComponent,
    SanitizeHtmlPipe,
    GenPageComponent,
    SelectableComponent,
    CsvFileUploadFormComponent,
    ByteaImageComponent,
    BarcodeImageComponent,
    TextFilterInputComponent,
    IsGrantedDirective,
    ShowHidePasswordComponent,
    PstackedBarsComponent,
    PieComponent,
    WizzardComponent,
    GenFormComponent,
    ShowLastCharsPipe,
    PrometheusLineComponent,
    ReportHeaderComponent,
    ReportContentComponent,
    ReportPageComponent,
    ReportCardComponent,
    FilterCardComponent,
    ReportCardHorizontalTitleComponent,
    ReportCardFilterTitleComponent,
    ReportCardTitleComponent,
    DataBoxComponent,
    ReportCardSimpleTitleComponent,
    EstackedBarsComponent,
    EpieComponent,
    ElineComponent
  ],
  providers: [
    // {
    //   provide: PERFECT_SCROLLBAR_CONFIG,
    //   useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    // }
  ]
})
export class SharedModule { }
