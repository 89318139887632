import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { PROMETHEUS_API_RELATIVE, PROMETHEUS_BASE_URL } from '../../../../core/connection.data';
import { Subscription, interval, startWith } from 'rxjs';
import { ColorGenerator, D3JS_SCHEME } from '../../../../core/util/color.util';
import { AuthService } from '../../../../core/auth/services/auth.service';
import * as hash from 'object-hash';
declare var Chart: any;
declare var ChartDatasourcePrometheusPlugin: any;


@Component({
  selector: 'app-prometheus-line',
  templateUrl: './prometheus-line.component.html',
  styleUrl: './prometheus-line.component.scss'
})
export class PrometheusLineComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @ViewChild('chartWrapper') chartWrapper!: ElementRef;
  @Input() query!: string|string[];
  @Input() start!: number;
  @Input() end: number = 0;
  @Input() updateMs: number = 0;
  @Input() colorScheme: string[] = Array.from(D3JS_SCHEME);
  colorGenerator : any;
  serieColorMap: any = {};
  prevCtx: any;
  prevChart: any;
  prevCanvas: any;
  subscriptions: Subscription[] = [];

  constructor(
    private authService: AuthService
  ){
    this.colorGenerator = ColorGenerator(this.colorScheme);
  }

  createOrUpdateChart(){
    let colorGenerator = this.colorGenerator;
    const serieColorMap = this.serieColorMap;

    console.log("query: ", this.query)
    // const colorGenerator = ColorGenerator(D3JS_SCHEME);
    if(this.chartWrapper === undefined){
      console.log("chart wrapper undefined yet");
      return;
    }
    const wrapper: any = this.chartWrapper.nativeElement;
    const canvas = document.createElement('canvas');
    const ctx: any = canvas.getContext('2d');
    // const ctx = (document.getElementById('myChart2') as any).getContext('2d');
    canvas.style.visibility = 'hidden';
    // canvas.style.position = 'absolute';
    // canvas.style.width = '600px';
    // canvas.style.height = '600px';
    wrapper.appendChild(canvas);
    // const colors: any = {};
    const backgroundPlugin = {
      id: 'customCanvasBackgroundColor',
      beforeDraw: (chart: any, args: any, options: any) => {
        const {ctx} = chart;
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = options.color || '#99ffff';
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
      }
    };
    let firstAfter = true;
    setTimeout(()=>{
      const myChart: any = new Chart(ctx, {
        type: 'line',
        plugins: [
          ChartDatasourcePrometheusPlugin as any,
          backgroundPlugin,
          // autocolors as any,
          {
            afterRender: (chart: any)=>{
              console.log("after render going to set timeout")
              console.log("after render time out")
              if(!firstAfter) return;
              console.log("is first time!")
              firstAfter = false;
              console.log("after render!")
              setTimeout(()=>{
                canvas.style.visibility = 'visible';
                canvas.style.position = 'absolute';
                setTimeout(()=>{
                  // chart.resize(500, 500);
                  // canvas.style.width = '600px';
                  // canvas.style.height = '600px';

                },50)

                if(this.prevChart && this.prevCtx && this.prevCanvas){
                  // this.prevChart.destroy();
                  this.prevCanvas.style.visibility = 'hidden';
                  this.prevCanvas.remove();
                  console.log("prev canvas removed: ", this.prevCanvas);
                }else{
                  console.log("could not remove prev canvas")
                }
                this.prevChart = chart;
                this.prevCtx = ctx;
                this.prevCanvas = canvas;
              }, 500)
              // setTimeout(()=>{
              //   if(this.prevChart && this.prevCtx){
              //     this.prevChart.destroy();
              //     this.prevChart = myChart;
              //     this.prevCtx = ctx;
              //   }
              // }, 1000);
            }
          }
        ],
        options: {
          elements: {
            line: {
              borderWidth: 0.8,
              pointStyle: false,
            }
          },
          plugins: {
            legend: {
              labels: {
                color: '#ededed',  // Color del texto de la leyenda
                font: {
                  size: 14  // Tamaño de la fuente de la leyenda
                }
              }
            },
            'datasource-prometheus': {
              prometheus: {
                endpoint: PROMETHEUS_BASE_URL,
                baseURL: PROMETHEUS_API_RELATIVE,
                headers: {
                  authorization: `Bearer ${this.authService.getToken()}`
                }
              },
              query: this.query,
              timeRange: {
                type: 'relative',
                start: this.start,
                end: this.end,
              },
              tension: 0.1,
              borderWidth: 1,
              findInBorderColorMap: function (serie: any) {
                const labelHash = hash.sha1(serie.labels);
                if(serieColorMap[labelHash] === undefined) {
                  serieColorMap[labelHash] = colorGenerator.generate();
                  console.log(`No existe color para hash ${labelHash}. Se usa color ${serieColorMap[labelHash]}`)
                }else{
                  console.log(`Existe color ${serieColorMap[labelHash]} para hash ${labelHash}`)
                }
                return serieColorMap[labelHash]
              },
              pointStyle: false,
            },
            customCanvasBackgroundColor: {
              color: 'black',  // Color de fondo del lienzo
            }
          },
          scales: {
            x: {
              type: 'time',
              ticks: {
                fontColor:['green','red','blue','yellow','green','red'],
                color: 'white',  // Cambia el color del texto de las etiquetas en el eje X a blanco
                font: {
                  size: 14,  // Tamaño de la fuente de las etiquetas en el eje X
                  color: 'red',
                  fontColor: 'red'
                },
                // callback: function(value: any, index: any, values: any) {
                //   console.log(`value: ${value}. index: ${index}. values: ${values}`)
                //   if(index === 3) {
                //     return '**'+value+'**';
                //   }
                //   else {
                //       return value;
                //       }
                //   }
              },
              grid: {
                color: '#ededed',  // Color de las líneas de la cuadrícula del eje X
                borderColor: '#ededed',  // Color del borde del eje X
                lineWidth: 0.5
              },
              title: {
                display: true,
                text: 'Hora',
                color: '#fff'  // Color del título del eje X
              },
              border: {
                color: '#ededed',
                width: 0.5
              }
            },
            y: {
              ticks: {
                color: '#ededed',  // Cambia el color del texto de las etiquetas en el eje Y a blanco
                font: {
                  size: 14  // Tamaño de la fuente de las etiquetas en el eje Y
                }
              },
              grid: {
                color: '#ededed',  // Color de las líneas de la cuadrícula del eje Y
                lineWidth: 0.5
              },
              border: {
                color: '#ededed',
                width: 0.5
              }
            }
          },
          maintainAspectRatio: false,
          responsive: true,
          animations: true
        }
      });
    }, 500);
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    console.log("ng on init")
    // document.addEventListener("DOMContentLoaded", () => {
      console.log("dom loaded")
      let st = performance.now();
      this.createOrUpdateChart();
      if(this.updateMs > 1000){
        const interval$ = interval(this.updateMs).pipe(
          startWith(0)
        ).subscribe(()=>{

          console.log(`since last time ${performance.now() - st}ms`);
          st = performance.now();
          // this.createOrUpdateChart();
          if(this.prevChart)
            this.prevChart.update({})
        });
        this.subscriptions.push(interval$);
      }
    // });
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("line changes: ", changes)
    this.createOrUpdateChart();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe());
  }
}
