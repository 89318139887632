import { Component } from '@angular/core';

@Component({
  selector: 'report-card-horizontal-title',
  templateUrl: './horizontal-title.component.html',
  styleUrl: './horizontal-title.component.scss'
})
export class ReportCardHorizontalTitleComponent {

}
