import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
@Component({
    selector: 'app-gen-page',
    templateUrl: './gen-page.component.html',
    styleUrls: ['./gen-page.component.scss',

    ]
})
export class GenPageComponent{

    @Input() breadcrumbs: any[] = [];
    @Input() title:string = "";

    constructor(
        private cdr: ChangeDetectorRef
    ) {

    }
}