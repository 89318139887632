import { Directive,OnInit,AfterViewInit,Input,ElementRef,NgZone,Injector } from '@angular/core';
import { ControlValueAccessor,UntypedFormControl,Validators,NgControl } from '@angular/forms';
declare const grecaptcha : any;
declare global {
  interface Window {
    grecaptcha : any;
    reCaptchaLoad : () => void
  }
}
@Directive({
  selector: '[appCaptcha]'
})
/**
 * https://netbasal.com/how-to-integrate-recaptcha-in-your-angular-forms-400c43344d5c
 */

export class CaptchaDirective implements OnInit, AfterViewInit, ControlValueAccessor{
  @Input() key !: string;
  @Input() config: ReCaptchaConfig = {};
  @Input() lang !: string;
  private control!: UntypedFormControl;
  private widgetId!: number;
  constructor( 
    private element : ElementRef,
    private ngZone : NgZone ,
     private injector : Injector
    ) {}
  private onChange !: ( value : string|null ) => void;
  private onTouched !: ( value : string|null ) => void;
  ngOnInit(): void {
    //throw new Error('Method not implemented.');
    //console.log('HERE WE ARE CaptchaDirective::ngOnInit');
    this.registerReCaptchaCallback();
    this.addScript();
  }
  ngAfterViewInit(): void {
    this.control = this.injector.get(NgControl).control as UntypedFormControl;
    this.setValidator();
    //throw new Error('Method not implemented.');
  }
  writeValue(obj: any): void {
    //throw new Error('Method not implemented.');
  }
  registerOnChange(fn: any): void {
    //throw new Error('Method not implemented.');
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    //throw new Error('Method not implemented.');
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    //throw new Error('Method not implemented.');
  }
  onExpired() {
    this.ngZone.run(() => {
      this.onChange(null);
      this.onTouched(null);
    });
  }
  
  onSuccess( token : string ) {
    this.ngZone.run(() => {
      this.onChange(token);
      this.onTouched(token);
    });
  }
  registerReCaptchaCallback() {
    window.reCaptchaLoad = () => {
      const config = {
        ...this.config,
        'sitekey': this.key,
        'callback': this.onSuccess.bind(this),
        'expired-callback': this.onExpired.bind(this)
      };
      this.widgetId = this.render(this.element.nativeElement, config);
    };
  }

  private render( element : HTMLElement, config: any ) : number {
    return grecaptcha.render(element, config);
  }


  addScript() {
    let script = document.createElement('script');
    const lang = this.lang ? '&hl=' + this.lang : '';
    script.src = `https://www.google.com/recaptcha/api.js?onload=reCaptchaLoad&render=explicit${lang}`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }
  private setValidator() {
    this.control.setValidators(Validators.required);
    this.control.updateValueAndValidity();
  }
}
export interface ReCaptchaConfig {
  theme? : 'dark' | 'light';
  type? : 'audio' | 'image';
  size? : 'compact' | 'normal';
  tabindex? : number;
}