import { Component } from '@angular/core';

@Component({
  selector: 'report-card-title',
  templateUrl: './title.component.html',
  styleUrl: './title.component.scss'
})
export class ReportCardTitleComponent {

}
