import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Form, FormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { ContractTemplateService } from '../../../core/contracts/services/contract-template.service';
import { BehaviorSubject, Subject, Subscription ,  of } from 'rxjs';
import { QueryParamsModel } from '../../../core/_base/crud';
import { debounceTime, distinctUntilChanged, filter, switchMap, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { InputCheckerValidator } from '../../../core/form/validators/input-checker.validator';
import { AuthService } from '../../../core/auth/services/auth.service';
import { formatDate } from '../../../core/util/date.util';
import { FormCheckerValidator } from '../../../core/form/validators/form-checker.validator';

export interface TableFilter{
    optionList?: {label: string, value: any}[], 
    name: string, 
    onChange: any, 
    label?: string, 
    icon?: string|null, 
    placeholder?: string|null, 
    type?: string|null
};
@Component({
    selector: 'app-gen-table-page',
    templateUrl: './gen-table-page.component.html',
    styleUrls: [
        './gen-table-page.component.scss',

    ]
})
export class GenTablePageComponent implements OnInit, OnDestroy, OnChanges {
    @Input() name: string = "";
    @Input() pluralName: string = "";

    //Busqueda de texto
    @Input() searchPlaceholder: string = "Búsqueda por campos relevantes";
    private searchObs = new BehaviorSubject<string>('');
    //Filtrado en listas
    @Input() filterList: TableFilter[] = [];
    //Ordenado de columnas
    private sortObs = new BehaviorSubject<any>(undefined);

    //Modal para añadir o editar
    formModal = { title: "Nueva plantilla de contrato", display: false, blocked: false, maxWidth: "1200px", maxHeight: "100%" };
    formModalButtonList = [
        { iconClass: "fa fa-check", text: "Guardar", classList: ["btn", "btn-success", "f-right"] },
        { iconClass: "fa fa-close", text: "Cancelar", classList: ["btn", "btn-danger", "f-left"] },
    ];

    //Ayuda
    @Input() helpText: string|null = null;
    @Output() onClickHelp: EventEmitter<any> = new EventEmitter();

    //Form
    @Input() columnsPerRow: number = 1;
    @Input() form!: UntypedFormGroup;
    @Input() formModalMaxWidth: string = "1200px";
    @Input() formModalMaxHeight: string = "20%";
    @Input() tabList: any[] = [];
    @Input() useTabset: boolean = false;
    @Input() inputList: any[] = [];
    @Input() filledFieldList: any[] = [];
    @Input() fileInput: any = null;
    @Input() templateName: string = "default";
    @Input() csvData: any[] = [];
    @Input() csvColumnList: any[] = [];
    //   inputList = [
    //     {label: "Nombre", name: "name", placeholder: "Plantilla 1", type: "text"},
    //     // {label: "Estado", name: "status_id", type: "list", optionList: [],
    //     //   onSelected: (event)=> this.onSelected(event, 'status_id'), 
    //     //   onDeselected: (event)=>this.onDeselected(event, 'status_id')},
    //     {label: "Contrato en latex", type: "file", 
    //       name: "latex_contract_file_name", 
    //       onFileChange: (event)=>this.onFileChange(event, 'latex_contract')},
    //     {label: "Contrato en pdf", type: "file", 
    //       name: "pdf_contract_file_name", 
    //       onFileChange: (event)=>this.onFileChange(event, 'pdf_contract')},
    //     {label: "Firma digital", type: "file", 
    //       name: "sign_key_contract_file_name", 
    //       onFileChange: (event)=>this.onFileChange(event, 'contract_sign_key')}
    //   ]
    //alert example: {field: 'name', error: 'no es un nombre válido'}
    @Input() alertList: (InputCheckerValidator | FormCheckerValidator)[] = [];
    //   alertList: InputCheckerValidator[] = [
    //     new InputCheckerValidator(this.form, 'name', 'Nombre', (value)=>{
    //       if(value==undefined || value==null || value=="") return "El nombre es requerido";
    //       return undefined;
    //     }),
    //     new InputCheckerValidator(this.form, 'pdf_contract', 'Contrato PDF', (value)=>{
    //       console.log("pdf contract change value: ");console.dir(value);
    //       if(value==undefined || value==null || value=="-1") return "Se requiere de un pdf de contrato";
    //       if(typeof(value) != 'object') return "No se ha seleccionado ningún archivo";
    //       return undefined;
    //     })
    //   ]

    //Modal pdf
    //   pdfModal = {display: false, title: 'PDF', blocked: false};
    //   currentPdfBlob: Blob = null;

    //Table
    @Input() actionBtnList: any[] = [];
    //   actionBtnList = [
    //     // {iconClass: "fa fa-trash", text: "Eliminar", classList: ["btn", "fix-btn", "btn-sm", "edit-btn-solo"]}, //No se puede debido a que habria que eliminar los contratos o updatear el id
    //     {iconClass: "fa fa-file", text: "PDF", classList: ["btn", "fix-btn", "btn-sm", "edit-btn-solo"]},
    //     {iconClass: "fa fa-file", text: "Latex", classList: ["btn", "fix-btn", "btn-sm", "edit-btn-solo"]},
    //     {iconClass: "fa fa-file", text: "Firma", classList: ["btn", "fix-btn", "btn-sm", "edit-btn-solo"]},
    //   ]
    @Input() enableCommonActions: {edit: boolean, delete: boolean, add:boolean, downloadCSV: boolean} = {
        edit: false, delete: false, add:true, downloadCSV: false
    };
    editBtn = {iconClass: "fa fa-edit", text: "Editar", classList: ["btn", "fix-btn", "btn-sm", "edit-btn-solo"]};
    deleteBtn = {iconClass: "fa fa-trash", text: "Eliminar", classList: ["btn", "fix-btn", "btn-sm", "edit-btn-solo"]};

    @Input() dataObs!: Subject<any>;
    rowsObs: Subject<any> = new BehaviorSubject<any[]>([]);
    @Output() addEvent = new EventEmitter<any>();
    @Input() addObs!: Subject<any>;
    @Output() editEvent = new EventEmitter<any>();
    @Input() editObs!: Subject<any>;
    @Output() deleteEvent = new EventEmitter<any>();
    @Input() deleteObs!: Subject<any>;
    
    // deleteObs: Subject<string> = new Subject<string>();
    lastQuery!: QueryParamsModel;

    @Input() columnList: any[] = [];
    @Input() shouldHideActionBtn!:any;

    @Output() fetchDataEvent = new EventEmitter<QueryParamsModel>();
    private firstLoad = new BehaviorSubject<boolean>(false);

    @Output() clickActionBtnEvent = new EventEmitter<any>();
    @Output() downloadCSVEvent = new EventEmitter<any>();
    //   columnList = [
    //     {name: 'id', text: 'ID'},
    //     {name: 'name', text: 'Nombre'},
    //     {name: 'create_date', text: 'Creación', isDate: true},
    //     {name: 'write_date', text: 'Última modificación'},
    //     // {name: 'word_contract', text: 'word_contract'}, //bytea
    //     // {name: 'latex_contract', text: 'latex_contract'}, //bytea
    //     // {name: 'pdf_contract', text: 'pdf_contract'}, //bytea
    //     {name: 'status_name', text: 'Estado'},
    //     // {name: 'contract_sign_key', text: 'contract_sign_key'}, //bytea
    //     {name: 'pdf_contract_file_name', text: 'Nombre contrato pdf'},
    //     {name: 'latex_contract_file_name', text: 'Nombre contrato latex'},
    //     {name: 'sign_key_contract_file_name', text: 'Nombre firma digital'},    
    //   ] 

    //Modal de eliminar plantilla
    deleteModal = { display: false, title: 'Eliminación de plantilla de contrato', blocked: false };

    private subscriptions: Subscription[] = [];
    statusList!: any[];
    mode: string = 'add';
    selectedRow: any;
    totalCount: number = 1;
    uid!: string|null;
    constructor(
        private cdr: ChangeDetectorRef,
        private toastr: ToastrService,
        private authService: AuthService
    ) {
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
        this.alertList.forEach(alert => alert.clear());
    }

    ngOnInit() {

        this.uid = this.authService.getUID();

        this.subscriptions.push(
            this.dataObs.subscribe(resp => {
                //console.log("resp"); console.dir(resp);
                this.totalCount = resp.totalCount;
                this.cdr.detectChanges();
                this.rowsObs.next(resp.items);
                this.firstLoad.next(true);
            })
        )

        this.subscriptions.push(
            this.firstLoad.pipe(
                filter(loaded=>loaded),
                take(1),
                switchMap(()=>this.searchObs),
                distinctUntilChanged(),
                debounceTime(250)
            ).subscribe(searchStr => {
                this.lastQuery.filter = { search: searchStr };
                this.fetchData(this.lastQuery);
            })
        );

        this.subscriptions.push(
            this.firstLoad.pipe(
                filter(loaded=>loaded),
                take(1),
                switchMap(()=>this.sortObs),
                filter(sortParams => sortParams != undefined),
                debounceTime(250)
            ).subscribe(sortParams => {
                this.lastQuery.sortField = sortParams.sortField;
                this.lastQuery.sortOrder = sortParams.sortOrder;
                this.fetchData(this.lastQuery);
            })
        );

        this.formModal.title = `Nuevo registro de ${this.name}`;
        this.formModal.maxWidth = this.formModalMaxWidth;
        this.formModal.maxHeight = this.formModalMaxHeight;
        this.deleteModal.title = `Eliminación de registro de ${this.name}`;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.formModal.maxWidth = this.formModalMaxWidth;
        this.formModal.maxHeight = this.formModalMaxHeight;
    }

    downloadCSV(){
        this.downloadCSVEvent.emit();
    }

    onSort(event: any) {
        //console.log("sort"); console.dir(event);
        const sort = event.sorts && event.sorts.length > 0 ? event.sorts[0] : undefined;
        if (sort != undefined) {
            this.sortObs.next({ sortOrder: sort.dir, sortField: sort.prop });
        }
    }

    onCloseModal(modal: any) {
        if (modal.blocked) return;
        modal.display = false;
    }

    getRows(pageInfo: any) {
        var queryParams = new QueryParamsModel(
            this.lastQuery ? this.lastQuery.filter : {},
            this.lastQuery?.sortOrder,
            this.lastQuery?.sortField,
            pageInfo.page - 1,
            pageInfo.pageSize,
            undefined
        );
        this.lastQuery = queryParams;
        this.fetchData(queryParams);
    }

    fetchData(queryParams: QueryParamsModel) {
        this.fetchDataEvent.emit(queryParams);
    }

    refreshTable() {
        this.fetchData(this.lastQuery);
    }

    onClickButton(_button: any) {
        this.formModal.blocked = true;
        //console.log(`button clicked:`); console.dir(_button);
        if(_button.text == "Cancelar"){
            this.formModal.blocked = false;
            this.formModal.display = false;
            return;
        }
        //console.dir(this.form);
        // console.log("gen table page form value: ", this.form.value)
        var item = new FormData();
        for (let key of Object.keys(this.form.controls)) {
            // if(typeof this.form.controls[key].value ==='object'){ // es un archivo(?)

            //   item.append(key, this.form.controls[key].value, this.form.controls[key].value['name']);
            // }else{
            let value = this.form.controls[key].value;
            if(value == undefined || value == null) continue;
            console.log(`se añade ${key} con valor: `); console.dir(value);
            if(value instanceof Date){
                value = formatDate(value);
            }
            item.append(key, value);
            // }      
        }
        if (this.mode == 'add') {
            item.append('create_date', 'now()');
            item.append('create_uid', `${this.uid}`);
        } else if (this.mode == 'edit') {
            item.append('write_date', 'now()');
            item.append('write_uid', `${this.uid}`);
        }
        // console.log("gen table page item formdata: ", Object.entries(item))
        //console.log("item");
        // item.forEach((value, key)=>{

        //   console.log("key: "+key); console.dir(value);
        // });
        // console.log("test: ", JSON.stringify(Object.fromEntries(item.entries()));)
        const mode = this.mode;
        this.subscriptions.push(
            of(null).pipe(
                switchMap(() => {
                    if (mode == 'add'){
                        this.addEvent.emit(item);
                        return this.addObs;
                    }else if (mode == 'edit'){
                        this.editEvent.emit({item, oldItem: this.selectedRow});
                        return this.editObs;
                    }else return of(null);
                }),
                take(1)
            ).subscribe(res => {
                //console.log("res"); console.dir(res);
                //console.log("mode"); console.dir(mode);
                this.formModal.blocked = false;
                this.formModal.display = false;
                if(mode == 'add' && res == 'added')
                    this.toastr.success(`Registro de ${this.name} creado exitosamente`, 'Operación exitosa');
                else if(mode == 'edit' && res == 'edited')
                    this.toastr.success(`Registro de ${this.name} modificado exitosamente`, 'Operación exitosa');
                else if(mode == 'add' && res == 'error')
                    this.toastr.error(`Error al crear registro de ${this.name}`, 'Error');
                else if(mode == 'edit' && res == 'error')
                    this.toastr.error(`Error al editar registro de ${this.name}`, 'Error');
                else if(mode == 'add' && res.message && res.level ){
                    switch(res.level){
                        case 'error': this.toastr.error(res.message, `Error al crear registro de ${this.name}`);
                        break;
                        case 'success': this.toastr.success(res.message, `Registro de ${this.name} creado exitosamente`);
                        break;
                    }
                }else 
                    this.toastr.warning(res);
                this.refreshTable();
            })
        )
    }

    onClickAdd(){
        this.mode = "add";
        this.formModal.title = this.formModal.title.replace('Modificar', 'Nuevo');
        this.openModalForm('add');
        this.onClickActionBtn({row: undefined, button: {text: 'add'}})
    }

    //Eventos al presionar un boton de accion de alguna fila de la tabla
    onClickActionBtn(event: any) {
        //console.log("click action btn: "); console.dir(event);
        this.clickActionBtnEvent.emit(event);
        const btn = event.button;
        const row = event.row;

        if (this.enableCommonActions.delete && btn.text == this.deleteBtn.text) {
            this.selectedRow = row;
            this.deleteModal.display = true;
        }else if (this.enableCommonActions.edit && btn.text == this.editBtn.text) {
            this.selectedRow = row;
            this.mode = "edit";
            this.formModal.title = this.formModal.title.replace('Nuevo', 'Modificar');
            this.formModal.display = true;
        }
    }

    getActionBtnList(){
        var commonActionList:any[] = [];
        if(this.enableCommonActions.edit) commonActionList.push(this.editBtn);
        if(this.enableCommonActions.delete) commonActionList.push(this.deleteBtn);

        const actionBtnList:any[] = ([] as any[]).concat(this.actionBtnList, commonActionList);
        return actionBtnList;
    }

    deleteContractTemplate() {
        this.formModal.blocked = true;
        //console.log('item to del:'); console.dir(this.selectedRow);
        this.subscriptions.push(
            of(null).pipe(
                switchMap(() => {
                    // this.contractTemplateService.delete(this.selectedRow)
                    this.deleteEvent.emit(this.selectedRow);
                    //console.dir({deleteObs: this.deleteObs})
                    return this.deleteObs;
                }),
                take(1)
            ).subscribe((res) => {
                if(res == 'error'){
                    this.toastr.error(`Error al elminar registro de ${this.name}`, 'Error');
                }else{
                    this.toastr.success(`Registro de ${this.name} eliminado exitosamente`, 'Operación exitosa');
                }
                this.deleteModal.display = false;
                this.deleteModal.blocked = false;
                this.refreshTable();
            })
        )
    }

    openModalForm(mode: any) {
        this.mode == mode;
        if (mode == 'add') {
        } else if (mode == 'edit') {

        }
        this.formModal.display = true;
    }

    search(event: any) {
        this.searchObs.next(event.target.value);
    }

    closeVerticallyCentered() {
        this.formModal.display = false;
    }

    clickHelp(){
        this.onClickHelp.emit();
    }

    onNoClick(){

    }
}
