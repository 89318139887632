
function formatDate(date:Date){
    console.dir(date);
    const year = date.getFullYear();
    const month = numberTo2DigitsString(date.getMonth() +1);
    const day = numberTo2DigitsString(date.getDate());
    const hours = numberTo2DigitsString(date.getHours());
    const minutes = numberTo2DigitsString(date.getMinutes());
    const seconds = numberTo2DigitsString(date.getSeconds());
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

function dateStr(date: Date){
    const year = date.getFullYear();
    const month = numberTo2DigitsString(date.getMonth() +1);
    const day = numberTo2DigitsString(date.getDate());
    return `${year}-${month}-${day}`;
}

function numberTo2DigitsString(number:number){
    return `${number<10?0:''}${number}`;
}

function humanDate(date: Date){
    return date.toLocaleDateString(window.navigator.language, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      });
}

function datepickerToDate(val: {year: number, month: number, day: number}): Date|null{
  if(val == null || val == undefined) return null;
  return new Date(`${val.year}-${val.month}-${val.day}`)
}

function dateToDatepicker(date: Date){
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate()
  }
}

export {formatDate, dateStr, humanDate, datepickerToDate, dateToDatepicker};
