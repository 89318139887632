import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
@Component({
    selector: 'app-csv-file-upload-form',
    templateUrl: './csv-file-upload-form.component.html',
    styleUrls: ['./csv-file-upload-form.component.scss',

    ]
})
export class CsvFileUploadFormComponent implements OnInit, OnDestroy {


    private subscriptions: Subscription[] = [];

    @Input() alertTemplate!: TemplateRef<any>;
    @Input() alertList: any[] = [];
    @Input() inputList: any[] = [];
    @Input() fileInput: any = null;
    @Input() form!: UntypedFormGroup;
    @Input() csvData: any[] = [];
    @Input() csvColumnList: any[] = [];

    sortType: any = 'multi';

    constructor(
        private cdr: ChangeDetectorRef
    ) {

    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
    }
}