import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-modal-form',
    templateUrl: './modal-form.component.html',
    styleUrls: ['./modal-form.component.scss',

    ]
})
export class ModalFormComponent implements OnInit, OnDestroy {

    @Input() title: string = "";
    @Input() display: boolean = false;
    @Input() inputList: any[] = [];
    @Input() tabList: any[] = [];
    @Input() filledFieldList: any[] = [];
    @Input() maxWidth: string = "1200px";
    @Input() maxHeight: string = "100%";
    // @Input() useTabset: boolean = false;
    @Input() fileInput: any = null;
    @Input() templateName: string = 'default';
    @Input() alertList: any[] = [];
    @Input() form!: UntypedFormGroup;
    @Input() buttonList: any[] = [];
    @Input() columnsPerRow: number = 2;
    @Input() loading: boolean = false;
    @Input() csvColumnList: any[] = [];
    @Input() csvData: any[] = [];
    @Output() onClickEvent = new EventEmitter<any>()
    @Output() onCloseEvent = new EventEmitter<any>();

    constructor(
        private cdr: ChangeDetectorRef
    ) {

    }

    ngOnDestroy(): void {
    }

    ngOnInit() {
    }

    onClickButton(button_: any) {
        console.log("emiting:");console.dir(button_);
        this.onClickEvent.emit(button_);
        this.cdr.detectChanges();
    }

    onClose(){
        this.onCloseEvent.emit();
    }
}