<app-basic-modal [display]="display" [title]="title" [maxWidth]="maxWidth" (onCloseEvent)="onClose()">

    <ng-container body>
        <!-- {{templateName}} => {{templateName == 'csvFile'}} -->
        <ng-scrollbar>
        <!-- <perfect-scrollbar [scrollIndicators]="true" class="overflow-container" [style.width]="'100%'" [style.height]="maxHeight"> -->
            <!-- <ng-container [ngSwitch]="templateName">
                <ng-container *ngSwitchCase="'default'">
                    <app-basic-form [alertList]="alertList" [inputList]="inputList" [columnsPerRow]="columnsPerRow"
                        [form]="form" [alertTemplate]="alertTemplate" *ngIf="!loading"></app-basic-form>
                </ng-container>
                <ng-container *ngSwitchCase="'tabset'">
                    <app-tabset-form [alertList]="alertList" [tabList]="tabList" [form]="form" [mainInputList]="inputList"
                    [alertTemplate]="alertTemplate" *ngIf="!loading" [filledFieldList]="filledFieldList"></app-tabset-form>
                </ng-container>
                <ng-container *ngSwitchCase="'csvFile'">
                    <app-csv-file-upload-form
                        [alertTemplate]="alertTemplate"
                        [alertList]="alertList"
                        [inputList]="inputList"
                        [fileInput]="fileInput"
                        [csvData]="csvData"
                        [csvColumnList]="csvColumnList"
                        [form]="form"
                    ></app-csv-file-upload-form>
                </ng-container>
            </ng-container> -->
            <app-gen-form
                [inputList]="inputList"
                [tabList]="tabList"
                [filledFieldList]="filledFieldList"
                [maxWidth]="maxWidth"
                [maxHeight]="maxHeight"
                [fileInput]="fileInput"
                [templateName]="templateName"
                [alertList]="alertList"
                [form]="form"
                [columnsPerRow]="columnsPerRow"
                [loading]="loading"
                [csvColumnList]="csvColumnList"
                [csvData]="csvData"
            ></app-gen-form>
            <div class="row" *ngIf="loading">
                <div class="col-sm-12 p-4">
                    <app-point-cross-loader></app-point-cross-loader>
                </div>
            </div>
        <!-- </perfect-scrollbar> -->
        </ng-scrollbar>
    </ng-container>

    <ng-template #alertTemplate let-alert="alert">
        <!-- <div class="alert alert-danger" *ngIf="alert.error">
            <div class="text-center">
                <p><strong>{{alert.fieldAlias|titlecase}}: </strong><code>{{alert.error}}</code></p>
            </div>
        </div> -->
        <app-basic-alert [fieldAlias]="alert.fieldAlias" [error]="alert.error"></app-basic-alert>
    </ng-template>

    <ng-container footer>
        <div class="col-12" *ngIf="!loading">
            <ng-container *ngFor="let button of buttonList">
                <app-basic-button [iconClass]="button.iconClass" (onClickEvent)="onClickButton(button)"
                    [classList]="button.classList">
                    {{button.text|titlecase}}
                </app-basic-button>
            </ng-container>
        </div>
    </ng-container>
</app-basic-modal>