import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-white-modal',
    templateUrl: './white-modal.component.html',
    styleUrls: ['./white-modal.component.scss',

    ]
})
export class WhiteModalComponent implements OnInit, OnDestroy, OnChanges {

    visible: boolean = false;
    @Input() title: string = "";
    @Input() display: boolean = false;
    @Input() maxWidth: string = "1200px";
    @Output() onCloseEvent = new EventEmitter<any[]>()

    constructor(
        private cdr: ChangeDetectorRef
    ) {

    }

    ngOnChanges(changes: SimpleChanges): void {
        this.visible = this.display;
    }

    ngOnDestroy(): void {
    }

    ngOnInit() {
    }

    close() {
        console.log("CLOSING MODAL")
        this.visible = false;
        this.onCloseEvent.emit(undefined);
        this.cdr.detectChanges();
    }
}