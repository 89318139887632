import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'report-card-filter-title',
  templateUrl: './filter-title.component.html',
  styleUrl: './filter-title.component.scss'
})
export class ReportCardFilterTitleComponent {
  @Input() input: any;
  @Input() form: FormGroup;
}
