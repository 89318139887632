<script src="filter-card.component.ts"></script>
<report-card>
  <section class="filter" *ngFor="let filter of filterList">
    <app-selectable
      [name]="filter.name"
      [optionList]="filter.optionList"
      [label]="filter.label"
      [form]="form"
      (onChange)="filter.onChange($event)"
    >
    </app-selectable>
  </section>
  <app-gen-ngb-input *ngIf="stInput"
    [input]="stInput"
    [form]="form"
  ></app-gen-ngb-input>
  <app-gen-ngb-input *ngIf="stInput"
    [input]="etInput"
    [form]="form"
  ></app-gen-ngb-input>

  <section class="button-filter-bar">
    <div style="padding-top: 16px; margin-left: 10px">
      <app-basic-button
        [iconClass]="'fa fa-right'"
        [classList]="['btn', 'normal-btn']"
        (onClickEvent)="search.emit()"
      >Revisar</app-basic-button
      >
    </div>
  </section>
</report-card>
