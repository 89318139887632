import { Component, Input, OnDestroy } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Component({
    selector: 'app-basic-alert',
    templateUrl: './basic-alert.component.html',
    styleUrls: [
      './basic-alert.component.scss',
      
    ]
})
export class BasicAlertComponent{
    @Input() fieldAlias!: string;
    @Input() error: string|null = null;
}