import { Component } from '@angular/core';

@Component({
  selector: 'report-content',
  templateUrl: './content.component.html',
  styleUrl: './content.component.scss'
})
export class ReportContentComponent {

}
