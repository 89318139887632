import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'text-filter-input',
    templateUrl: 'text-filter-input.component.html',
    styleUrls: [
        './text-filter-input.component.scss'
    ]
})

export class TextFilterInputComponent implements OnInit, OnDestroy {
    @Input() placeholder!: string|null|undefined;
    @Input() icon!: string|null|undefined;
    @Output() onChange = new EventEmitter<string>();
    private value$ = new Subject<string>();
    private subscriptions: Subscription[] = [];
    constructor() { }

    ngOnInit() {
        this.subscriptions.push(
            this.value$.pipe(
                debounceTime(250)
            ).subscribe(value=>{
                this.onChange.emit(value);
            })
        )
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach(sub=>sub.unsubscribe());
    }
    keyup(event: any){
        const value = event.srcElement.value;
        this.value$.next(value);
    }
}