import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PERMISSION_TYPE } from '../models/permission.model';
import { PermissionManagerService } from '../services/permission-manager.service';


@Directive({
  selector: '[appIsGranted]'
})
export class IsGrantedDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionManagerS: PermissionManagerService
  ) { }
  @Input() set appIsGranted(permission: PERMISSION_TYPE | string) {
    // console.log("checking if is granted: ");console.dir(permission);
    this.isGranted(permission as PERMISSION_TYPE);
  }
  private isGranted(permission: PERMISSION_TYPE) {    
    if (this.permissionManagerS.isGranted(permission)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}