import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BasicService } from '../common/services/basic.service';
import { API_BARCODE_URL } from '../connection.data';

@Injectable()
export class BarcodeService{
    constructor(private http: HttpClient){
        
    }

    generateBarcode(code:string): Observable<any>{
        return this.http.get(API_BARCODE_URL+`/generate/${code}`, { responseType: 'blob' });
    }
}