import { Component } from '@angular/core';

@Component({
  selector: 'app-data-box',
  templateUrl: './data-box.component.html',
  styleUrl: './data-box.component.scss'
})
export class DataBoxComponent {

}
