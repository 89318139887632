import { Component } from '@angular/core';

@Component({
  selector: 'report-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class ReportHeaderComponent {

}
