import { Component } from '@angular/core';
import {ReportCardTitleComponent} from "../title/title.component";

@Component({
  selector: 'report-card-simple-title',
  templateUrl: './simple-title.component.html',
  styleUrl: './simple-title.component.scss'
})
export class ReportCardSimpleTitleComponent {

}
