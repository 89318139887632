<div class="col-md-12">
    <!-- <div *ngIf="errorForm == 10" class="alert alert-danger">
      <div class="text-center">
        <p><strong>Nombre de la empresa</strong><code>no válido</code> ó <strong>Vacío</strong></p>
      </div>
    </div> -->
    <ng-container *ngFor="let alert of alertList" [ngTemplateOutlet]="alertTemplate"
        [ngTemplateOutletContext]="{ alert: alert }"></ng-container>
</div>
<div class="col-sm-12 p-30">
    <form>
        <fieldset>
            <div class="row">
                <div [ngClass]="columnClass + ' form-group'" *ngFor="let input of inputList">
                    <!-- <div [ngClass]="columnClass"> -->
                        <app-gen-ngb-input [input]="input" [form]="form">

                        </app-gen-ngb-input>
                    <!-- </div> -->
                </div>
            </div>
        </fieldset>
    </form>
</div>