import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
@Component({
    selector: 'app-basic-form',
    templateUrl: './basic-form.component.html',
    styleUrls: ['./basic-form.component.scss',

    ]
})
export class BasicFormComponent implements OnInit, OnDestroy, OnChanges {


    private subscriptions: Subscription[] = [];

    @Input() alertTemplate!: TemplateRef<any>;
    @Input() alertList: any[] = [];
    @Input() inputList: any[] = [];
    @Input() columnsPerRow: number = 2;
    @Input() form!: UntypedFormGroup;

    columnClass: string = "col-sm-6";

    constructor(
        private cdr: ChangeDetectorRef
    ) {

    }

    ngOnChanges(changes: SimpleChanges): void {
        this.columnClass = `col-sm-${Math.round(12/this.columnsPerRow)}`;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    ngOnInit() {
        this.columnClass = `col-sm-${Math.round(12/this.columnsPerRow)}`;
        console.log("columns per row: ", this.columnsPerRow);
    }

    ngAfterViewInit() {
    }
}