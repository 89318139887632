import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-basic-button',
    templateUrl: './basic-button.component.html',
    styleUrls: ['./basic-button.component.scss',

    ]
})
export class BasicButtonComponent implements OnInit, OnDestroy {

    columnList: string[] = ["btn"];
    // @Input() align: string = "right";
    @Input() iconClass: string = "fa";
    @Input() classList: string[] = [];
    @Output() onClickEvent = new EventEmitter<any[]>()

    constructor(
        private cdr: ChangeDetectorRef
    ) {

    }

    ngOnDestroy(): void {
    }

    ngOnInit() {
    }

    onClick() {
        this.onClickEvent.emit(undefined);
        this.cdr.detectChanges();
    }
}