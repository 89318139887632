import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateBoolean'
})
export class TranslateBooleanPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    var return_string!: string;
    var option!: number;
    if (args){
      option = parseInt(args);
    }
    if (option == 1){
      if (value)
        return_string = 'Activo';
      else
        return_string = 'Inactivo';
    }else if (option == 2){
      if (value)
        return_string = 'Habilitado';
      else
        return_string = 'Bloqueado';
    }else if (option == 3){
      if (value)
        return_string = 'Sí';
      else
        return_string = 'No';
    } else if (option == 4){
      if (value == 'charging')
        return_string = 'Cobro';
      else
        return_string = 'Reembolso';
    }else if (option == 5){
      if (value == 'created')
        return_string = 'Creado';
      else if (value == 'erased')
        return_string = 'Anulado';
      else if (value == 'assigned')
        return_string = 'Asignado';        
      else 
        return_string = 'PROBLEMA';        
    }
    return return_string;
  }

}
