<!-- inicio modals -->
<div class="backdrop" [ngStyle]="{'display':visible?'block':'none'}"></div>
<div class="basic modal" tabindex="-1" role="dialog" [ngStyle]="{'display':visible?'block':'none'}" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered chris-modal" role="document" [style.max-width]="maxWidth">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{title}}</h4>
                <button type="button" (click)="close()" class="close basic-close" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-30">
                <ng-content select="[body]"></ng-content>
            </div>
            <div class="modal-footer">
                <ng-content select="[footer]"></ng-content>
            </div>
        </div>
    </div>
</div>