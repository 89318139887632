import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-basic-modal',
    templateUrl: './basic-modal.component.html',
    styleUrls: ['./basic-modal.component.scss',

    ]
})
export class BasicModalComponent implements OnInit, OnDestroy, OnChanges {

    visible: boolean = false;
    @Input() title: string = "";
    @Input() display: boolean = false;
    @Input() maxWidth: string = "1200px";
    @Output() onCloseEvent = new EventEmitter<any[]>()

    constructor(
        private cdr: ChangeDetectorRef
    ) {

    }

    ngOnChanges(changes: SimpleChanges): void {
      if(changes['display']) {
        console.log(`${this.title} changed display to ${changes['display'].currentValue}`)
        this.visible = changes['display'].currentValue;
      }
    }

    ngOnDestroy(): void {
    }

    ngOnInit() {
    }

    close() {
        console.log("CLOSING MODAL")
        this.visible = false;
        this.onCloseEvent.emit(undefined);
        this.cdr.detectChanges();
    }
}
