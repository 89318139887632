import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthService{
    constructor(protected http: HttpClient){

    }

    getUserIdentificator(){
        return localStorage.getItem('userId');
    }

    getToken(){
        return localStorage.getItem('token');
    }

    setToken(token: string){
        localStorage.setItem('token', token);
    }

    clear(){
        localStorage.removeItem('userId');
        localStorage.removeItem('profile_id');
        localStorage.removeItem('partner_name');
    }

    setlogin(login: string){
        localStorage.setItem('login', login);
    }

    getLogin(){
        return localStorage.getItem('login')
    }

    setUserIdentificator(userId: string){
        localStorage.setItem('userId', userId);
    }

    getProfileId(){
        return localStorage.getItem('profile_id');
    }

    setProfileId(profileId: string){
        localStorage.setItem('profile_id', profileId);
    }

    getPartnerName(){
        return localStorage.getItem('partner_name');
    }

    getPartnerId(){
        return localStorage.getItem('partner_id');
    }

    setPartnerName(partnerName: string){
        localStorage.setItem('partner_name', partnerName);
    }

    getUID(){
        return localStorage.getItem("uid")
    }
}