<app-basic-modal [display]="display" [title]="title" (onCloseEvent)="onClose()">

    <ng-container body>
        <app-tabset-form [alertList]="alertList" [tabList]="tabList" [form]="form"
            [alertTemplate]="alertTemplate" *ngIf="!loading">
        </app-tabset-form>
        <div class="row" *ngIf="loading">
            <div class="col-sm-12 p-4">
                <app-point-cross-loader></app-point-cross-loader>
            </div>
        </div>
        
    </ng-container>

    <ng-template #alertTemplate let-alert="alert">
        <!-- <div class="alert alert-danger" *ngIf="alert.error">
            <div class="text-center">
                <p><strong>{{alert.fieldAlias|titlecase}}: </strong><code>{{alert.error}}</code></p>
            </div>
        </div> -->
        <app-basic-alert [fieldAlias]="alert.fieldAlias" [error]="alert.error"></app-basic-alert>
    </ng-template>

    <ng-container footer>
        <div class="col-12" *ngIf="!loading">
            <ng-container  *ngFor="let button of buttonList" >
                <app-basic-button [iconClass]="button.iconClass"
                    (onClickEvent)="onClickButton(button)"
                    [classList]="button.classList">
                    {{button.text|titlecase}}
                </app-basic-button>
            </ng-container>
        </div>
    </ng-container>
</app-basic-modal>