import {Component} from '@angular/core';

@Component({
    selector: 'app-point-cross-loader',
    templateUrl: './point-cross-loader.component.html',
    styleUrls: [
        './point-cross-loader.component.scss'
    ]
})
export class PointCrossLoaderComponent {
    constructor() {
        
    }
}
