import { Pipe, PipeTransform } from '@angular/core';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({
//   standalone: true,
  name: 'showLastChars',
  pure: true
})
export class ShowLastCharsPipe implements PipeTransform {
  transform(text: string|null|undefined, nchars = 4): string {
    if(text == null || text == undefined || text.length-nchars<=0) return text as string;
    return "*".repeat(4)+text.slice(-nchars)
    // return "*".repeat(text.length-nchars)+text.slice(-nchars)
  }
}