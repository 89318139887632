export const environment = {
  production: true,
  // RIPLEY_ASSOC_ID: 148206,
  RIPLEY_ASSOC_ID: 148202,
  // SMU_ASSOC_ID: 148207,
  SMU_ASSOC_ID: 148203,
  role: 'Az023Sd0sgbnj0tywW',
  platform: 'clerticket',
  base_url: 'https://lab.clerticket.cl:45117',
  prometheus: {
    app: {
      API_MOVIL_SSL: 'clerticket_api_movil_https',
      API_SSL: 'clerticket_api_ssl',
      API: 'clerticket_api',
      CRON_RIPLEY: 'clerticket_cron',
      CRON_SMU: 'clerticket_cron_smu',
    },
    instance: {
      API_MOVIL_SSL: '192.168.2.19:9988',
      API_SSL: '192.168.2.19:9988',
      API: '192.168.2.19:9988',
      CRON_RIPLEY: '192.168.2.19:3006',
      CRON_SMU: '192.168.2.19:3002',
    }
  }
};
