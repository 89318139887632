import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators'
import { BarcodeService } from '../../../core/voucher/barcode.service';

@Component({
    selector: 'app-barcode-image',
    templateUrl: 'barcode-image.component.html',
    styleUrls: [
        'barcode-image.component.scss'
    ]
})

export class BarcodeImageComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    imageBytea: any;
    imageType: any;
    @Input() code!: string;

    constructor(
        private barcodeService: BarcodeService,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit() {
        this.subscriptions.push(
            this.barcodeService.generateBarcode(this.code)
            .subscribe((buffer) => {
                console.log("response: ");console.dir({buffer})
                try {
                    this.imageBytea = buffer;
                    this.imageType = 'png';
                } catch (err) {
                    console.log(err);
                    this.imageBytea = null;
                    this.imageType = null;
                }
                this.cdr.detectChanges();
            })
        );        
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}