export enum PERMISSION_TYPE {
    CREATE = 'CREATE',
    READ = 'READ',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
    OTHER = 'OTHER',
    CREATE_USER = 'CREATE_USER',
    EDIT_USER = 'EDIT_USER',
    BLOCK_USER = 'BLOCK_USER',
    CREATE_SMART_VOUCHER = 'CREATE_SMART_VOUCHER',
    EDIT_SMART_VOUCHER = 'EDIT_SMART_VOUCHER',
    DELETE_SMART_VOUCHER = 'DELETE_SMART_VOUCHER',
    DOWNLOAD_SMART_VOUCHER = 'DOWNLOAD_SMART_VOUCHER',
    VIEW_ALL_ASSOCIATED_ASSIGNATIONS = 'VIEW_ALL_ASSOCIATED_ASSIGNATIONS',
    ASSIGN_ASSOCIATED_TO_BENEFICIARY = 'ASSIGN_ASSOCIATED_TO_BENEFICIARY',
    ABORT_ASSOCIATED_ASSIGNATION = 'ABORT_ASSOCIATED_ASSIGNATION',
    EDIT_COMPANY = 'EDIT_COMPANY',
    UPDATE_COMPANY_STATE = 'UPDATE_COMPANY_STATE',
    CREATE_COMPANY = 'CREATE_COMPANY',
    EDIT_BENEFICIARY = 'EDIT_BENEFICIARY',
    CREATE_BENEFICIARY = 'CREATE_BENEFICIARY',
    UPDATE_BENEFICIARY_STATE = 'UPDATE_BENEFICIARY_STATE',
    DELETE_BENEFICIARY = 'DELETE_BENEFICIARY',
    DOWNLOAD_ASSOCIATED_ASSIGNATIONS = 'DOWNLOAD_ASSOCIATED_ASSIGNATIONS',
    DOWNLOAD_BENEFICIARIES = 'DOWNLOAD_BENEFICIARIES',
    DOWNLOAD_USERS = 'DOWNLOAD_USERS',
    DOWNLOAD_ASSOCIATED_PRODUCTS = 'DOWNLOAD_ASSOCIATED_PRODUCTS',
    REQ_SMART_VOUCHER_MOVEMENTS = 'REQ_SMART_VOUCHER_MOVEMENTS',
    VIEW_PROVIDER_COD = 'VIEW_PROVIDER_COD',
    DOWNLOAD_ASSOCIATEDS = 'DOWNLOAD_ASSOCIATEDS',
    EDIT_MONEDERO = 'EDIT_MONEDERO',
    DOWNLOAD_MONEDEROS = 'DOWNLOAD_MONEDEROS',
    DOWNLOAD_CAMPAIGNS = 'DOWNLOAD_CAMPAIGNS',
    CREDIT_SMART_VOUCHER = 'CREDIT_SMART_VOUCHER',
    REVERSE_SMART_VOUCHER = 'REVERSE_SMART_VOUCHER',
    ACTIVATE_SMART_VOUCHER = 'ACTIVATE_SMART_VOUCHER',
    DEACTIVATE_SMART_VOUCHER = 'DEACTIVATE_SMART_VOUCHER',
    REPLENISH_SMART_VOUCHER = 'REPLENISH_SMART_VOUCHER',
    SEE_BALANCE_COLUMN = 'SEE_BALANCE_COLUMN',
    UNBLOCK_SMART_VOUCHER = 'UNBLOCK_SMART_VOUCHER',
    ADM_VOUCHER_ACTION_BTN_LIST = 'ADM_VOUCHER_ACTION_BTN_LIST'
}

export enum ROLE {
    PLATFORM_ADMIN = 'adm',
    ADMIN = 'ent_adm',
    CUSTOMER = 'ent_comp',
    BENEFICIARY = 'ent_ben'
  }

export abstract class PermissionBase {
    public permissions: PERMISSION_TYPE[] = [];
    constructor() {}
}

export class PlatformAdminPermission extends PermissionBase {
    constructor() {
      super();
      this.permissions = [
        PERMISSION_TYPE.CREATE, PERMISSION_TYPE.READ, 
        PERMISSION_TYPE.UPDATE, PERMISSION_TYPE.DELETE,
        PERMISSION_TYPE.OTHER, PERMISSION_TYPE.CREATE_USER,
        PERMISSION_TYPE.EDIT_USER, PERMISSION_TYPE.BLOCK_USER,
        PERMISSION_TYPE.EDIT_COMPANY, PERMISSION_TYPE.UPDATE_COMPANY_STATE,
        PERMISSION_TYPE.CREATE_COMPANY, PERMISSION_TYPE.DELETE_SMART_VOUCHER,
        PERMISSION_TYPE.CREATE_BENEFICIARY, PERMISSION_TYPE.DELETE_BENEFICIARY,
        PERMISSION_TYPE.UPDATE_BENEFICIARY_STATE, PERMISSION_TYPE.EDIT_BENEFICIARY,
        PERMISSION_TYPE.VIEW_ALL_ASSOCIATED_ASSIGNATIONS, PERMISSION_TYPE.DOWNLOAD_ASSOCIATED_ASSIGNATIONS,
        PERMISSION_TYPE.DOWNLOAD_BENEFICIARIES, PERMISSION_TYPE.DOWNLOAD_USERS,
        PERMISSION_TYPE.DOWNLOAD_ASSOCIATED_PRODUCTS, PERMISSION_TYPE.REQ_SMART_VOUCHER_MOVEMENTS,
        PERMISSION_TYPE.VIEW_PROVIDER_COD, PERMISSION_TYPE.DOWNLOAD_ASSOCIATEDS,
        PERMISSION_TYPE.CREATE_SMART_VOUCHER, PERMISSION_TYPE.EDIT_SMART_VOUCHER,
        PERMISSION_TYPE.DELETE_SMART_VOUCHER, PERMISSION_TYPE.DOWNLOAD_SMART_VOUCHER,
        PERMISSION_TYPE.EDIT_MONEDERO, PERMISSION_TYPE.DOWNLOAD_MONEDEROS,
        PERMISSION_TYPE.DOWNLOAD_CAMPAIGNS,
        PERMISSION_TYPE.CREDIT_SMART_VOUCHER,
        PERMISSION_TYPE.REVERSE_SMART_VOUCHER,
        PERMISSION_TYPE.ACTIVATE_SMART_VOUCHER,
        PERMISSION_TYPE.DEACTIVATE_SMART_VOUCHER,
        PERMISSION_TYPE.REPLENISH_SMART_VOUCHER,
        PERMISSION_TYPE.SEE_BALANCE_COLUMN,
        PERMISSION_TYPE.UNBLOCK_SMART_VOUCHER,
        PERMISSION_TYPE.ADM_VOUCHER_ACTION_BTN_LIST
      ];
    }
}

export class PdiAdminPermission extends PermissionBase {
    constructor() {
      super();
      this.permissions = [
        PERMISSION_TYPE.CREATE, PERMISSION_TYPE.READ, 
        PERMISSION_TYPE.UPDATE, PERMISSION_TYPE.DELETE,
        PERMISSION_TYPE.OTHER, PERMISSION_TYPE.BLOCK_USER,
        PERMISSION_TYPE.EDIT_USER, PERMISSION_TYPE.EDIT_BENEFICIARY,
        PERMISSION_TYPE.UPDATE_BENEFICIARY_STATE, PERMISSION_TYPE.VIEW_ALL_ASSOCIATED_ASSIGNATIONS,
        PERMISSION_TYPE.DOWNLOAD_ASSOCIATED_ASSIGNATIONS, PERMISSION_TYPE.DOWNLOAD_BENEFICIARIES, 
        PERMISSION_TYPE.DOWNLOAD_USERS, PERMISSION_TYPE.DOWNLOAD_ASSOCIATED_PRODUCTS,
        PERMISSION_TYPE.REQ_SMART_VOUCHER_MOVEMENTS, PERMISSION_TYPE.VIEW_PROVIDER_COD,
        PERMISSION_TYPE.DOWNLOAD_ASSOCIATEDS, PERMISSION_TYPE.CREATE_SMART_VOUCHER, 
        PERMISSION_TYPE.DOWNLOAD_SMART_VOUCHER,
      ];
    }
}

export class BeneficiaryPermission extends PermissionBase {
    constructor() {
      super();
      this.permissions = [
        PERMISSION_TYPE.CREATE, PERMISSION_TYPE.READ, 
        PERMISSION_TYPE.UPDATE, PERMISSION_TYPE.OTHER,
        PERMISSION_TYPE.EDIT_USER, PERMISSION_TYPE.ASSIGN_ASSOCIATED_TO_BENEFICIARY,
        PERMISSION_TYPE.ABORT_ASSOCIATED_ASSIGNATION
      ];
    }
}