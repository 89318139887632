import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'report-filter-card',
  templateUrl: './filter-card.component.html',
  styleUrl: './filter-card.component.scss'
})
export class FilterCardComponent {
  @Input() filterList: any[] = [];
  @Input() form: FormGroup;
  @Input() stInput: any;
  @Input() etInput: any;
  @Output() search = new EventEmitter();
}
