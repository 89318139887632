import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { PermissionFactory } from '../factory/permission.factory';
import { PermissionBase, PERMISSION_TYPE, ROLE } from '../models/permission.model';

@Injectable()
export class PermissionManagerService {
  private permissions?: PermissionBase;
  constructor() { }

  isGranted(permission: PERMISSION_TYPE) {
    const permissionInstance = PermissionFactory.getInstance();
    // console.log("permission Instance: ");console.dir(permissionInstance)
    const permissions = permissionInstance.permissions;
    for (let perm of permissions) {
      if (perm === permission){
        return true;
      }
    }
    // console.log("usuario no tiene permiso de ", permission)
    return false;
  }
  authAs(role: ROLE) {
    localStorage.setItem(environment.role,
      (role === null)
        ? ROLE.BENEFICIARY
        : role
    );
    console.log("AUTHENTICATED GRANTS PERMISSION FOR ", role)
    PermissionFactory.clean();
    this.permissions = PermissionFactory.getInstance();
  }
}