<div class="page-body">
  <app-modal-form
    [title]="formModal.title"
    [display]="formModal.display"
    [loading]="formModal.blocked"
    [maxWidth]="formModal.maxWidth"
    [maxHeight]="formModal.maxHeight"
    [inputList]="inputList"
    [alertList]="alertList"
    [form]="form"
    [buttonList]="formModalButtonList"
    [columnsPerRow]="columnsPerRow"
    [fileInput]="fileInput"
    [templateName]="templateName"
    [tabList]="tabList"
    [csvData]="csvData"
    [csvColumnList]="csvColumnList"
    [filledFieldList]="filledFieldList"
    (onClickEvent)="onClickButton($event)"
    (onCloseEvent)="onCloseModal(formModal)">
  </app-modal-form>

  <!-- <app-basic-modal [display]="pdfModal.display" [title]="pdfModal.title" (onCloseEvent)="()=>pdfModal.display=false">
    <ng-container body *ngIf="currentPdfBlob">
      <ngx-extended-pdf-viewer
        [src]="currentPdfBlob"
        [height]="'95%'" 
        [useBrowserLocale]="true" 
        [textLayer]="true" 
        [showHandToolButton]="true">
      </ngx-extended-pdf-viewer>
    </ng-container>

    <ng-container footer>
        <div class="col-12">
            <app-basic-button [iconClass]="'fa fa-close'" [align]="'right'"
                (onClickEvent)="closePdfModal()"
                [classList]="['btn', 'btn-danger']">
                Cerrar
            </app-basic-button>
        </div>
    </ng-container>
  </app-basic-modal> -->

  <app-basic-modal [display]="deleteModal.display" [title]="deleteModal.title" (onCloseEvent)="onCloseModal(deleteModal)">
    <ng-container body>
      <div class="p-3">
        <h6>¿Desea eliminar el registro de {{name}}?</h6>
      </div>
      <app-point-cross-loader *ngIf="deleteModal.blocked"></app-point-cross-loader>
    </ng-container>

    <ng-container footer>
        <div class="col-12" *ngIf="!deleteModal.blocked">
            <app-basic-button [iconClass]="'fa fa-close f-left'"
                (onClickEvent)="onCloseModal(deleteModal)"
                [classList]="['btn', 'btn-danger']">
                Cerrar
            </app-basic-button>

            <app-basic-button [iconClass]="'fa fa-close f-right'"
                (onClickEvent)="deleteContractTemplate()"
                [classList]="['btn', 'btn-warning']">
                Eliminar
            </app-basic-button>
        </div>
    </ng-container>
  </app-basic-modal>

  <div class="page-encabezado col-sm-12">
    <div class="row p-10">
      <div class="col-10">
        <h1><i class="titulo-seguridad fa-titulo color-3 fa ti-layers p-r-5"></i> Administración de {{pluralName}}</h1>
        <ul class="breadcrumb-title titulo-seguridad color-3">
          <li class="breadcrumb-item color-2"><a [routerLink]="['/pages/home/']">Inicio</a></li>
          <li class="breadcrumb-item color-2"><a href="javascript:">Administración de {{pluralName}}</a></li>
        </ul>
      </div>
      <div class="ayuda col-2">
        <ng-template #tipContent>{{helpText}}</ng-template>
        <button *ngIf="helpText" type="button" class="btn btn-warning circular-button" [ngbTooltip]="tipContent" placement="left" (click)="clickHelp()">
          ?
        </button>
        <!-- <a href="javascript:" class="box-ayuda" title="ayuda" placement="left" ngbPopover="En esta sección es posible hacer cambios relacionados con el contrato que actualmente esta operando" popoverTitle="Ayuda">?</a> -->
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-md-12 contenido administracion">
      <div class="row" style="width:100%">
        <div class="col-sm-12">            
          <div class="filter-bar" *ngIf="enableCommonActions.downloadCSV">
            <div style="padding-top: 20px;">
              <app-basic-button
                  [iconClass]="'fa fa-download'"
                  [classList]="['btn', 'btn-crear-tarj me-2']"
                  (onClickEvent)="downloadCSV()"
                >Exportar XLSX</app-basic-button>
            </div>
          </div>
        </div>          
        <div class="filter-bar col-sm-12">
          <app-basic-button class="justify-item-right" *ngIf="enableCommonActions.add"
            [iconClass]="'fa fa-plus'"
            [classList]="['btn', 'btn-crear-tarj']"
            (onClickEvent)="onClickAdd()">
            Agregar
          </app-basic-button>

          <div class="filter" *ngFor="let filter of filterList">
            <ng-container [ngSwitch]="filter.type">
              <ng-container *ngSwitchCase="'text'">
                <text-filter-input
                  [placeholder]="'filter.placeholder'"
                  [icon]="filter.icon"
                  (onChange)="filter.onChange($event)"
                ></text-filter-input>
              </ng-container>
              <ng-container *ngSwitchCase="'select'">
                <app-selectable
                  [name] = "filter.name"
                  [label] = "filter.label || ''"
                  [optionList]= "filter.optionList || []"
                  (onChange) = "filter.onChange($event)"
                ></app-selectable>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <app-selectable 
                  [customClass] = "'filtro-select'"
                  [name] = "filter.name"
                  [optionList]= "filter.optionList || []"
                  [placeholder] = "filter.placeholder || 'seleccione una opción'"
                  (onChange) = "filter.onChange($event)"
                ></app-selectable>
              </ng-container>
            </ng-container>
          </div>
          
          <label class="dt-cust-search-a" style="width: 20%;">
            <div class="input-group ">
              <span class="input-group-prepend-a" id="basic-addon1"><label class="input-group-text"><i class="icofont icofont-search"></i></label></span>
              <input
                type='text'
                class="search-a"
                placeholder='{{searchPlaceholder || "Busqueda por campos relevantes"}}'
                (keyup)='search($event)'
              />
            </div>
          </label>
        </div>
        <app-basic-table class="admvouchers-table" style="padding:0;"
          [columnList]="columnList"
          [rowsObs] = "rowsObs"
          [actionBtnList] = "getActionBtnList()"
          (getRowsEvent) = "getRows($event)"
          (clickActionBtnEvent) = "onClickActionBtn($event)"
          [totalCount]="totalCount"
          [shouldHideActionBtn]="shouldHideActionBtn"
          (onSort)="onSort($event)"
        >

        </app-basic-table>
      </div>
  </div>
</div>
<br><br><br><br><br><br>
<!-- .assets/icon/icofont/css/icofont.scss -->

<ng-template #deleteDialog>
  <div style="padding:15px">
      <h1 mat-dialog-title>Eliminación de registro de {{name}}</h1>
      <div mat-dialog-content>
          <p>Está usted seguro de que desea eliminar este registro de {{name}}?</p>
      </div>
      <div mat-dialog-actions>
          <button mat-button (click)="onNoClick()">Cancelar</button>
          <button mat-button 
              cdkFocusInitial>Eliminar</button>
      </div>
  </div>
</ng-template>