import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
declare let Plotly: any;

export interface PSerie {
  data?: any[],
  labels?: any[],
  name: string,
  opacity?: number,
  marker?: {
    color: string,
    line?: {
      color: string,
      width?: number
    }
  }
  width?: number,
  visible?: 'legendonly' | boolean,
  orientation?: 'h' | 'v'
}

function makeid(length: number) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function ColorGenerator() {
  const colorList = [
    '#9F1717',
    '#EFAB58',
    '#D6925F',
    '#70A16F',
    '#3F5B4F',
    '#044B7F',
    '#B9D6F2',
    '#242325'
  ];
  let colorIdx = 0;
  return {
    generate: ()=>{
      if(colorIdx > colorList.length) colorIdx = 0;
      const color = colorList[colorIdx];
      colorIdx += 1;
      return color;
    }
  }
}

@Component({
  selector: 'app-pstacked-bars',
  templateUrl: './pstacked-bars.component.html',
  styleUrls: ['./pstacked-bars.component.scss']
})
export class PstackedBarsComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  trace1 = {
    x: ['2024-01-01', '2024-01-02', '2024-01-03'],
    y: [20, 14, 23],
    name: 'SF Zoo',
    type: 'bar',
    opacity: 0.5,
    marker: {
      color: 'rgb(158,202,225)',
      line: {
        color: 'rgb(8,48,107)',
        width: 1.5
      }
    }
  };

  trace2 = {
    x: ['2024-01-01', '2024-01-02', '2024-01-03'],
    y: [12, 18, 29],
    name: 'LA Zoo',
    type: 'bar'
  };

  @Input() series: PSerie[] = [];
  @Input() xTickLabels: string[] = [];
  @Input() xRange!: any[];

  data: any[] = [];
  // [
  //   {
  //     "data": [
  //       500
  //     ],
  //     "name": "Vigente",
  //     "marker": {
  //       "color": "#b31919",
  //       "line": {
  //         "color": "#b31919",
  //         "width": 1
  //       }
  //     },
  //     "y": [
  //       500
  //     ],
  //     "x": [
  //       "2023-12-19"
  //     ],
  //     "type": "bar"
  //   },
  //   {
  //     "data": [
  //       0
  //     ],
  //     "name": "Compra realizada",
  //     "marker": {
  //       "color": "#b31919",
  //       "line": {
  //         "color": "#b31919",
  //         "width": 1
  //       }
  //     },
  //     "y": [
  //       0
  //     ],
  //     "x": [
  //       "2023-12-19"
  //     ],
  //     "type": "bar"
  //   }
  // ]

  layout: any = {
    barmode: 'stack',
    autosize: true,
    width: '100%',
    height: '100%',
    showlegend: true,
    margin: {"t": 30, "b": 30, "l": 30, "r": 30},
    bargap: 0.30,
    // autotypenumbers: "strict",
    autorange: true,
    autorangeoptions: {
      minallowed: 0
    },
    // yaxis:{
    //   rangemode: 'tozero'
    // },
    xaxis: {
      type: 'category',
      automargin: true
    },
    // yaxis: {
    //   type: 'log',
    //   autorange: true
    // },
    autoshift: true,
    // xaxis: {
    //   range: ['2024-01-01', '2024-01-03']
    // }
  };

  loaded = false;

  divId = makeid(6)

  changes$ = new Subject();
  subscriptions: Subscription[] = [];

  constructor() { }

  ngOnInit() {

    // this.resize();
    this.subscriptions.push(
      this.changes$.pipe(
        throttleTime(500)
      ).subscribe(()=>{
        this.buildData();
        this.buildLayout();
        if(!this.loaded){
          console.log("data: ", this.data)
          this.loaded = true;
          setTimeout(()=>Plotly.newPlot(this.divId, this.data, this.layout, {locale: 'es'}))

        }
        else
          setTimeout(()=>Plotly.newPlot(this.divId, this.data, this.layout, {locale: 'es'}))
          // Plotly.update(this.divId, this.data, this.layout, {locale: 'es'})
      })
    )
  }

  ngAfterViewInit(): void {
    this.resize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.changes$.next(true);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s=>s.unsubscribe())
  }

  buildData(){
    const data = [];
    for(let serie of this.series){
      const orientation = _.get(serie, 'orientation', 'v');
      const serie_ = Object.assign({}, serie, {
        y: orientation == 'v' ? serie.data: (serie.labels || this.xTickLabels),
        x: orientation == 'v' ? (serie.labels || this.xTickLabels): serie.data,
        type: 'bar',
        width: _.get(serie, 'width', 1),
        showlegend: true,
        automargin: true,
        visible: _.get(serie, 'visible', true),
        // orientation: orientation,
      })
      delete serie_['data'];
      data.push(serie_);
    }
    console.log("data created: ");console.dir(data);
    console.log("working data: ");console.dir([this.trace1, this.trace2])
    this.data = data;// [this.trace1, this.trace2];
  }

  buildLayout(){
    if(this.xRange){
      this.layout['xaxis'] = {
        range: this.xRange,
        tickmode: 'array'
      }
    }
  }

  resize(){
    const div = document.getElementById(this.divId);
    this.layout.width = div?.getBoundingClientRect().width;
    this.layout.height = div?.getBoundingClientRect().height;
    this.changes$.next(true);
  }
}
