<div id="pcoded" class="pcoded"
     [attr.nav-type]="navType"
     [attr.theme-layout]="themeLayout"
     [attr.horizontal-placement]="verticalPlacement"
     [attr.horizontal-layout]="verticalLayout"
     [attr.pcoded-device-type]="pcodedDeviceType"
     [attr.vertical-effect]="verticalEffect"
     [attr.vnavigation-view]="vnavigationView"
     [attr.fream-type]="freamType"
     [attr.sidebar-img]="sidebarImg"
     [attr.sidebar-img-type]="sidebarImgType"
     [attr.layout-type]="layoutType"
     (window:resize)="onResize($event)"
  > <!-- [attr.vertical-nav-type]="verticalNavType" -->
  <div class="pcoded-overlay-box"></div>
  <div class="pcoded-container navbar-wrapper">
    <nav *ngIf="userId" class="navbar header-navbar pcoded-header" [attr.header-theme]="headerTheme" [attr.pcoded-header-position]="pcodedHeaderPosition">
      <div class="navbar-wrapper">
        <div *ngIf="userId" class="navbar-logo">
          <!-- [exclude]="'#main_navbar'" -->
          <a class="mobile-menu" id="mobile-collapse" href="javascript:" (click)="toggleOpened()" (clickOutside)="onClickedOutsideSidebar($event)">
            <i class="ti-menu"></i>
          </a>
          <a [routerLink]="['/pages/home/']">
            <img class="img-fluid" src="assets/images/logo-cler-small-white-2.png" alt="cler ticket" />
          </a>
          <a href="javascript:" class="mobile-options" (click)="toggleHeaderNavRight()">
            <i class="ti-more"></i>
          </a>
        </div>
        <div class="navbar-container container-fluid">
          <ul *ngIf="userId" [@mobileHeaderNavRight]="navRight" [ngClass]="navRight" class="nav-right">
            <!--<li class="">
              <a href="javascript:" (click)="toggleChat()">
                <i class="ti-comments"></i>
                <span class="badge bg-c-green"></span>
              </a>
            </li>
            -->
            <li (clickOutside)="notificationOutsideClick('profile')" class="user-profile header-notification" [ngClass]="profileNotificationClass" (click)="toggleProfileNotification()">
              <a href="javascript:">
                <span>{{user_login}}</span>
                <span *ngIf="has_parent == 1">[{{partner_name}}]</span>
                <i class="ti-angle-down"></i>
              </a>
              <ul [@notificationBottom]="profileNotification" class="show-notification profile-notification">
                <!--
                <li>
                  <a href="javascript:">
                    <i class="ti-settings"></i> Configuraciones
                  </a>
                </li>
                <li>
                  <a [routerLink]="['/email/inbox/']">
                    <i class="ti-email"></i> Mis Mensajes
                  </a>
                </li>
                -->
                <li>
                  <a (click)="logout()" [routerLink]="['/auth/login']">
                    <i class="ti-layout-sidebar-left"></i> Salir
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          <!-- Sidebar chat start -->
          <div [@slideInOut]="chatSlideInOut" id="sidebar" class="users p-chat-user showChat" [ngClass]="chatSlideInOut">
            <div class="had-container">
              <div class="card card_main p-fixed users-main">
                <div class="user-box">
                  <div class="chat-search-box">
                    <a class="back_friendlist" (click)="toggleChat()">
                      <i class="fa fa-chevron-left"></i>
                    </a>
                    <div class="right-ti-control input-group">
                      <input class="form-control  search-text" placeholder="Search Friend" id="search-friends">
                      <div class="form-icon input-group-append">
                        <label class="input-group-text"><i class="fa fa-search"></i></label>
                      </div>
                    </div>
                  </div>
                  <div class="main-friend-list">
                      <div class="media userlist-box" *ngFor="let user of dataUri" data-username="msg" placement="left"
                        ngbTooltip="{{user.login}}" (click)="toggleInnerChat()">
                        <input type="hidden" #name value="{{user.login}}" />
                        <a class="media-left" href="javascript:">
                          <img class="media-object img-radius" src="assets/images/avatar-4.jpg" alt="Alice">
                          <div class="live-status bg-success"></div>
                        </a>
                        <div class="media-body">
                          <div class="f-13 chat-header">
                            {{user.login}}
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Sidebar inner chat start-->
          <div [@slideInOut]="innerChatSlideInOut" class="showChat_inner">
            <div class="media chat-inner-header">
              <a class="back_chatBox">
                <i class="fa fa-chevron-left" (click)="toggleInnerChat()"></i> Josephin Doe
              </a>
            </div>
            <div class="media chat-messages">
              <a class="media-left photo-table" href="javascript:">
                <img class="media-object img-radius img-radius m-t-5" src="assets/images/avatar-3.jpg" alt="Generic placeholder image">
              </a>
              <div class="media-body chat-menu-content">
                <div class="">
                  <p class="chat-cont">I'm just looking around. Will you tell me something about yourself?</p>
                  <p class="chat-time">8:20 a.m.</p>
                </div>
              </div>
            </div>
            <div class="media chat-messages">
              <div class="media-body chat-menu-reply">
                <div class="">
                  <p class="chat-cont">I'm just looking around. Will you tell me something about yourself?</p>
                  <p class="chat-time">8:20 a.m.</p>
                </div>
              </div>
              <div class="media-right photo-table">
                <a href="javascript:">
                  <img class="media-object img-radius img-radius m-t-5" src="assets/images/avatar-4.jpg" alt="Generic placeholder image">
                </a>
              </div>
            </div>
            <div class="chat-reply-box p-b-20">
              <div class="right-ti-control input-group">
                <input type="text" class="form-control search-text" placeholder="Share Your Thoughts">
                <div class="form-icon input-group-append">
                  <label class="input-group-text"><i class="fa fa-paper-plane"></i></label>
                </div>
              </div>
            </div>
          </div>
          <!-- Sidebar inner chat end-->
        </div>
      </div>
    </nav>
    <div class="pcoded-main-container" [style.margin-top]="headerFixedMargin">
      <!-- adjust dev <nav *ngIf="panel == 0" -->
      <!--<nav *ngIf="false"      -->
        <!-- [exclude]="'#mobile-collapse'" -->
      <nav *ngIf="panel == 0"
        id="main_navbar"
        class="pcoded-navbar"
        (clickOutside)="onClickedOutsideSidebar($event)"
        
        [attr.pcoded-header-position]="pcodedHeaderPosition"
        [attr.navbar-theme]="navBarTheme"
        [attr.active-item-theme]="activeItemTheme"
        sub-item-theme="theme2"
        active-item-style="style0"
        [attr.pcoded-navbar-position]="pcodedSidebarPosition"
      >
      <div class="space"></div>
        <!-- <div *ngIf="panel == 0 && profile_id == 'adm'" class="pcoded-inner-navbar" appAccordion> -->
        <div *ngIf="panel == 0" class="pcoded-inner-navbar" appAccordion>
              <span *ngFor="let asideItems of menuItemList" class="d-color">
                <ul class="menu-main-item pcoded-item pcoded-left-item" [attr.item-border]="itemBorder" [attr.item-border-style]="itemBorderStyle" [attr.subitem-border]="subItemBorder" *ngFor="let asideItem of asideItems.main" appAccordionLink group="{{asideItem.state}}">
                  <li [routerLinkActive]="['active']" *ngIf="asideItem.type === 'external'" appAccordionLink group="{{asideItem.state}}">
                    <a appTrigger href="{{asideItem.external}}" target="{{asideItem.target ? '_blank' : '_self'}}" appAccordionToggle>
                      <span class="pcoded-micon"><i class="{{ asideItem.icon }}"></i><b>{{ asideItem.short_label }}</b></span>
                      <span class="pcoded-mtext">{{ asideItem.name }}</span>
                      <span *ngFor="let asideBadge of asideItem.badge" class="pcoded-badge label label-{{ asideBadge.type }}">{{asideBadge.value}}</span>
                      <span class="pcoded-mcaret"></span>
                    </a>
                  </li>
                  <li [routerLinkActive]="['active']" *ngIf="asideItem.type === 'link'" appAccordionLink group="{{asideItem.state}}">
                    <a appTrigger [routerLink]="['/', asideItem.main_state, asideItem.state]" appAccordionToggle *ngIf="!asideItem.target && asideItem.main_state">
                      <span class="pcoded-micon"><i class="{{ asideItem.icon }}"></i><b>{{ asideItem.short_label }}</b></span>
                      <span class="pcoded-mtext">{{ asideItem.name }}</span>
                      <span *ngFor="let asideBadge of asideItem.badge" class="pcoded-badge label label-{{ asideBadge.type }}">{{asideBadge.value}}</span>
                      <span class="pcoded-mcaret"></span>
                    </a>
                    <a [routerLink]="['/', asideItem.main_state, asideItem.state]" target="_blank" appAccordionToggle *ngIf="asideItem.target && asideItem.main_state">
                      <span class="pcoded-micon"><i class="{{ asideItem.icon }}"></i><b>{{ asideItem.short_label }}</b></span>
                      <span class="pcoded-mtext">{{ asideItem.name }}</span>
                      <span *ngFor="let asideBadge of asideItem.badge" class="pcoded-badge label label-{{ asideBadge.type }}">{{asideBadge.value}}</span>
                      <span class="pcoded-mcaret"></span>
                    </a>
                    <a appTrigger [routerLink]="['/pages/'+ asideItem.state]" *ngIf="!asideItem.target && !asideItem.main_state" appAccordionToggle>
                      <span class="pcoded-micon"><i class="{{ asideItem.icon }}"></i><b>{{ asideItem.short_label }}</b></span>
                      <span class="pcoded-mtext">{{ asideItem.name }}</span>
                      <span *ngFor="let asideBadge of asideItem.badge" class="pcoded-badge label label-{{ asideBadge.type }}">{{asideBadge.value}}</span>
                      <span class="pcoded-mcaret"></span>
                    </a>
                    <a [routerLink]="['/pages/'+ asideItem.state]" *ngIf="asideItem.target && !asideItem.main_state" target="_blank" appAccordionToggle>
                      <span class="pcoded-micon"><i class="{{ asideItem.icon }}"></i><b>{{ asideItem.short_label }}</b></span>
                      <span class="pcoded-mtext">{{ asideItem.name }}</span>
                      <span *ngFor="let asideBadge of asideItem.badge" class="pcoded-badge label label-{{ asideBadge.type }}">{{asideBadge.value}}</span>
                      <span class="pcoded-mcaret"></span>
                    </a>
                  </li>
                  <li [routerLinkActive]="['active']" *ngIf="asideItem.type === 'sub'" class="pcoded-hasmenu" [attr.dropdown-icon]="dropDownIcon" [attr.subitem-icon]="subItemIcon" appAccordionLink group="{{asideItem.state}}">
                    <a class="main-click-trigger" [routerLinkActive]="['active']" href="javascript:;" appAccordionToggle>
                      <span class="pcoded-micon"><i class="{{ asideItem.icon }}"></i><b>{{ asideItem.short_label }}</b></span>
                      <span class="pcoded-mtext">{{ asideItem.name }}</span>
                      <span *ngFor="let asideBadge of asideItem.badge" class="pcoded-badge label label-{{ asideBadge.type }}">{{asideBadge.value}}</span>
                      <span class="pcoded-mcaret"></span>
                    </a>
                    <ul class="pcoded-submenu">
                      <ng-template ngFor let-asideChildren [ngForOf]="asideItem.children">
                        <li [routerLinkActive]="['active']" *ngIf="asideChildren.type !== 'sub' " appAccordionLink group="{{asideChildren.state}}">
                          <a appTrigger [routerLink]="['/', asideChildren.main_state, asideChildren.state]" appAccordionToggle *ngIf="!asideChildren.target && asideChildren.main_state">
                            <span class="pcoded-micon"><i class="{{ asideChildren.icon }}"></i><b>{{ asideChildren.short_label }}</b></span>
                            <span class="pcoded-mtext">{{ asideChildren.name }}</span>
                            <span *ngFor="let asideBadge of asideChildren.badge" class="pcoded-badge label label-{{ asideBadge.type }}">{{asideBadge.value}}</span>
                            <span class="pcoded-mcaret"></span>
                          </a>
                          <a [routerLink]="['/', asideChildren.main_state, asideChildren.state]" target="_blank" appAccordionToggle *ngIf="asideChildren.target && asideChildren.main_state">
                            <span class="pcoded-micon"><i class="{{ asideChildren.icon }}"></i><b>{{ asideChildren.short_label }}</b></span>
                            <span class="pcoded-mtext">{{ asideChildren.name }}</span>
                            <span *ngFor="let asideBadge of asideChildren.badge" class="pcoded-badge label label-{{ asideBadge.type }}">{{asideBadge.value}}</span>
                            <span class="pcoded-mcaret"></span>
                          </a>
                          <a appTrigger [routerLink]="['/pages/'+ asideChildren.state]" *ngIf="!asideChildren.target && !asideChildren.main_state" appAccordionToggle>
                            <span class="pcoded-micon"><i class="{{ asideChildren.icon }}"></i><b>{{ asideChildren.short_label }}</b></span>
                            <span class="pcoded-mtext">{{ asideChildren.name }}</span>
                            <span *ngFor="let asideBadge of asideChildren.badge" class="pcoded-badge label label-{{ asideBadge.type }}">{{asideBadge.value}}</span>
                            <span class="pcoded-mcaret"></span>
                          </a>
                          <a [routerLink]="['/pages/'+ asideChildren.state]" *ngIf="asideChildren.target && !asideChildren.main_state" target="_blank" appAccordionToggle>
                            <span class="pcoded-micon"><i class="{{ asideChildren.icon }}"></i><b>{{ asideChildren.short_label }}</b></span>
                            <span class="pcoded-mtext">{{ asideChildren.name }}</span>
                            <span *ngFor="let asideBadge of asideChildren.badge" class="pcoded-badge label label-{{ asideBadge.type }}">{{asideBadge.value}}</span>
                            <span class="pcoded-mcaret"></span>
                          </a>
                        </li>

                        <li [routerLinkActive]="['active']" class="pcoded-hasmenu" [attr.dropdown-icon]="dropDownIcon" [attr.subitem-icon]="subItemIcon" *ngIf="asideChildren.type === 'sub'" appAccordionLink group="sub-toggled">
                          <a href="javascript:;" appAccordionToggle>
                            <span class="pcoded-micon"><i class="ti-direction-alt"></i></span>
                            <span class="pcoded-mtext">{{ asideChildren.name }}</span>
                            <span *ngFor="let asideChildrenBadge of asideChildren.badge" class="pcoded-badge label label-{{ asideChildrenBadge.type }}">{{asideChildrenBadge.value}}</span>
                            <span class="pcoded-mcaret"></span>
                          </a>
                          <ul class="pcoded-submenu">
                            <ng-template ngFor let-asideChildrenSub [ngForOf]="asideChildren.children">
                              <li [routerLinkActive]="['active']" *ngIf="asideChildrenSub.type !== 'sub'" appAccordionLink>
                                <a appTrigger [routerLink]="['/', asideChildren.state, asideChildrenSub.state]" *ngIf="!asideChildrenSub.target">
                                  <span class="pcoded-micon"><i class="ti-angle-right"></i></span>
                                  <span class="pcoded-mtext">{{ asideChildrenSub.name }}</span>
                                  <span *ngFor="let asideChildrenSubBadge of asideChildrenSub.badge" class="pcoded-badge label label-{{ asideChildrenSubBadge.type }}">{{asideChildrenSubBadge.value}}</span>
                                  <span class="pcoded-mcaret"></span>
                                </a>
                                <a [routerLink]="['/', asideChildren.state, asideChildrenSub.state]" *ngIf="asideChildrenSub.target" target="_blank">
                                  <span class="pcoded-micon"><i class="ti-angle-right"></i></span>
                                  <span class="pcoded-mtext">{{ asideChildrenSub.name }}</span>
                                  <span *ngFor="let asideChildrenSubBadge of asideChildrenSub.badge" class="pcoded-badge label label-{{ asideChildrenSubBadge.type }}">{{asideChildrenSubBadge.value}}</span>
                                  <span class="pcoded-mcaret"></span>
                                </a>
                              </li>

                              <li [routerLinkActive]="['active']" class="pcoded-hasmenu" [attr.dropdown-icon]="dropDownIcon" [attr.subitem-icon]="subItemIcon" *ngIf="asideChildrenSub.type === 'sub'" appAccordionLink group="sub-super-toggled">
                                <a href="javascript:;" appAccordionToggle>
                                  <span class="pcoded-micon"><i class="ti-direction-alt"></i></span>
                                  <span class="pcoded-mtext">{{ asideChildrenSub.name }}</span>
                                  <span *ngFor="let asideChildrenSubBadge of asideChildrenSub.badge" class="pcoded-badge label label-{{ asideChildrenSubBadge.type }}">{{asideChildrenSubBadge.value}}</span>
                                  <span class="pcoded-mcaret"></span>
                                </a>
                                <ul class="pcoded-submenu">
                                  <ng-template ngFor let-asideChildrenSubSuper [ngForOf]="asideChildrenSub.children">
                                    <li [routerLinkActive]="['active']" appAccordionLink>
                                      <a appTrigger [routerLink]="['/', asideChildren.state, asideChildrenSub.state, asideChildrenSubSuper.state]" *ngIf="!asideChildrenSubSuper.target">
                                        <span class="pcoded-micon"><i class="ti-angle-right"></i></span>
                                        <span class="pcoded-mtext">{{ asideChildrenSubSuper.name }}</span>
                                        <span *ngFor="let asideChildrenSubSuperBadge of asideChildrenSubSuper.badge" class="pcoded-badge label label-{{ asideChildrenSubSuperBadge.type }}">{{asideChildrenSubSuperBadge.value}}</span>
                                        <span class="pcoded-mcaret"></span>
                                      </a>
                                      <a [routerLink]="['/', asideChildren.state, asideChildrenSub.state, asideChildrenSubSuper.state]" *ngIf="asideChildrenSubSuper.target" target="_blank">
                                        <span class="pcoded-micon"><i class="ti-angle-right"></i></span>
                                        <span class="pcoded-mtext">{{ asideChildrenSubSuper.name }}</span>
                                        <span *ngFor="let asideChildrenSubSuperBadge of asideChildrenSubSuper.badge" class="pcoded-badge label label-{{ asideChildrenSubSuperBadge.type }}">{{asideChildrenSubSuperBadge.value}}</span>
                                        <span class="pcoded-mcaret"></span>
                                      </a>
                                    </li>
                                  </ng-template>
                                </ul>
                              </li>
                            </ng-template>
                          </ul>
                        </li>
                      </ng-template>
                    </ul>
                  </li>
                </ul>
              </span>
        </div>
      </nav>
      &nbsp;
      <div class="pcoded-wrapper">
        <div class="pcoded-content">
          <div class="pcoded-inner-content">
            <div class="main-body">
              <div class="page-wrapper">
                <router-outlet>
                  <app-title></app-title>
                  <!-- <app-breadcrumbs></app-breadcrumbs> -->
                  <app-spinner></app-spinner>
                </router-outlet>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div *ngIf="chrisfooter == 1" class="pcoded-container navbar-wrapper no-bg footer">
    <div class="col-sm-12">
      <div class="row">
        <div class="col-sm-12 col-md-2 footer-logo text-center">
          <img class="mx-auto d-block" src="/assets/images/footer-logo.png" width="121" height="45" alt="">
        </div>
        <div class="col-sm-12 col-md-10 p-10">
          <div class="col-sm-12 footer-links">
            <a [routerLink]="['/pages/home/']"><i class="fa fa-angle-double-right footer-color"></i> Inicio</a>
            <a (click)="footer_click('help_table')"><i class="fa fa-angle-double-right footer-color"></i> Mesa de Ayuda</a>
            <a (click)="footer_click('sales')"><i class="fa fa-angle-double-right footer-color"></i> Ventas</a>
            <a (click)="footer_click('contact')"><i class="fa fa-angle-double-right footer-color"></i> Contacto</a>
          </div>
          <div class="col-sm-12 footer-contenido">
            CLER: LA RESPUESTA QUE QUEDA BIEN: Vestuario Corporativo, Medios de Pago, Insumos de Oficina, Insumos para Emergencias
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
