import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-modal-tabset-form',
    templateUrl: './modal-tabset-form.component.html',
    styleUrls: ['./modal-tabset-form.component.scss',

    ]
})
export class ModalTabsetFormComponent implements OnInit, OnDestroy {

    @Input() title: string = "";
    @Input() display: boolean = false;
    @Input() tabList: any[] = [];
    @Input() alertList: any[] = [];
    @Input() form!: UntypedFormGroup;
    @Input() buttonList: any[] = [];
    @Input() loading: boolean = false;
    @Output() onClickEvent = new EventEmitter<any>()
    @Output() onCloseEvent = new EventEmitter<any>();

    constructor(
        private cdr: ChangeDetectorRef
    ) {

    }

    ngOnDestroy(): void {
    }

    ngOnInit() {
    }

    onClickButton(button_: any) {
        this.onClickEvent.emit(button_);
        this.cdr.detectChanges();
    }

    onClose(){
        this.onCloseEvent.emit();
    }
}