<div class="page-encabezado col-sm-12">
  <div class="row">
    <div class="col-10">
      <h1>
        <i
          class="titulo-seguridad fa-solid fa-square-poll-vertical color-3 p-r-15"
        ></i
        ><ng-content></ng-content>
      </h1>
      <!-- <ul class="breadcrumb-title titulo-seguridad color-3">
          <li class="breadcrumb-item color-2"><a [routerLink]="['/pages/home/']">Inicio</a></li>
          <li class="breadcrumb-item color-2"><a href="javascript:">Tarjetas</a></li>
        </ul> -->
    </div>
    <!-- <div class="ayuda col-2">
        <a href="javascript:" class="box-ayuda" title="ayuda" placement="left" ngbPopover="En esta sección es posible revisar las compras junto con el listado de productos asociados a cada compra" popoverTitle="Ayuda">?</a>
      </div> -->
  </div>
</div>
