<section class="chart-header-container">
  <div class="chart-header">
    <span><ng-content select="[icon]"></ng-content></span>
    <div class="chart-header-text">
      <h6><ng-content select="[title]"></ng-content></h6>
      <span><ng-content select="[subtitle]"></ng-content></span>
    </div>
  </div>
  <div class="voucher-filter-bar">
    <app-gen-ngb-input
      [input]="input"
      [form]="form"
    ></app-gen-ngb-input>
  </div>
</section>
