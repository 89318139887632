import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
// import "rxjs/add/operator/map";
import { BASE_URL } from '../../core/connection.data';
import { Observable ,  of } from 'rxjs';
import { QueryParamsModel } from '../../core/_base/crud';
import { map, switchMap } from 'rxjs/operators';

@Injectable()

export class DatosService {
  //REf: https://medium.com/@joshuamichaelwaggoner/what-to-do-when-your-angular-app-runs-out-of-javascript-heap-space-ee0f8a43d8b7

  /**
   * FOR PRODUCTION
   *
   * node --max_old_space_size=8048 ./node_modules/@angular/cli/bin/ng build --aot --prod
   *
   */
  //private host_port = "https://plataforma.clerticket.cl:45034"       //PRODUCTION
  // private host_port = "http://localhost:3001"                      //DEVELOPMENT LOCAL
  //private host_port = "https://lab.clerticket.cl:7485"              //DEVELOPMENT ATCAS ||
  constructor(
    private _http: HttpClient
    ) {}

    //AUTHENTICATION
    getUserName(name: string){
      const urlDataUserName = BASE_URL+"/user/"+name;
      return this._http.get(urlDataUserName).pipe(map(res=>res));
    }

    login(credentials:any){
      const loginUrl = BASE_URL+"/users/login";
      var post_data = credentials;
      return this._http.post(loginUrl,post_data).pipe(map(res=>res));
    // }*/
    // login(post_data){
    //   const loginUrl = BASE_URL+"/users/login";
      // return this._http.post(loginUrl,post_data).pipe(map(res=>res));
    }

    registerLoginDate(name: string){
      const urlRegisterLoginDate = BASE_URL+"/userLogin/"+name;
      return this._http.get(urlRegisterLoginDate).pipe(map(res=>res));
    }

    forgot_password(login: string, is_email: string | boolean | number){
      const urlRegisterLoginDate = BASE_URL+`/users/forgot_password?login=${login}&is_email=${is_email}`;
      return this._http.get(urlRegisterLoginDate).pipe(map(res=>res));
    }
    request_password_forgot(login: string){
      const urlRegisterLoginDate = BASE_URL+`/users/request_password_forgot?login=${login}`;
      return this._http.get(urlRegisterLoginDate).pipe(map(res=>res));
    }
    validate_token(token:string, token_type: string){
      const urlRegisterLoginDate = BASE_URL+`/users/validate_token?token=${token}&token_type=${token_type}`;
      return this._http.get<any>(urlRegisterLoginDate).pipe(map(res=>res));
    }
    update_token(pass_id: string | number){
      var post_data = {
        pass_id:pass_id
      }
      const url = BASE_URL+"/users/validate_token";
      return this._http.post<any>(url, post_data).pipe(map(res=>res));
    }
    password(uid: string | number, new_password: string, pass_id: string|number){
      var post_data = {
        uid:uid,
        new_password:new_password,
        pass_id:pass_id
      }
      const url = BASE_URL+"/users/password";
      return this._http.post<any>(url, post_data).pipe(map(res=>res));
    }
    check_email_rut(email:string, rut:string, check_type: string){
      const urlRegisterLoginDate = BASE_URL+`/users/check_email_rut?email=${email}&rut=${rut}&check_type=${check_type}`;
      return this._http.get<any>(urlRegisterLoginDate).pipe(map(res=>res));
    }
    create_user_beneficiary(post_data: any){
      const url = BASE_URL+"/users/create_user_beneficiary";
      return this._http.post<any>(url, post_data).pipe(map(res=>res));
    }
    create_web_associated(post_data: any){
      const url = BASE_URL+"/associated/web_associated";
      return this._http.post<any>(url, post_data).pipe(map(res=>res));
    }
    get_region(){
      const urlRegisterLoginDate = BASE_URL+`/users/region`;
      return this._http.get<any>(urlRegisterLoginDate).pipe(map(res=>res));
    }
    get_banks(){
      const urlRegisterLoginDate = BASE_URL+`/associated/bank`;
      return this._http.get<any>(urlRegisterLoginDate).pipe(map(res=>res));
    }
    get_bank_account_types(){
      const urlRegisterLoginDate = BASE_URL+`/associated/bank_account_tyoe`;
      return this._http.get<any>(urlRegisterLoginDate).pipe(map(res=>res));
    }

    get_cities(region_id: string|number){
      const urlRegisterLoginDate = BASE_URL+`/users/cities_by_region?region_id=${region_id}`;
      return this._http.get<any>(urlRegisterLoginDate).pipe(map(res=>res));
    }
    get_country(){
      const urlRegisterLoginDate = BASE_URL+`/users/country`;
      return this._http.get<any>(urlRegisterLoginDate).pipe(map(res=>res));
    }
    get_civil_status(){
      const urlRegisterLoginDate = BASE_URL+`/users/civil_status`;
      return this._http.get<any>(urlRegisterLoginDate).pipe(map(res=>res));
    }
    get_commune(){
      const urlRegisterLoginDate = BASE_URL+`/users/commune`;
      return this._http.get<any>(urlRegisterLoginDate).pipe(map(res=>res));
    }
    getUserCompanies(id: string){
      const urlDataUser = BASE_URL+"/get_res_companies/"+id;
      return this._http.get(urlDataUser).pipe(map(res=>res));
    }

    getCompany(){
      const urlDataCompanies = BASE_URL+"/get_companies";
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_company(id:string){
      const urlDataCompanies = BASE_URL+"/get_companies/"+id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_companiesByIdandProfile(id:string,profile_id:string,mode:string){
      const urlDataCompanies = BASE_URL+"/get_companies/"+id+"/"+profile_id+"/"+mode;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_entitiesByIdandProfile(id:string,profile_id:string){
      const urlDataCompanies = BASE_URL+"/get_entities/"+id+"/"+profile_id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_adquisitionByIdandProfile(id:string,profile_id:string,mode:string){
      const urlDataCompanies = BASE_URL+"/get_adquisition_units/"+id+"/"+profile_id+"/"+mode;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_dccp_reports(id:string,profile_id:string){
      const urlDataCompanies = BASE_URL+"/get_dccp_reports/"+id+"/"+profile_id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_dccp_reports_by_id(id:string){
      const urlDataCompanies = BASE_URL+"/get_dccp_reports_by_id/"+id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_nourishmentreportcsv(){
      const urlDataCompanies = BASE_URL+`/report/nourishmentreportcsv`;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_purchasesreportcsv(id: string|number, profile_id: string|number){
      const urlDataCompanies = BASE_URL+`/report/purchasesreportcsv?id=${id}&profile_id=${profile_id}`;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_admvoucherreportcsv(id: string|number, profile_id: string|number,  options={}){
      let url = BASE_URL+`/report/admvoucherreportcsv`;
      const body = Object.assign({id, profile_id}, {options});
      console.log("GET ADM VOUCHERR REPORT CSV BODY", body)
      return this._http.post(url, body).pipe(map(res=>res));
    }
    get_giftcardreportcsv(){
      const urlDataCompanies = BASE_URL+`/report/giftcardreportcsv`;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_saleorderreportcsv(id: string|number, profile_id: string|number){
      const urlDataCompanies = BASE_URL+`/report/saleorderreportcsv?id=${id}&profile_id=${profile_id}`;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }

    put_cler_dccp_report(write_uid:string,mode:string,src_id:string,voucher_ids:string,purchase_ids:string){
      const urlDataCompanies = BASE_URL+"/put_cler_dccp_report?write_uid="+write_uid+"&mode="+mode+"&src_id="+src_id+"&voucher_ids="+voucher_ids+"&purchase_ids="+purchase_ids;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    put_cler_full_dccp_report(write_uid:string){
      const urlDataCompanies = BASE_URL+"/put_cler_full_dccp_report?write_uid="+write_uid;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }

    get_beneficiaryByIdandProfile(id:string,profile_id:string,mode:string){
      const urlDataCompanies = BASE_URL+"/get_beneficiary/"+id+"/"+profile_id+"/"+mode;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    deleteBeneficiary(id: string|number){
      const urlDataCompanies = BASE_URL+`/beneficiary/beneficiary?id=${id}`
      return this._http.delete(urlDataCompanies).pipe(map(res=>res));
    }


    // put_beneficiary(name : string, rut : string, entity_id : string, adquisition_unit_id : string, email : string, phone : string, mobile : string, street : string, city : string, zip : string, region : string, create_uid : number){
    put_beneficiary(item: any){
      // const urlDataCompanies = BASE_URL+"/put_beneficiary?name="+name+"&rut="+rut+"&entity_id="+entity_id+"&adquisition_unit_id="+adquisition_unit_id+"&email="+email+"&phone="+phone+"&mobile="+mobile+"&street="+street+"&city="+city+"&zip="+zip+"&region="+region+"&create_uid="+create_uid;
      const urlDataCompanies = BASE_URL+"/put_beneficiary"
      let params = new HttpParams();
      Object.keys(item).forEach(key=>{
        if(item[key]!=undefined)params = params.set(key, item[key]);
      })
      return this._http.get(urlDataCompanies, {params}).pipe(map(res=>res));
    }

    // updateBeneficiary(name : string, rut : string, entity_id : string, adquisition_unit_id : string, email : string, phone : string, mobile : string, street : string, city : string, zip : string, region : string, uid : number, id: string){
    updateBeneficiary(item: any){
      // const urlDataCompanies = BASE_URL+"/updateBeneficiary?name="+name+"&rut="+rut+"&entity_id="+entity_id+"&adquisition_unit_id="+adquisition_unit_id+"&email="+email+"&phone="+phone+"&mobile="+mobile+"&street="+street+"&city="+city+"&zip="+zip+"&region="+region+"&uid="+uid+"&id="+id;
      const urlDataCompanies = BASE_URL+"/updateBeneficiary"
      let params = new HttpParams();
      Object.keys(item).forEach(key=>{
        if(item[key]!=undefined)params = params.set(key, item[key]);
      })
      return this._http.get(urlDataCompanies, {params}).pipe(map(res=>res));
    }

    put_cler_voucher_transaction(write_uid : number|string, entity_id : number, adquisition_unit_id : number , cler_voucher_id : number, transaction_code : string , amount : number, associated_id  : number,  invoice_number : string, company_id : number, beneficiary_id : number, current_amount : number, is_admin_sale : string ){
      const urlDataCompanies = BASE_URL+"/put_cler_voucher_transaction?transaction_code="+transaction_code+"&cler_voucher_id="+cler_voucher_id+"&entity_id="+entity_id+"&adquisition_unit_id="+adquisition_unit_id+"&invoice_number="+invoice_number+"&amount="+amount+"&associated_id="+associated_id+"&company_id="+company_id+"&write_uid="+write_uid+"&beneficiary_id="+beneficiary_id+"&current_amount="+current_amount+"&is_admin_sale="+is_admin_sale;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    refunding_cler_voucher_transaction(write_uid : number|string, cler_voucher_id : number, amount : number,cler_voucher_transacion_id:number){
      const urlDataCompanies = BASE_URL+"/refunding_cler_voucher_transaction?write_uid="+write_uid+"&cler_voucher_id="+cler_voucher_id+"&amount="+amount+"&cler_voucher_transacion_id="+cler_voucher_transacion_id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }

    put_user(login : string, password : any, partner_id : string, create_uid : string, res_group : string,is_evoucher:string, entity_id: string|undefined){
      let url = BASE_URL+"/put_users?login="+login+"&password="+password+"&partner_id="+partner_id+"&create_uid="+create_uid+"&res_group="+res_group+"&is_evoucher="+is_evoucher;
      if(entity_id){
        url +=`&entity_id=${entity_id}`
      }
      return this._http.get(url).pipe(map(res=>res));
    }
    update_user(login : string, password : any, partner_id : string, create_uid : string, res_group : string,res_user_id:string, entity_id: string|undefined){
      let url = BASE_URL+"/update_user?login="+login+"&password="+password+"&partner_id="+partner_id+"&create_uid="+create_uid+"&res_group="+res_group+"&res_user_id="+res_user_id;
      if(entity_id){
        url +=`&entity_id=${entity_id}`
      }
      return this._http.get(url).pipe(map(res=>res));
    }
    old_get_associatedByIdandProfile(id:string,profile_id:string){
      const urlDataCompanies = BASE_URL+"/get_associated/"+id+"/"+profile_id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_associatedByIdandProfile(id: string|number, profile_id: string|number){
      const urlDataCompanies = BASE_URL+`/associated/associated?uid=${id}&profile_id=${profile_id}`;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_associatedByIdandProfile2(id:string,profile_id:string, page: string, regexp:string | undefined){
      const urlDataCompanies = BASE_URL+"/get_associated2/"+id+"/"+profile_id+"/"+page+"/"+regexp;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_associatedByCategory(id:string,profile_id:string, category_id:string){
      const urlDataCompanies = BASE_URL+"/get_associatedByCategory/"+id+"/"+profile_id+"/"+category_id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_associatedBySaleOrder(id:string,profile_id:string, sale_order_id:string){
      const urlDataCompanies = BASE_URL+"/get_associatedBySaleOrder/"+id+"/"+profile_id+"/"+sale_order_id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_a2produtsByaId(associated_id: string){
      const urlDataCompanies = BASE_URL+"/get_a2produtsByaId/"+associated_id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_a2voucherByaId(associated_id: string){
      const urlDataCompanies = BASE_URL+"/get_a2voucherByaId/"+associated_id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    put_associated(name:string,rut:string,email:string,phone:string,mobile:string,website:string,street:string,city:string,zip:string,region:string,google_lat:string,
      google_long:string,product_types:string,payment_methods:string,create_uid:string
      ){
      const urlDataCompanies = BASE_URL+"/put_associated?name="+name+"&rut="+rut+"&email="+email+
      "&phone="+phone+"&mobile="+mobile+"&website="+website+"&street="+street+"&city="+city+"&zip="+zip+"&region="+region+"&google_lat="+google_lat+
      "&google_long="+google_long+"&product_types="+product_types+"&payment_methods="+payment_methods+"&create_uid="+create_uid;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    /**
     * ASSOCIATED
     *
     */
    post_put_associated(post_data: any){
      const url = BASE_URL+"/associated/associated";
      return this._http.post<any>(url, post_data).pipe(map(res=>res));
    }
    update_associated2(post_data: any){
      const url = BASE_URL+"/associated/associated";
      return this._http.put<any>(url, post_data).pipe(map(res=>res));
    }
    update_post_associated(post_data:any){
      const url = BASE_URL+"/associated/update_associated_groups";
      return this._http.post<any>(url,post_data).pipe(map(res=>res));
    }
    delete_associated_group(id: string|number){
      const url = BASE_URL+`/associated/associated_groups?id=${id}`;
      return this._http.delete<any>(url).pipe(map(res=>res));
    }
    get_associated_groups(){
      const url = BASE_URL+"/associated/associated_groups";
      return this._http.get(url).pipe(map(res=>res));
    }

    // update_associated(name:string,rut:string,email:string,phone:string,mobile:string,website:string,street:string,city:string,zip:string,region:string,google_lat:string,
    //   google_long:string,product_types:string,payment_methods:string,create_uid:string, associated_id : string,voucher_provider_type_id,
    //   ){
    update_associated(item:any){
      // const urlDataCompanies = BASE_URL+"/update_associated?name="+name+"&rut="+rut+"&email="+email+
      // "&phone="+phone+"&mobile="+mobile+"&website="+website+"&street="+street+"&city="+city+"&zip="+zip+"&region="+region+"&google_lat="+google_lat+
      // "&google_long="+google_long+"&product_types="+product_types+"&payment_methods="+payment_methods+"&create_uid="+create_uid+"&associated_id="+associated_id+'&voucher_provider_type_id='+voucher_provider_type_id;
      const url = BASE_URL+"/update_associated";
      let params = new HttpParams();
      Object.keys(item).forEach(key=>{
        if(item[key]!=undefined)params = params.set(key, item[key]);
      });
      return this._http.get(url, {params}).pipe(map(res=>res));
    }
    put_update_associated(body: any){
      const urlDataCompanies = BASE_URL+"/associated/update_associated"
      return this._http.put<any>(urlDataCompanies,body).pipe(map(res=>res));
    }

    getUser(){
      const urlDataUser = BASE_URL+"/users/users";
      return this._http.get(urlDataUser).pipe(map(res=>res));
    }
    toggleUserStatus(id:string,profile_id:string){
      const urlDataUser = BASE_URL+"/toggle_user_status/"+id+"/"+profile_id;
      return this._http.get(urlDataUser).pipe(map(res=>res));
    }
    toggleEntityStatus(id:string,status:boolean,uid:string){
      const urlDataUser = BASE_URL+"/toggle_entity_status/"+id+"/"+status+"/"+uid;
      return this._http.get(urlDataUser).pipe(map(res=>res));
    }
    //SALEORDER
    /*getSaleOrder(id:string,profile_id:string){
      const urlDataCompanies = BASE_URL+"/get_clersaleorder/"+id+"/"+profile_id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }*/
    getSaleOrder(id:string,profile_id:string){
      const urlDataCompanies = BASE_URL+`/saleorder/saleorder?id=${id}&profile_id=${profile_id}`
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    /*getSaleOrder2(id:string,profile_id:string,role:string){
      const urlDataCompanies = BASE_URL+"/get_clersaleorder2/"+id+"/"+profile_id+"/"+role;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }*/
    getSaleOrder2(id:string,profile_id:string,role:string){
      const urlDataCompanies = BASE_URL+`/saleorder/clersaleorder?id=${id}&profile_id=${profile_id}&role=${role}`;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_sale_order_name_category(id:string){
      const urlDataCompanies = BASE_URL+"/get_sale_order_name_category/"+id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }

    get_products_from_sale_order(sale_order_id:string){
      const urlDataCompanies = BASE_URL+"/get_products_from_sale_order/"+sale_order_id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }

    get_payments_from_sale_order(sale_order_id:string){
      const urlDataCompanies = BASE_URL+`/voucher/get_payments_from_sale_order?sale_order_id=${sale_order_id}`;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }


    get_cler_sale_order_c_amount(sale_order_number:string){
      const urlDataCompanies = BASE_URL+"/get_cler_sale_order_c_amount/"+sale_order_number;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    put_clerSaleorder(sale_order_number:string,invoice_number:string,s_entity:number,s_adquisition_unit:number,sale_order_amount:number,iva:number,
      sale_order_total:number,discount_amount:number,discount_p:number,bonification_amount:number,bonification_p:number,
      cler_voucher_amount:number,dispatch_address:string,notes:string,associated_list:string,product_list:string,payment_list:string,emission_date:string,
      invoice_date:string,uid:string,cler_product_category_id:number){
      const urlDataCompanies = BASE_URL+"/put_clerSaleorder?sale_order_number="+sale_order_number+"&invoice_number="+invoice_number+"&s_entity="+s_entity+"&s_adquisition_unit="+s_adquisition_unit+
      "&sale_order_amount="+sale_order_amount+"&iva="+iva+"&sale_order_total="+sale_order_total+"&discount_amount="+discount_amount+"&discount_p="+discount_p+"&bonification_amount="+bonification_amount+
      "&bonification_p="+bonification_p+"&cler_voucher_amount="+cler_voucher_amount+"&dispatch_address="+dispatch_address+"&notes="+notes+"&associated_list="+associated_list+
      "&product_list="+product_list+"&payment_list="+payment_list+"&emission_date="+emission_date+"&invoice_date="+invoice_date+"&uid="+uid+"&cler_product_category_id="+cler_product_category_id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    put_clerSaleorder2(post_data: any){
      const urlDataCompanies = BASE_URL+"/saleorder/saleorder"
      return this._http.post(urlDataCompanies,post_data).pipe(map(res=>res));
    }


    update_cler_sale_order(dispatch_address:string,notes:string,associated_list:string,product_list:string,payment_list:string,emission_date:string,
      invoice_date:string,uid:string,sale_order_id:string,invoice_number:string,max_voucher_amount: string|number){
        const urlDataCompanies = BASE_URL+"/update_cler_sale_order?dispatch_address="+dispatch_address+"&notes="+notes+"&associated_list="+associated_list+
        "&product_list="+product_list+"&payment_list="+payment_list+"&emission_date="+emission_date+"&invoice_date="+invoice_date+"&uid="+uid+"&sale_order_id="+sale_order_id+"&invoice_number="+invoice_number+"&max_voucher_amount="+max_voucher_amount;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    update_cler_sale_order2(dispatch_address:string,notes:string,associated_list:string,product_list:string,payment_list:string,emission_date:string,
      invoice_date:string,uid:string,sale_order_id:string,invoice_number:string,max_voucher_amount: string|number,
      ext_providers_sync, max_sync_date, voucher_provider_ids){
        var post_data = {
          dispatch_address:dispatch_address,
          notes:notes,
          associated_list:associated_list,
          product_list:product_list,
          payment_list:payment_list,
          emission_date:emission_date,
          invoice_date:invoice_date,
          uid:uid,
          sale_order_id:sale_order_id,
          invoice_number:invoice_number,
          max_voucher_amount:max_voucher_amount,
          ext_providers_sync: ext_providers_sync,
          max_sync_date: max_sync_date,
          voucher_provider_ids: voucher_provider_ids
        }
        const urlDataCompanies = BASE_URL+"/saleorder/update_cler_sale_order";
        return this._http.put(urlDataCompanies,post_data).pipe(map(res=>res));
    }
  update_cler_sale_order3(item: any){
    const url = BASE_URL+"/saleorder/update_cler_sale_order";
    return this._http.put(url,item);
  }
    update_cler_sale_order_created(sale_order_number:string,invoice_number:string,s_entity:number,s_adquisition_unit:number,sale_order_amount:number,iva:number,
      sale_order_total:number,discount_amount:number,discount_p:number,bonification_amount:number,bonification_p:number,
      cler_voucher_amount:number,dispatch_address:string,notes:string,associated_list:string,product_list:string,payment_list:string,emission_date:string,
      invoice_date:string,uid:string,sale_order_id:string,max_voucher_amount: string|number){
      const urlDataCompanies = BASE_URL+"/update_cler_sale_order_created?sale_order_number="+sale_order_number+"&invoice_number="+invoice_number+"&s_entity="+s_entity+"&s_adquisition_unit="+s_adquisition_unit+
      "&sale_order_amount="+sale_order_amount+"&iva="+iva+"&sale_order_total="+sale_order_total+"&discount_amount="+discount_amount+"&discount_p="+discount_p+"&bonification_amount="+bonification_amount+
      "&bonification_p="+bonification_p+"&cler_voucher_amount="+cler_voucher_amount+"&dispatch_address="+dispatch_address+"&notes="+notes+"&associated_list="+associated_list+
      "&product_list="+product_list+"&payment_list="+payment_list+"&emission_date="+emission_date+"&invoice_date="+invoice_date+"&uid="+uid+"&sale_order_id="+sale_order_id+"&max_voucher_amount="+max_voucher_amount;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    update_cler_sale_order_created2(sale_order_number:string,invoice_number:string,s_entity:number,s_adquisition_unit:number,sale_order_amount:number,iva:number,
      sale_order_total:number,discount_amount:number,discount_p:number,bonification_amount:number,bonification_p:number,
      cler_voucher_amount:number,dispatch_address:string,notes:string,associated_list:string,product_list:string,payment_list:string,emission_date:string,
      invoice_date:string,uid:string,sale_order_id:string,max_voucher_amount: string|number){
      var post_data = {
        sale_order_number:sale_order_number,
        invoice_number:invoice_number,
        s_entity:s_entity,
        s_adquisition_unit:s_adquisition_unit,
        sale_order_amount:sale_order_amount,
        iva:iva,
        sale_order_total:sale_order_total,
        discount_amount:discount_amount,
        discount_p:discount_p,
        bonification_amount:bonification_amount,
        bonification_p:bonification_p,
        cler_voucher_amount:cler_voucher_amount,
        dispatch_address:dispatch_address,
        notes:notes,
        associated_list:associated_list,
        product_list:product_list,
        payment_list:payment_list,
        emission_date:emission_date,
        invoice_date:invoice_date,
        uid:uid,
        sale_order_id:sale_order_id,
        max_voucher_amount:max_voucher_amount
      }
      const urlDataCompanies = BASE_URL+"/saleorder/update_cler_sale_order_created";
      return this._http.put(urlDataCompanies,post_data).pipe(map(res=>res));
    }
    disable_cler_sale_order(sale_order_id:string,sale_order_number:string){
        const urlDataCompanies = BASE_URL+"/disable_sale_order?sale_order_id="+sale_order_id+"&sale_order_number="+sale_order_number;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }

    getsale2associated(sale_order_id:string){
        const urlDataCompanies = BASE_URL+"/getsale2associated?sale_order_id="+sale_order_id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    getsale2voucher(sale_order_id:string){
      const urlDataCompanies = BASE_URL+"/getsale2voucher?sale_order_id="+sale_order_id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    getsale2product(sale_order_id:string){
        const urlDataCompanies = BASE_URL+"/getsale2product?sale_order_id="+sale_order_id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_dump_provsales(){
      const urlDataCompanies = BASE_URL+"/get_dump_provsales";
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }

    get_dump_product(){
      const urlDataCompanies = BASE_URL+"/get_dump_product";
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }

    getSaleOrderTransaction(id:string,profile_id:string){
      const urlDataCompanies = BASE_URL+"/get_cler_associated_transaction/"+id+"/"+profile_id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_cler_associated_invoice_number(id:string,profile_id:string): Observable<any>{
      const urlDataCompanies = BASE_URL+`/consolidation/get_cler_associated_invoice_number?id=${id}&profile_id=${profile_id}`;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    put_conciliation(uid:string,associated_id:string,cler_voucher_transaction_id:string,data:any,
        is_partial_c: string|boolean|number, total_conciliation: string|number, c_complete: string|boolean|number,
        consolidation_date: string|Date, payment_date: string|Date){
      const urlDataCompanies = BASE_URL+`/consolidation/put_conciliation`
      return this._http.put(urlDataCompanies,{
        id: uid,
        cler_voucher_transaction_id: cler_voucher_transaction_id,
        associated_id: associated_id,
        data: data,
        is_partial_c: is_partial_c,
        total_conciliation: total_conciliation,
        c_complete: c_complete,
        consolidation_date: consolidation_date,
        payment_date: payment_date
      }).pipe(map(res=>res));
    }

    put_qr_map(uid:string,associated_id:string,cler_voucher_transaction_id:string,data:string){
      const urlDataCompanies = BASE_URL+"/put_conciliation?id="+uid+"&cler_voucher_transaction_id="+cler_voucher_transaction_id+
      "&associated_id="+associated_id+"&data="+data;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_qr_map(associated_id:string,qr_type:string,qr_code:string){
      const urlDataCompanies = BASE_URL+"/get_qr_map?associated_id="+associated_id+"&qr_type="+qr_type+"&qr_code="+qr_code;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }

    getVoucherTransaction(id:string,profile_id:string){
      const urlDataCompanies = BASE_URL+"/get_cler_voucher_transacion/"+id+"/"+profile_id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    getClerTransactionfromVoucherId(id:string){
      const urlDataCompanies = BASE_URL+"/get_cler_transacion_from_voucher_id/"+id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }

    get_cler_voucher(id:string,profile_id:string){
      const urlDataCompanies = BASE_URL+`/voucher/get_cler_voucher?id=${id}&profile_id=${profile_id}`;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }

    getRipleyBalance(voucher: any){
      // const urlDataCompanies = BASE_URL+`/voucher/ripley/balance/${id}?code=${code}`;
      let url = '';
      if(voucher.id) url = BASE_URL+`/voucher/ripley/balance/${voucher.id}/`;
      if(voucher.code) url = BASE_URL+`/voucher/ripley/balance?code=${voucher.code}`;
      return this._http.get(url).pipe(map(res=>res));
    }

    getVouchersPage(queryParams: QueryParamsModel, id:string, profile_id:string){
      const url = BASE_URL+`/voucher/page`;
      const body = Object.assign(Object.assign({}, queryParams), {id, profile_id});
      return of(null).pipe(
        switchMap(()=>this._http.post(url, body).pipe(map(res=>res)))
      );
    }

    get_cler_voucher_replacement(id:string,profile_id:string){
      const urlDataCompanies = BASE_URL+`/voucher/replacement?id=${id}&profile_id=${profile_id}`
      return this._http.get(urlDataCompanies).pipe(map(res=>res));

    }
    get_external_vouchers_data(data: any){
      const urlDataCompanies = BASE_URL+`/voucher/get_external_vouchers_data?data=${data}`
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    /*put_cler_voucher(uid:string, amount :number, code : string, beneficiary_id : string,
      validation_period :string, entity_id :string, adquisition_unit_id :string, cler_sale_order_id : string,
      cler_voucher_type_id :string, is_cler_ticket : string, ticket_number :string, password: string, amount_to_be_charge:number){
      const urlDataCompanies = BASE_URL+"/put_cler_voucher?id="+uid+"&amount="+amount+"&code="+code+"&beneficiary_id="+beneficiary_id
      +"&validation_period="+validation_period+"&entity_id="+entity_id+"&adquisition_unit_id="+adquisition_unit_id+"&cler_sale_order_id="+cler_sale_order_id
      +"&cler_voucher_type_id="+cler_voucher_type_id+"&is_cler_ticket="+is_cler_ticket+"&ticket_number="+ticket_number+"&password="+password+"&amount_to_be_charge="+amount_to_be_charge;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }*/

    // put_cler_voucher(uid:string, amount :number, code : string, beneficiary_id : string,
    //   validation_period :string, entity_id :string, adquisition_unit_id :string, cler_sale_order_id : string,
    //   cler_voucher_type_id :string, is_cler_ticket : string, ticket_number :string, password, amount_to_be_charge,voucher_provider_id,
    //   assignation_req_id){
    put_cler_voucher(voucher: any){
      const urlDataCompanies = `${BASE_URL}/voucher/cler_voucher`
      return this._http.post(urlDataCompanies,voucher).pipe(map(res=>res));
    }

    get_cler_voucher_status(id:string){
      const urlDataCompanies = BASE_URL+"/get_cler_voucher_status/"+id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_cler_voucher_from_sale(id:string){
      const urlDataCompanies = BASE_URL+"/get_cler_voucher_from_sale/"+id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_cler_voucher_from_code(code:string){
      const urlDataCompanies = BASE_URL+"/get_cler_voucher_from_code/"+code;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_cler_voucher_from_id(id:string){
      const urlDataCompanies = BASE_URL+"/get_cler_voucher_from_id/"+id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }


    set_cler_voucher_status(id:string,status:string){
      const urlDataCompanies = BASE_URL+"/set_cler_voucher_status/"+id+"/"+status;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    put_cler_voucher_status(body: any){
      const urlDataCompanies = `${BASE_URL}/voucher/voucher_status`
      return this._http.put(urlDataCompanies,body).pipe(map(res=>res));
    }



    get_voucher_type(){
      const urlDataCompanies = BASE_URL+"/get_cler_voucher_type";
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_voucher_type_by_associated_id(associated_id:string){
      const urlDataCompanies = BASE_URL+"/get_cler_voucher_type/"+associated_id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_cler_products(){
      const urlDataCompanies = BASE_URL+"/get_cler_voucher_products";
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_cler_products_by_associated(associated_id:string){
      const urlDataCompanies = BASE_URL+"/get_cler_voucher_products/"+associated_id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }

    get_cler_voucher_providers(){
      const urlDataCompanies = BASE_URL+"/associated/voucher_providers/";
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    /*get_country(){
      const urlDataCompanies = BASE_URL+"/associated/country/";
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_civil_status(){
      const urlDataCompanies = BASE_URL+"/associated/civil_status/";
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }*/
    put_products(uid:string,associated_id:string,product_id:string,data:any){
      const urlDataCompanies = BASE_URL+"/put_products?uid="+uid+"&associated_id="+associated_id+"&product_id="+product_id+"&data="+data;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    update_products(uid:string,product_name:string,description:string,sku:string,net_price:number,tax:number,price:number,associated_product_id:string){
      const urlDataCompanies = BASE_URL+"/update_products?uid="+uid+
      "&product_name="+product_name+"&description="+description+"&sku="+
      sku+"&net_price="+net_price+"&tax="+tax+"&price="+price+
      "&associated_product_id="+associated_product_id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    delete_product(associated_product_id:string){
      const urlDataCompanies = BASE_URL+"/delete_product/"+associated_product_id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_cler_associated_product(associated_id:string,profile_id:string):Observable<any[]>{
      const urlDataCompanies = BASE_URL+"/get_cler_associated_product/"+associated_id+"/"+profile_id;
      return this._http.get<any[]>(urlDataCompanies).pipe(map(res=>res));
    }
    get_cler_associated_product_price(product_id:string){
      const urlDataCompanies = BASE_URL+"/get_cler_associated_product/"+product_id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_dump_product_consiliation(){
      const urlDataCompanies = BASE_URL+"/get_dump_product_consiliation";
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }

    get_cler_voucher_assignation_req(id:string,profile_id:string){
      //const urlDataCompanies = BASE_URL+"/get_cler_voucher_assignation_req/"+id+"/"+profile_id;
      const urlDataCompanies = BASE_URL+`/voucher/get_cler_voucher_assignation_req?id=${id}&profile_id=${profile_id}`;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_cler_ext_voucher_assignation_req(id:string,profile_id:string){
      const urlDataCompanies = BASE_URL+`/voucher/get_cler_external_voucher_assignation_req?id=${id}&profile_id=${profile_id}`;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_voucher_providers(){
      const urlDataCompanies = BASE_URL+`/voucher/get_voucher_providers`;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }

    /*get_cler_voucher_assignation_req_by_entity_id(entity_id:string){
      const urlDataCompanies = BASE_URL+"/get_cler_voucher_assignation_req_by_entity_id/"+entity_id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }*/
    get_cler_voucher_assignation_req_by_entity_id(entity_id:string){
      const urlDataCompanies = BASE_URL+`/voucher/get_cler_voucher_assignation_req_by_entity_id?entity_id=${entity_id}`;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    put_cler_voucher_assignation_req2(state: string,action:string ,name:string ,create_uid : number,
      write_uid : number,amount :string ,company_id: number,voucher_type:string,beneficiary_id:number,entity_id:string,adquisition_unit_id:string,cler_new_current_voucher_amount : number, cler_sale_order_id: string){
      const urlCompanie = BASE_URL+"/put_cler_voucher_assignation_req?state="+state+"&action="+action+"&create_uid="+create_uid+"&write_uid="+write_uid+"&amount="+amount+"&company_id="+company_id+"&voucher_type="+voucher_type+"&beneficiary_id="+beneficiary_id+"&entity_id="+entity_id+"&adquisition_unit_id="+adquisition_unit_id+"&name="+name+"&cler_new_current_voucher_amount="+cler_new_current_voucher_amount+"&cler_sale_order_id="+cler_sale_order_id;
      //logo_web
      return this._http.get(urlCompanie).pipe(map(res=>res));
    }
    /*put_cler_voucher_assignation_req(state: string,action:string ,name:string ,create_uid : number,
      write_uid : number,amount :string ,company_id: number,voucher_type:string,beneficiary_id:number,
      entity_id:string,adquisition_unit_id:string,cler_new_current_voucher_amount : number, cler_sale_order_id: string,
      voucher_provider_id:number){
      var post_data = {
        state: state,
        action:action,
        name:name ,
        create_uid:create_uid,
        write_uid:write_uid,
        amount :amount ,
        company_id: company_id,
        voucher_type:voucher_type,
        beneficiary_id:beneficiary_id,
        entity_id:entity_id,
        adquisition_unit_id:adquisition_unit_id,
        cler_new_current_voucher_amount : cler_new_current_voucher_amount,
        cler_sale_order_id: cler_sale_order_id,
        voucher_provider_id : voucher_provider_id
      }
      const urlCompanie = BASE_URL+"/voucher/put_cler_voucher_assignation_req";
      return this._http.post(urlCompanie,post_data).pipe(map(res=>res));
    }*/
    put_cler_voucher_assignation_req(post_data: any){
      const urlCompanie = BASE_URL+"/voucher/put_cler_voucher_assignation_req";
      return this._http.post(urlCompanie,post_data).pipe(map(res=>res));
    }

    get_cler_voucher_assignation_req_id(){
      const urlCompanie = BASE_URL+"/get_cler_voucher_assignation_req_id";
      //logo_web
      return this._http.get(urlCompanie).pipe(map(res=>res));
    }

    get_refund_cler_voucher_assignation_req(voucher_code: string,write_uid:string){
      const urlCompanie = BASE_URL+"/refund_cler_voucher_assignation_req?voucher_code="+voucher_code+"&write_uid="+write_uid;
      //logo_web
      return this._http.get(urlCompanie).pipe(map(res=>res));
    }
    //OLD
    set_refund_cler_voucher_req(voucher_id :string,voucher_current_amount:string,voucher_refunding_amount:string,entity_id:string,adquisition_unit_id:string,
      beneficiary_id:string,sale_order_number:string,uid:string){
      const urlCompanie = BASE_URL+"/refund_cler_voucher_req?voucher_id="+voucher_id+"&voucher_current_amount="+voucher_current_amount+
      "&voucher_refunding_amount="+voucher_refunding_amount+"&entity_id="+entity_id+"&adquisition_unit_id="+adquisition_unit_id+"&beneficiary_id="+
      beneficiary_id+"&sale_order_number="+sale_order_number+"&uid="+uid;
      return this._http.get(urlCompanie).pipe(map(res=>res));
    }
    cler_voucher_refund_req(voucher_id :string,voucher_current_amount:number,voucher_refunding_amount:number,entity_id:string,adquisition_unit_id:string,
      beneficiary_id:string,sale_order_number:string,uid:string){
        var post_data = {
          voucher_id : voucher_id,
          voucher_current_amount:voucher_current_amount,
          voucher_refunding_amount:voucher_refunding_amount,
          entity_id:entity_id,
          adquisition_unit_id:adquisition_unit_id,
          beneficiary_id:beneficiary_id,
          sale_order_number:sale_order_number,
          uid:uid
        }
        const url = BASE_URL+"/voucher/cler_voucher_refund_req";
        return this._http.post<any>(url, post_data).pipe(map(res=>res));
    }
    put_voucher_replacement(row:any, uid: string|number){
      var post_data = {
        id : row.id,
        cler_sale_order_id : row.cler_sale_order_id,
        current_amount : row.current_amount,
        voucher_amount : row.amount,
        entity_id : row.entity_id,
        adquisition_unit_id : row.adquisition_unit_id,
        beneficiary_id : row.beneficiary_id,
        uid:uid
      }
      const urlCompanie = BASE_URL+"/voucher/replacement";
      return this._http.put(urlCompanie,post_data).pipe(map(res=>res));

    }
    put_voucher_delete(row:any, uid: string|number){
      var post_data = {
        id : row.id,
        cler_sale_order_id : row.cler_sale_order_id,
        current_amount : row.current_amount,
        assignation_req_id: row.assignation_req_id,
        uid:uid
      }
      const urlCompanie = BASE_URL+"/voucher/put_voucher_delete";
      return this._http.put(urlCompanie,post_data).pipe(map(res=>res));

    }
    get_voucher_sale_by_id(voucher_id: string|number){
      const urlCompanie = BASE_URL+`/voucher/get_voucher_sale_by_id?voucher_id=${voucher_id}`;
      return this._http.get(urlCompanie).pipe(map(res=>res));
    }
    put_credit_cler_voucher_req(voucher_id :string,voucher_current_amount:string,voucher_credit_amount:string,entity_id:string,adquisition_unit_id:string,
      beneficiary_id:string,sale_order_number:string,uid:string){
      const urlCompanie = BASE_URL+"/credit_cler_voucher_req?voucher_id="+voucher_id+"&voucher_current_amount="+voucher_current_amount+
      "&voucher_credit_amount="+voucher_credit_amount+"&entity_id="+entity_id+"&adquisition_unit_id="+adquisition_unit_id+"&beneficiary_id="+
      beneficiary_id+"&sale_order_number="+sale_order_number+"&uid="+uid;
      return this._http.get(urlCompanie).pipe(map(res=>res));
    }
    put_cler_voucher_max(voucher_id: string|number, voucher_max_amount: string|number, uid: string|number){
      var post_data = {
        voucher_id : voucher_id,
        voucher_max_amount : voucher_max_amount,
        uid:uid
      }
      const urlCompanie = BASE_URL+"/voucher/put_cler_voucher_max";
      return this._http.put(urlCompanie,post_data).pipe(map(res=>res));
    }

    cler_voucher_credit_req(voucher_id :string|number,voucher_current_amount:number,voucher_credit_amount:number,entity_id:string|number,adquisition_unit_id:string|number,
      beneficiary_id:string|number,sale_order_number:string,uid:string|number){
        var post_data = {
          voucher_id : voucher_id,
          voucher_current_amount : voucher_current_amount,
          voucher_credit_amount : voucher_credit_amount,
          entity_id : entity_id,
          adquisition_unit_id : adquisition_unit_id,
          beneficiary_id : beneficiary_id,
          sale_order_number : sale_order_number,
          uid :  uid
        }
        const url = BASE_URL+"/voucher/cler_voucher_credit_req";
        return this._http.post<any>(url, post_data).pipe(map(res=>res));
    }

    get_cler_home_voucher_transaction(id:string,profile_id:string){
      const urlDataCompanies = BASE_URL+"/get_home_cler_voucher_transacion/"+id+"/"+profile_id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_cler_home_purchase(id:string,profile_id:string){
      const urlDataCompanies = BASE_URL+"/get_home_cler_voucher_purchases/"+id+"/"+profile_id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }

    /*get_beneficiary_stats(id:string,profile_id:string){
      const urlDataCompanies = BASE_URL+"/get_beneficiary_stats/"+id+"/"+profile_id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }


    get_sale_order_stats(id:string,profile_id:string){
      const urlDataCompanies = BASE_URL+"/get_sale_order_stats/"+id+"/"+profile_id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }*/
    get_beneficiary_stats(id:string,profile_id:string){
      const urlDataCompanies = BASE_URL+`/statistics/get_beneficiary_stats?id=${id}&profile_id=${profile_id}`
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }


    get_sale_order_stats(id:string,profile_id:string){
      const urlDataCompanies = BASE_URL+`/statistics/get_sale_order_stats?id=${id}&profile_id=${profile_id}`
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }

    get_product_list_from_transaction_id(id:string){
      const urlDataCompanies = BASE_URL+"/get_product_list_from_transaction_id/"+id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }

    get_voucher_data_by_id(id:string){
      const urlDataCompanies = BASE_URL+"/get_voucher_data_by_id/"+id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_associated_list_by_id(id:string){
      const urlDataCompanies = BASE_URL+"/get_associated_list_by_id/"+id;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }

    getUserMGS(){
      const urlDataUser = BASE_URL+"/res_usersNGS";
      return this._http.get(urlDataUser).pipe(map(res=>res));
    }

    getUsersbyLogin(login: string){
      const urlDataUserlogin = BASE_URL+"/users/"+login;
      return this._http.get(urlDataUserlogin).pipe(map(res=>res));
    }

    getUserLogin(name: string){
      const urlLogin = BASE_URL+"/login/"+name;
      return this._http.get(urlLogin).pipe(map(res=>res));
    }
    delUser(login: string){
      const urldelUser = BASE_URL+"/delUser/"+name;
      return this._http.get(urldelUser).pipe(map(res=>res));
    }
    getGroups(){
      const urlGroups = BASE_URL+"/groups";
      return this._http.get(urlGroups).pipe(map(res=>res));
    }

    getDataGrafico(){
      const urlDataGroups = BASE_URL+"/dataGroups";
      return this._http.get(urlDataGroups).pipe(map(res=>res));
    }

    getDataGraficoComp(id: string){
      const urlDataGroups = BASE_URL+"/dataUbicacionComp/"+id;
      return this._http.get(urlDataGroups).pipe(map(res=>res));
    }

    getDataCompanies(){
      const urlDataCompanies = BASE_URL+"/dataCompanies";
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }


    getCompanyNGS(){
      const urlDataCompanies = BASE_URL+"/get_companiesNGS/";
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }

    getGroupsNGS(){
      const urlDataCompanies = BASE_URL+"/groupsNGS";
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }

    getGroupsNGSxName(name: string){
      const urlDataCompanies = BASE_URL+"/groupsNGS/"+name;
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }

    getDataUbicacion(id: string){
      const urlDataUbicacion = BASE_URL+"/dataUbicacion/"+id;
      return this._http.get(urlDataUbicacion).pipe(map(res=>res));
    }
    getDataUbicacion2(id: string){
      const urlDataUbicacion2 = BASE_URL+"/dataUbicacion2/"+id;
      return this._http.get(urlDataUbicacion2).pipe(map(res=>res));
    }
    getDataUbicacionxNombre(name: string){
      const urlDataUbicacion = BASE_URL+"/dataUbicacionxNombre/"+name;
      return this._http.get(urlDataUbicacion).pipe(map(res=>res));
    }

    getDataUbicacionxNombreAll(){
      const urlDataUbicacion = BASE_URL+"/dataUbicacionxNombreAll/";
      return this._http.get(urlDataUbicacion).pipe(map(res=>res));
    }

    getDataGraficaxMinero(name: string, tipo: string, num: number){
      const urlDataUbicacion = BASE_URL+"/dataEmployeeGraph/"+name+"/"+tipo+"/"+num;
      return this._http.get(urlDataUbicacion).pipe(map(res=>res));
    }
    //
    // setEntityOrAdquisitionU(name:string,rut:string,is_entity:string,parent_id:string,is_adquisition_unit:string,
    //   city:string,email:string,website:string,phone:string,street:string,google_lat:string,
    //   google_long:string,region:string,uid:string,zip:string){
    setEntityOrAdquisitionU(item: any){
      // const urlCompanie = BASE_URL+"/setEntityOrAdquisitionU?name="+name+"&email="+email+"&phone="+phone+"&rut="+rut+"&is_entity="+is_entity+
      // "&parent_id="+parent_id+"&is_adquisition_unit="+is_adquisition_unit+"&city="+city+"&website="+website+"&street="+street+"&google_lat="+google_lat+
      // "&google_long="+google_long+"&region="+region+"&uid="+uid+"&zip="+zip;
      let url = BASE_URL+"/setEntityOrAdquisitionU?";
      let params = new HttpParams();
      Object.keys(item).forEach(key=>{
        if(item[key]!=undefined)params = params.set(key, item[key]);
      });
      const paramsArray = params.keys().map(x => ({ [x]: params.get(x) }));
      console.dir(item);
      console.log(JSON.stringify(paramsArray));
      return this._http.get(url, {params}).pipe(map(res=>res));
    }
    // putEntityOrAdquisitionU(id:string, name:string,rut:string,is_entity:string,parent_id:string,is_adquisition_unit:string,
    //   city:string,email:string,website:string,phone:string,street:string,google_lat:string,
    //   google_long:string,region:string,uid:StrictNavigationMode,zip:string){
    putEntityOrAdquisitionU(item: any){
      // const urlCompanie = BASE_URL+"/putEntityOrAdquisitionU?id="+id+"&name="+name+"&email="+email+"&phone="+phone+"&rut="+rut+"&is_entity="+is_entity+
      // "&parent_id="+parent_id+"&is_adquisition_unit="+is_adquisition_unit+"&city="+city+"&website="+website+"&street="+street+"&google_lat="+google_lat+
      // "&google_long="+google_long+"&region="+region+"&uid="+uid+"&zip="+zip;
      const url = BASE_URL+"/putEntityOrAdquisitionU"
      let params = new HttpParams();
      Object.keys(item).forEach(key=>{
        if(item[key]!=undefined)params = params.set(key, item[key]);
      });
      return this._http.get(url, {params}).pipe(map(res=>res));
    }

    setCompany(name: string, email: string, telefono: string, logo_web: any){
      //const urlCompanie = BASE_URL+"/setCompanies?name="+name+"&email="+email+"&phone="+telefono;
      const urlCompanie = BASE_URL+"/setCompanies?name="+name+"&email="+email+"&logo_web="+logo_web+"&phone="+telefono;
      //logo_web
      return this._http.get(urlCompanie).pipe(map(res=>res));
    }

    delCompanie(id: string){
      const urlCompanie = BASE_URL+"/deleteCompanies/"+id;
      return this._http.get(urlCompanie).pipe(map(res=>res));
    }

    getCompanie(id: string){
      const urlCompanie = BASE_URL+"/getCompanies/"+id;
      return this._http.get(urlCompanie).pipe(map(res=>res));
    }

    getDataEmployee(id: string){
      const urlEmployee = BASE_URL+"/dataEmployeexId/"+id;
      return this._http.get(urlEmployee).pipe(map(res=>res));
    }

    getNameEmployee(id: string){
      const urlEmployee = BASE_URL+"/nameMineroxid/"+id;
      return this._http.get(urlEmployee).pipe(map(res=>res));
    }

    putCompanie(name: string, email: string, telefono: string){
      const urlCompanie = BASE_URL+"/putCompanies?name="+name+"&email="+email+"&phone="+telefono;
      return this._http.get(urlCompanie).pipe(map(res=>res));
    }

    getSuitModels(){
      const urlModels = BASE_URL+"/getSuitModels";
      return this._http.get(urlModels).pipe(map(res=>res));
    }
    getSuitModel(id: string){
      const urlModelsid = BASE_URL+"/getCompanies/"+id;
      return this._http.get(urlModelsid).pipe(map(res=>res));
    }
    getGroupsRes(){
      const urlGroups = BASE_URL+"/getGroups";
      return this._http.get(urlGroups).pipe(map(res=>res));
    }
    getEarlyPayments(profile_id: string|number, partner_id: string|number){
      const urlGroups = BASE_URL+`/earlypayments/earlypayments?profile_id=${profile_id}&partner_id=${partner_id}`;
      return this._http.get(urlGroups).pipe(map(res=>res));
    }
    getContracts(profile_id: string|number, partner_id: string|number){
      const urlGroups = BASE_URL+`/contract/contracts?profile_id=${profile_id}&partner_id=${partner_id}`;
      return this._http.get(urlGroups).pipe(map(res=>res));
    }

    getMSRCards(){
      const urlGroups = BASE_URL+"/voucher/msrcards";
      return this._http.get(urlGroups).pipe(map(res=>res));
    }

    getAdminGroupsNGS(){
      const urlGroups = BASE_URL+"/getGroupsNGS";
      return this._http.get(urlGroups).pipe(map(res=>res));
    }
    getGroup(name: string){
      const urlGroupsid = BASE_URL+"/getGroups/"+name;
      return this._http.get(urlGroupsid).pipe(map(res=>res));
    }
    putGroup(id: Number,new_name : string){
      const urlGroupName = BASE_URL+"/putGroup/id="+id+"&new_name="+new_name;
      return this._http.get(urlGroupName).pipe(map(res=>res));
    }


    getClerSuitSensors(){
      const sensors = BASE_URL+"/cler_suit_sensors";
      return this._http.get(sensors).pipe(map(res=>res));
    }
    get_cler_suit_act(){
      const sensors = BASE_URL+"/cler_suit_act";
      return this._http.get(sensors).pipe(map(res=>res));
    }
    get_cler_suit_comm(){
      const sensors = BASE_URL+"/cler_suit_comm";
      return this._http.get(sensors).pipe(map(res=>res));
    }

    getClotheT(){
      const clohet = BASE_URL+"/getClotheT";
      return this._http.get(clohet).pipe(map(res=>res));
    }

    //Serials & Models
    getClerSerials(){
      const serials = BASE_URL+"/clerSerials";
      return this._http.get(serials).pipe(map(res=>res));
    }
    getClerSerialsID(serial: string){
      const serial_id = BASE_URL+"/clerSerialsID/"+serial;
      return this._http.get(serial_id).pipe(map(res=>res));
    }
    getClerSuitModels(){
      const models = BASE_URL+"/clerSuitModels";
      return this._http.get(models).pipe(map(res=>res));
    }
    putSerial(serial: string,id : string){
      const urlSerialName = BASE_URL+"/putSerial/"+serial+"/"+id;
      return this._http.get(urlSerialName).pipe(map(res=>res));
    }
    updateSerial(new_serial_name: string, new_model_id : string, serial_id: string){
      const urlSerialName = BASE_URL+"/updateSerial/"+serial_id+"/"+new_serial_name+"/"+new_model_id;
      return this._http.get(urlSerialName).pipe(map(res=>res));
    }
    deleteSerial(id: string){
      const urlSerialName = BASE_URL+"/deleteSerial/"+id;
      return this._http.get(urlSerialName).pipe(map(res=>res));
    }
    //DEPARTMENTS
    getDepartments(){
      const deparments = BASE_URL+"/getDepartments";
      return this._http.get(deparments).pipe(map(res=>res));
    }
    getDepartmentsNGS(){
      const deparments = BASE_URL+"/getDepartmentsNGS";
      return this._http.get(deparments).pipe(map(res=>res));
    }
    //WORKS
    getWorks(){
      const works = BASE_URL+"/getWorks";
      return this._http.get(works).pipe(map(res=>res));
    }
    //getHours
    getHours(){
      const works = BASE_URL+"/getHours";
      return this._http.get(works).pipe(map(res=>res));
    }

    //SQUADS MEMBERS
    getSquads(){
      const squads = BASE_URL+"/getSquads";
      return this._http.get(squads).pipe(map(res=>res));
    }

    getSquadsMembers(squad_id: string){
      const squad_members = BASE_URL+"/getSquadsMembers/"+squad_id;
      return this._http.get(squad_members).pipe(map(res=>res));
    }

    //PERSONAL
    getPersonal(){
      const squad_members = BASE_URL+"/getPersonal/";
      return this._http.get(squad_members).pipe(map(res=>res));
    }
    getPersonalNGS(){
      const squad_members = BASE_URL+"/getPersonalNGS/";
      return this._http.get(squad_members).pipe(map(res=>res));
    }

    //ASSIGNATIONS
    getAssignations(){
      const squad_members = BASE_URL+"/getAssignations/";
      return this._http.get(squad_members).pipe(map(res=>res));
    }

    //HEALTH
    getHealthConfig(){
      const  squad_members = BASE_URL+"/get_health_config/";
      return this._http.get(squad_members).pipe(map(res=>res));
    }
    getClerSensor(){
      const  squad_members = BASE_URL+"/get_cler_heatlh_sensor/";
      return this._http.get(squad_members).pipe(map(res=>res));
    }

    /*
    * bathload
    */
    putBatchLoadBeneficiarues(post_data: any){
      const url = BASE_URL+"/batchload/beneficiary";
      return this._http.post<any>(url, post_data).pipe(map(res=>res));

    }
    get_bathload_beneficiaries(){
      const urlDataCompanies = BASE_URL+"/batchload/batchLoadBeneficiaries"
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }

    //REPORTS
    get_giftcardreport(id:string,profile_id:string){
      const urlDataCompanies = BASE_URL+`/report/giftcardreport?id=${id}&profile_id=${profile_id}`
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }
    get_nourishmentreport(id:string,profile_id:string){
      const urlDataCompanies = BASE_URL+`/report/nourishmentreport?id=${id}&profile_id=${profile_id}`
      return this._http.get(urlDataCompanies).pipe(map(res=>res));
    }



    //
}
