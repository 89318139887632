import * as i0 from '@angular/core';
import { inject, Directive, Input, EventEmitter, ElementRef, NgZone, afterNextRender, booleanAttribute, Output } from '@angular/core';
import { DOCUMENT } from '@angular/common';

/**
 * Optimization for Treeshaking: https://angular.io/guide/lightweight-injection-tokens
 */
class NgClickOutsideExcludeToken {}
class NgClickOutsideExcludeDirective extends NgClickOutsideExcludeToken {
  constructor() {
    super(...arguments);
    /**
     * A comma-separated string of DOM element queries to exclude when clicking outside of the element.
     * For example: `[exclude]="'button,.btn-primary'"`.
     */
    this.clickOutsideExclude = '';
    this.document = inject(DOCUMENT);
  }
  excludeCheck() {
    if (this.clickOutsideExclude) {
      try {
        const nodes = Array.from(this.document.querySelectorAll(this.clickOutsideExclude));
        if (nodes) {
          return nodes;
        }
      } catch (err) {
        console.error('[ng-click-outside] Check your exclude selector syntax.', err);
      }
    }
    return [];
  }
  isExclude(target) {
    const nodesExcluded = this.excludeCheck();
    for (let excludedNode of nodesExcluded) {
      if (excludedNode.contains(target)) {
        return true;
      }
    }
    return false;
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵNgClickOutsideExcludeDirective_BaseFactory;
      return function NgClickOutsideExcludeDirective_Factory(t) {
        return (ɵNgClickOutsideExcludeDirective_BaseFactory || (ɵNgClickOutsideExcludeDirective_BaseFactory = i0.ɵɵgetInheritedFactory(NgClickOutsideExcludeDirective)))(t || NgClickOutsideExcludeDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgClickOutsideExcludeDirective,
      selectors: [["", "clickOutsideExclude", ""]],
      inputs: {
        clickOutsideExclude: "clickOutsideExclude"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: NgClickOutsideExcludeToken,
        useExisting: NgClickOutsideExcludeDirective
      }]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgClickOutsideExcludeDirective, [{
    type: Directive,
    args: [{
      selector: '[clickOutsideExclude]',
      standalone: true,
      providers: [{
        provide: NgClickOutsideExcludeToken,
        useExisting: NgClickOutsideExcludeDirective
      }]
    }]
  }], null, {
    clickOutsideExclude: [{
      type: Input
    }]
  });
})();
function arrayAttribute(events) {
  if (Array.isArray(events)) {
    return events;
  } else {
    return events.split(',');
  }
}

/**
 * Directove to detect clicks outside of the current element
 *
 * ```typescript
 * @Component({
 *   selector: 'app',
 *   template: `
 *     <div (clickOutside)="onClickedOutside($event)">Click outside this</div>
 *   `
 * })
 * export class AppComponent {
 *   onClickedOutside(e: Event) {
 *     console.log('Clicked outside:', e);
 *   }
 * }
 * ```
 */
class NgClickOutsideDirective {
  constructor() {
    /**
     * Enables directive.
     */
    this.clickOutsideEnabled = true;
    /**
     * A comma-separated list of events to cause the trigger.
     * ### For example, for additional mobile support:
     * `[clickOutsideEvents]="'click,touchstart'"`
     */
    this.clickOutsideEvents = ['click'];
    /**
     * Outside Click Event
     */
    this.clickOutside = new EventEmitter();
    this.excludeDirective = inject(NgClickOutsideExcludeToken, {
      host: true,
      optional: true
    });
    this._el = inject(ElementRef);
    this._ngZone = inject(NgZone);
    this.document = inject(DOCUMENT);
    this._initOnClickBody = this._initOnClickBody.bind(this);
    this._onClickBody = this._onClickBody.bind(this);
    afterNextRender(() => this._init());
  }
  ngOnDestroy() {
    this._removeClickOutsideListener();
  }
  _init() {
    this._initOnClickBody();
  }
  _initOnClickBody() {
    this._initClickOutsideListener();
  }
  _emit(ev) {
    this._ngZone.run(() => this.clickOutside.emit(ev));
  }
  _initClickOutsideListener() {
    this._ngZone.runOutsideAngular(() => {
      this.clickOutsideEvents.forEach(e => this.document.addEventListener(e, this._onClickBody));
    });
  }
  _removeClickOutsideListener() {
    this._ngZone.runOutsideAngular(() => {
      this.clickOutsideEvents.forEach(e => this.document.removeEventListener(e, this._onClickBody));
    });
  }
  _onClickBody(ev) {
    if (!this.clickOutsideEnabled) {
      return;
    }
    if (!this._el.nativeElement.contains(ev.target) && !this.excludeDirective?.isExclude(ev.target)) {
      this._emit(ev);
    }
  }
  static {
    this.ɵfac = function NgClickOutsideDirective_Factory(t) {
      return new (t || NgClickOutsideDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgClickOutsideDirective,
      selectors: [["", "clickOutside", "", 3, "delayClickOutsideInit", "", 3, "attachOutsideOnClick", ""]],
      inputs: {
        clickOutsideEnabled: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "clickOutsideEnabled", "clickOutsideEnabled", booleanAttribute],
        clickOutsideEvents: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "clickOutsideEvents", "clickOutsideEvents", arrayAttribute]
      },
      outputs: {
        clickOutside: "clickOutside"
      },
      standalone: true,
      features: [i0.ɵɵInputTransformsFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgClickOutsideDirective, [{
    type: Directive,
    args: [{
      selector: '[clickOutside]:not([delayClickOutsideInit]):not([attachOutsideOnClick])',
      standalone: true
    }]
  }], () => [], {
    clickOutsideEnabled: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    clickOutsideEvents: [{
      type: Input,
      args: [{
        transform: arrayAttribute
      }]
    }],
    clickOutside: [{
      type: Output
    }]
  });
})();
class NgClickOutsideAttachOutsideDirective extends NgClickOutsideDirective {
  constructor() {
    super(...arguments);
    /**
     * By default, the outside click event handler is automatically attached.
     *
     * Explicitely setting this to `true`sets the handler after the element is clicked. The outside click event handler
     * will then be removed after a click outside has occurred.
     */
    this.attachOutsideOnClick = true;
  }
  ngOnChanges(changes) {
    if (changes['attachOutsideOnClick']) {
      this._init();
    }
  }
  _init() {
    if (this.attachOutsideOnClick) {
      this._initAttachOutsideOnClickListener();
    } else {
      this._initOnClickBody();
    }
  }
  _emit(ev) {
    if (this.attachOutsideOnClick) {
      this._removeClickOutsideListener();
    }
    super._emit(ev);
  }
  _initAttachOutsideOnClickListener() {
    this._ngZone.runOutsideAngular(() => {
      this.clickOutsideEvents.forEach(e => this._el.nativeElement.addEventListener(e, this._initOnClickBody));
    });
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    this._removeAttachOutsideOnClickListener();
  }
  _removeAttachOutsideOnClickListener() {
    this._ngZone.runOutsideAngular(() => {
      this.clickOutsideEvents.forEach(e => this._el.nativeElement.removeEventListener(e, this._initOnClickBody));
    });
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵNgClickOutsideAttachOutsideDirective_BaseFactory;
      return function NgClickOutsideAttachOutsideDirective_Factory(t) {
        return (ɵNgClickOutsideAttachOutsideDirective_BaseFactory || (ɵNgClickOutsideAttachOutsideDirective_BaseFactory = i0.ɵɵgetInheritedFactory(NgClickOutsideAttachOutsideDirective)))(t || NgClickOutsideAttachOutsideDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgClickOutsideAttachOutsideDirective,
      selectors: [["", "clickOutside", "", "attachOutsideOnClick", ""]],
      inputs: {
        attachOutsideOnClick: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "attachOutsideOnClick", "attachOutsideOnClick", booleanAttribute]
      },
      standalone: true,
      features: [i0.ɵɵInputTransformsFeature, i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgClickOutsideAttachOutsideDirective, [{
    type: Directive,
    args: [{
      selector: '[clickOutside][attachOutsideOnClick]',
      standalone: true
    }]
  }], null, {
    attachOutsideOnClick: [{
      type: Input,
      args: [{
        transform: booleanAttribute,
        required: true
      }]
    }]
  });
})();
class NgClickOutsideDelayOutsideDirective extends NgClickOutsideDirective {
  constructor() {
    super(...arguments);
    /**
     * Delays the initialization of the click outside handler.
     * This may help for items that are conditionally shown ([see issue #13](https://github.com/arkon/ng-click-outside/issues/13)).
     */
    this.delayClickOutsideInit = true;
  }
  _initOnClickBody() {
    if (this.delayClickOutsideInit) {
      setTimeout(this._initClickOutsideListener.bind(this));
    } else {
      this._initClickOutsideListener();
    }
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵNgClickOutsideDelayOutsideDirective_BaseFactory;
      return function NgClickOutsideDelayOutsideDirective_Factory(t) {
        return (ɵNgClickOutsideDelayOutsideDirective_BaseFactory || (ɵNgClickOutsideDelayOutsideDirective_BaseFactory = i0.ɵɵgetInheritedFactory(NgClickOutsideDelayOutsideDirective)))(t || NgClickOutsideDelayOutsideDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgClickOutsideDelayOutsideDirective,
      selectors: [["", "clickOutside", "", "delayClickOutsideInit", ""]],
      inputs: {
        delayClickOutsideInit: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "delayClickOutsideInit", "delayClickOutsideInit", booleanAttribute]
      },
      standalone: true,
      features: [i0.ɵɵInputTransformsFeature, i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgClickOutsideDelayOutsideDirective, [{
    type: Directive,
    args: [{
      selector: '[clickOutside][delayClickOutsideInit]',
      standalone: true
    }]
  }], null, {
    delayClickOutsideInit: [{
      type: Input,
      args: [{
        transform: booleanAttribute,
        required: true
      }]
    }]
  });
})();

/**
 *  emits an event when user clicks outside of applications' window while it's visible.
 *  Especially useful if page contains iframes.
 */
class NgClickOutsideEmitOnBlurDirective {
  constructor() {
    this._ngZone = inject(NgZone);
    this.document = inject(DOCUMENT);
    this.blurWindow = new EventEmitter();
    this._onWindowBlur = this._onWindowBlur.bind(this);
    afterNextRender(() => this._initWindowBlurListener());
  }
  ngOnDestroy() {
    this._removeWindowBlurListener();
  }
  _initWindowBlurListener() {
    this._ngZone.runOutsideAngular(() => {
      this.document.defaultView?.addEventListener('blur', this._onWindowBlur);
    });
  }
  /**
   * Resolves problem with outside click on iframe
   * @see https://github.com/arkon/ng-click-outside/issues/32
   */
  _onWindowBlur(ev) {
    if (!this.document.hidden) {
      this._ngZone.run(() => this.blurWindow.emit(ev));
    }
  }
  _removeWindowBlurListener() {
    this._ngZone.runOutsideAngular(() => {
      this.document.defaultView?.removeEventListener('blur', this._onWindowBlur);
    });
  }
  static {
    this.ɵfac = function NgClickOutsideEmitOnBlurDirective_Factory(t) {
      return new (t || NgClickOutsideEmitOnBlurDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgClickOutsideEmitOnBlurDirective,
      selectors: [["", "clickOutsideEmitOnBlur", ""]],
      outputs: {
        blurWindow: "blurWindow"
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgClickOutsideEmitOnBlurDirective, [{
    type: Directive,
    args: [{
      selector: '[clickOutsideEmitOnBlur]',
      standalone: true
    }]
  }], () => [], {
    blurWindow: [{
      type: Output
    }]
  });
})();

/*
 * Public API Surface of ng-click-outside2
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgClickOutsideAttachOutsideDirective, NgClickOutsideDelayOutsideDirective, NgClickOutsideDirective, NgClickOutsideEmitOnBlurDirective, NgClickOutsideExcludeDirective, NgClickOutsideExcludeToken };
