import { Component, ContentChild, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-show-hide-password',
  templateUrl: './show-hide-password.component.html',
  styleUrls: [
    './show-hide-password.component.scss'
  ]
})
export class ShowHidePasswordComponent {
  show = false;
  @Output() onToggle = new EventEmitter();

  constructor() {}

  toggleShow() {
    this.show = !this.show;
    this.onToggle.emit(this.show);
  }
}
