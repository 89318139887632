import { ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
    selector: 'app-bytea-image',
    templateUrl: 'bytea-image.component.html',
    styleUrls: [
        'bytea-image.component.scss'
    ]
})

export class ByteaImageComponent implements OnInit, OnChanges {
    image: any;
    @Input() imageBytea: any;
    @Input() imageType:string = "png";
    @Input() classList:string[] = [];

    constructor(
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit() {
        this.ngOnChanges(null);
    }

    ngOnChanges(event: any){
        if(this.imageBytea){
            this.convertImageToDataURL();
        }else{
            this.image = null;
        }
    }

    convertImageToDataURL(){
        const reader = new FileReader();
        const componentRef = this;
        reader.onloadend = (e) => {
            componentRef.image = reader.result
            console.dir({imagen: reader.result, bytea:componentRef.imageBytea});
            this.cdr.detectChanges();
        };
        
        // const blob = new Blob([new Uint8Array(this.imageBytea.data, 0, this.imageBytea.data.length)], {
        //     type: `image/${this.imageType}`
        // });
        // const blob = new Blob(this.imageBytea.Uint8Array, {
        //     type: `image/${this.imageType}`
        // });
        const blob = this.imageBytea
        reader.readAsDataURL(blob);
    }
}