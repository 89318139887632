import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {EChartsOption, EChartsType} from "echarts";
import _ from "lodash";
import * as echarts from "echarts";
import {D3JS_SCHEME, ColorGenerator} from "../../../../core/util/color.util";

@Component({
  selector: 'app-epie',
  templateUrl: './epie.component.html',
  styleUrl: './epie.component.scss'
})
export class EpieComponent  implements OnInit, OnChanges{
  @ViewChild('wrapper', {static: true}) wrapper!: ElementRef;
  @Input() data: any[] = [];
  @Input() legends: string[] = [];
  @Input() colorMap: string[] = D3JS_SCHEME;
  @Input() loading: boolean = false;

  loadingOption = {
    graphic: {
      elements: [
        {
          type: 'group',
          left: 'center',
          top: 'center',
          children: new Array(7).fill(0).map((val, i) => ({
            type: 'rect',
            x: i * 20,
            shape: {
              x: 0,
              y: -40,
              width: 10,
              height: 80
            },
            style: {
              fill: '#5470c6'
            },
            keyframeAnimation: {
              duration: 1000,
              delay: i * 200,
              loop: true,
              keyframes: [
                {
                  percent: 0.5,
                  scaleY: 0.3,
                  easing: 'cubicIn'
                },
                {
                  percent: 1,
                  scaleY: 1,
                  easing: 'cubicOut'
                }
              ]
            }
          }))
        }
      ]
    }
  };

  chartOption: any = {
    /*title: {
      text: 'Weather Statistics',
      subtext: 'Fake Data',
      left: 'center'
    },*/
    tooltip: {
      trigger: 'item',
      formatter: '{b} : {c} ({d}%)'
    },
    legend: {
      right: 200,
      top: 50,
      left: 'right',
      orient: 'vertical',
      data: []
    },
    series: [
      {
        type: 'pie',
        radius: '80%',
        center: ['10%', '50%'],
        selectedMode: 'single',
        data: [
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ],
    grid: {
      containLabel: true,
      left: '3%',
      right: '10%',
      bottom: '50',
    },
  };
  private chart!: EChartsType;
  constructor(){

  }

  ngOnInit(){
    this.buildChart()
  }

  buildChart(){
    console.log("LINE BUILD CHART")
    this.chart = echarts.init(this.wrapper.nativeElement);
    this.chart.setOption(this.chartOption)
  }

  rebuildChart(){
    this.removeChart();
    this.buildChart();
  }

  removeChart(){
    try {
      this.chart.dispose();
    }catch(err){console.log(err)}
  }

  showLoading(){
    console.log("LINE SHOW LOADING")
    this.removeChart();
    this.chart = echarts.init(this.wrapper.nativeElement);
    this.chart.setOption(this.loadingOption);
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['data']){
      const colorGen = ColorGenerator(this.colorMap);
      console.log("BAR DATA CHANGES ", changes['data'].currentValue)
      //this.chartOption.xAxis['data'] = _.get(changes, 'categories.currentValue', this.categories) as any;
      const data: any[] = _.get(changes, '["data"].currentValue', []) as any;
      const legends = Array.from(this.legends);
      const series: any = [{
        type: 'pie',
        radius: '80%',
        center: ['50%', '50%'],
        selectedMode: 'single',
        data: data.map(d=>{
          return {
              value: d,
              name: legends.shift()
          }
        }),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }];
      if(this.chart) {
        this.chart.setOption({
          series: series,
        });
      }
      this.chartOption.series = series;
      console.log("chart options: ", this.chartOption)
    }
    if(changes['legends']){
      this.chartOption.legend['data'] = _.get(changes, 'legends.currentValue', this.legends) as any;
      if(this.chart) {
        this.chart.setOption({
          legend: Object.assign({}, this.chartOption.legend)
        });
      }
    }
    if(changes['loading']){
      if(changes['loading'].currentValue == true) {
        this.showLoading();
      }else{
        this.rebuildChart();
      }
    }
  }

  resize(){
    if(this.chart)
      this.chart.resize();
  }
}
