import { Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-button-group',
    templateUrl: './button-group.component.html',
    styleUrls: ['./button-group.component.scss',

    ]
})
export class ButtonGroupComponent{

    @Input() buttonList: any[] = [];
    @Output() onClick = new EventEmitter<any>(); 

    constructor(
    ) {

    }

    onClickButton(button: any){
        this.onClick.emit(button);
    }

}