import {Component, Directive, ElementRef, HostListener, OnInit} from '@angular/core';
import {animate, AUTO_STYLE, state, style, transition, trigger} from '@angular/animations';
import {Router } from '@angular/router';
import {MenuItems} from '../../shared/menu-items/menu-items';
import { DatosService } from '../../../core/legacy/datos.service';
import swal from 'sweetalert2';
import { AuthService } from '../../../core/auth/services/auth.service';
@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrl: './base.component.scss',
  animations: [
    trigger('notificationBottom', [
      state('an-off, void',
        style({
          overflow: 'hidden',
          height: '0px',
        })
      ),
      state('an-animate',
        style({
          overflow: 'hidden',
          height: AUTO_STYLE,
        })
      ),
      transition('an-off <=> an-animate', [
        animate('400ms ease-in-out')
      ])
    ]),
    trigger('slideInOut', [
      state('in', style({
        width: '300px',
        // transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        width: '0',
        // transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
    trigger('mobileHeaderNavRight', [
      state('nav-off, void',
        style({
          overflow: 'hidden',
          height: '0px',
        })
      ),
      state('nav-on',
        style({
          height: AUTO_STYLE,
        })
      ),
      transition('nav-off <=> nav-on', [
        animate('400ms ease-in-out')
      ])
    ]),
    trigger('fadeInOutTranslate', [
      transition(':enter', [
        style({opacity: 0}),
        animate('400ms ease-in-out', style({opacity: 1}))
      ]),
      transition(':leave', [
        style({transform: 'translate(0)'}),
        animate('400ms ease-in-out', style({opacity: 0}))
      ])
    ])
  ]
})
export class BaseComponent implements OnInit {
  public navType: string;
  public themeLayout: string;
  public verticalPlacement: string;
  public verticalLayout: string;
  public pcodedDeviceType: string;
  public verticalNavType: string;
  public verticalEffect: string;
  public vnavigationView: string;
  public freamType: string;
  public sidebarImg: string;
  public sidebarImgType: string;
  public layoutType: string;

  public headerTheme: string;
  public pcodedHeaderPosition: string;

  public liveNotification: string;
  public liveNotificationClass: string = '';

  public profileNotification: string;
  public profileNotificationClass: string = '';

  public chatSlideInOut: string;
  public innerChatSlideInOut: string;

  public searchWidth: number;
  public searchWidthString: string = '';

  public navRight: string;
  public windowWidth: number;
  public chatTopPosition: string = '';

  public toggleOn: boolean;
  public navBarTheme: string;
  public activeItemTheme: string;
  public pcodedSidebarPosition: string;

  public menuTitleTheme: string;
  public dropDownIcon: string;
  public subItemIcon: string;

  public configOpenRightBar: string = '';
  public displayBoxLayout: string;
  public isVerticalLayoutChecked: boolean;
  public isSidebarChecked: boolean;
  public isHeaderChecked: boolean;
  public headerFixedMargin: string;
  public sidebarFixedHeight: string;
  public itemBorderStyle: string;
  public subItemBorder: boolean;
  public itemBorder: boolean;

  public config: any;

  public userId: string|number|null = null;
  public partner_name: string|null = null;
  public has_parent = 0;
  public dataUri: string|null|any = null;
  public panel = 0;
  //public panel2 = 0;
  public profile_id: string|number|null = null;

  public sales_up = 0;
  public help_table_up = 0;
  public contact_up = 0;
  public chrisfooter = 0 ;
  user_login: string|null = null;
  menuItemList: any[] = [];


  constructor(public menuItems: MenuItems, 
    private _datos: DatosService,
    private authService: AuthService,
    private route: Router) {
    this._datos.getUser().subscribe(
      (res: any)=>{
        this.dataUri = res;
      }
    )
    
    this.navType = 'st1';
    this.themeLayout = 'horizontal'; // vertical
    this.verticalPlacement = 'left';
    this.verticalLayout = 'wide';
    this.pcodedDeviceType = 'desktop';
    this.verticalNavType = 'expanded';
    this.verticalEffect = 'shrink';
    this.vnavigationView = 'view1';
    this.freamType = 'theme1';
    this.sidebarImg = 'false';
    this.sidebarImgType = 'img1';
    this.layoutType = 'light';

    this.headerTheme = 'themelight5';
    this.pcodedHeaderPosition = 'fixed';

    this.liveNotification = 'an-off';
    this.profileNotification = 'an-off';

    this.chatSlideInOut = 'out';
    this.innerChatSlideInOut = 'out';

    this.searchWidth = 0;

    this.navRight = 'nav-on';

    this.windowWidth = window.innerWidth;
    this.setHeaderAttributes(this.windowWidth);

    this.toggleOn = true;
    this.navBarTheme = 'themelight1';
    this.activeItemTheme = 'theme10';
    this.pcodedSidebarPosition = 'fixed';
    this.menuTitleTheme = 'theme6';
    this.dropDownIcon = 'style1';
    this.subItemIcon = 'style1';

    this.displayBoxLayout = 'd-none';
    this.isVerticalLayoutChecked = false;
    this.isSidebarChecked = true;
    this.isHeaderChecked = true;
    this.headerFixedMargin = '56px';
    this.sidebarFixedHeight = 'calc(100vh - 56px)';
    this.itemBorderStyle = 'none';
    this.subItemBorder = true;
    this.itemBorder = true;

    this.setMenuAttributes(this.windowWidth);
    this.setHeaderAttributes(this.windowWidth);
  }

  ngOnInit() {
    //this.setBackgroundPattern('pattern1');
    //this.panel = 0;
    this.chrisfooter = 0;
    this.verticalNavType = 'offcanvas';
    this.verticalLayout = 'box';
    this.displayBoxLayout = '';
    /*document.querySelector('body').classList.remove('dark');*/
    this.headerTheme = 'theme1';
    this.sidebarImg = 'false';
    this.navBarTheme = 'theme1';
    this.menuTitleTheme = 'theme2';
    this.onUserMenu();
    //document.querySelector('body').classList.add('dark');
    //document.querySelector('body').classList.remove('dark');

    //console.log(this.chrisfooter);

  }

  onUserMenu(){
    console.log('Entering onUserMenu');
    this.partner_name = this.authService.getPartnerName();
    if (this.partner_name != 'null')
      this.has_parent = 1;     
    this.user_login = this.authService.getLogin();
    this.userId = this.authService.getUserIdentificator();
    console.log(`user id: ${this.userId}`);
    this.profile_id =  this.authService.getProfileId();
    console.log(`profile id: ${this.profile_id}`);
    this.menuItemList = this.getMenuItems();
  }

  onClearUser(){
    console.log('Entering onClearUser');
    this.userId = null;
    this.profile_id = null;
    this.menuItemList = [];
  }

  getMenuItems(){
    if(this.profile_id == undefined || this.profile_id == 'null') return [];
    // console.log(`get menu items for ${this.profile_id}`)
    console.log("user login: ", this.user_login)
    const devUsers: (string|null)[] = ['frontadm'];
    let profile_id = this.profile_id;
    if(devUsers.includes(this.user_login)) profile_id = 'dev';
    const profileMenuItemsFcnMap: any = {
      'adm': ()=>this.menuItems.getAll(),
      'ent_comp': ()=>this.menuItems.getAdquisitionsEntity(),
      'ent_adm': ()=>this.menuItems.getEntity(),
      'ent_ben': ()=>this.menuItems.getBeneficiary(),
      'asoc_ven': ()=>this.menuItems.getPointofSales(),
      'asoc_con': ()=>this.menuItems.getAssociatedCon(),
      'asoc_admin_ven_mov': ()=>this.menuItems.getAssociatedCon(),
      'adm_dcp': ()=>this.menuItems.getDCPPEntity(),
      'ent_ch_compras': ()=>this.menuItems.getCHEntity(),
      'dev': ()=>this.menuItems.getDev()
    }
    const getMenuItemsFcn: any = profileMenuItemsFcnMap[profile_id];
    return getMenuItemsFcn();
  }

  logout(){
    //console.log('Entering logout');
    this.userId = null;
    this.profile_id = null;
    this.authService.clear();
    //this.route.navigate(['/']);  
  }
  footer_click(footer_selection:string){
    var str1 = "Teléfono: +56 45 2 646310\n"+ 
               "Correo: contacto@clerconfecciones.com"; 
    var str2 = "Alejandra Ochoa - Vendedora\n"+
               "Teléfono: +56954850562\n"+
               "Correo: alejandra.ochoa@clerconfecciones.com\n"+
               "Roxana Valeria – Asistente de Ventas\n"+
               "Teléfono: +56966743167\n"+
               "Correo: roxana.valeria@clerconfecciones.com";
    var str3 = "Dirección: Carrera N°61, Temuco\n"+ 
                "Teléfono: 45 223 5688\n"+
                "Correo: contacto@clerconfecciones.com\n"+
                "Facebook: facebook.com/cler.confecciones\n"+
                "Twitter: @clerconfeccione";
    if ('help_table' ==  footer_selection){
      //console.log('help_table');
      swal.fire({
        title: 'Mesa de Ayuda',
        html: '<pre>' + str1 + '</pre>',
        icon: 'success',
        confirmButtonText: 'Ok',
        allowOutsideClick: true
      });
    }else if ('sales' ==  footer_selection){      
      //console.log('help_table');
      swal.fire({
        title: 'Ventas',
        html: '<pre>' + str2 + '</pre>',
        icon: 'success',
        confirmButtonText: 'Ok',
        allowOutsideClick: true
      });
    }else if ('contact' ==  footer_selection){    
      //console.log('help_table');
      swal.fire({
        title: 'Contacto',
        html: '<pre>' + str3 + '</pre>',
        icon: 'success',
        confirmButtonText: 'Ok',
        allowOutsideClick: true
      });
    }
  }

  onResize(event: any) {
    this.windowWidth = event.target.innerWidth;
    this.setHeaderAttributes(this.windowWidth);

    let reSizeFlag = true;
    if (this.pcodedDeviceType === 'tablet' && this.windowWidth >= 768 && this.windowWidth <= 1024) {
      reSizeFlag = false;
    } else if (this.pcodedDeviceType === 'phone' && this.windowWidth < 768) {
      reSizeFlag = false;
    }
    /* for check device */
    if (reSizeFlag) {
      this.setMenuAttributes(this.windowWidth);
    }
  }

  setHeaderAttributes(windowWidth: number) {
    if (windowWidth < 992) {
      this.navRight = 'nav-off';
    } else {
      this.navRight = 'nav-on';
    }
  }

  setMenuAttributes(windowWidth: number) {
    if (windowWidth >= 768 && windowWidth <= 1024) {
      this.pcodedDeviceType = 'tablet';
      this.verticalNavType = 'offcanvas';
      this.verticalEffect = 'overlay';
    } else if (windowWidth < 768) {
      this.pcodedDeviceType = 'phone';
      this.verticalNavType = 'offcanvas';
      this.verticalEffect = 'overlay';
    } else {
      this.pcodedDeviceType = 'desktop';
      this.verticalNavType = 'expanded';
      this.verticalEffect = 'shrink';
    }
  }

  toggleHeaderNavRight() {
    this.navRight = this.navRight === 'nav-on' ? 'nav-off' : 'nav-on';
    this.chatTopPosition = this.chatTopPosition === 'nav-on' ? '112px' : '';
    if (this.navRight === 'nav-off' && this.innerChatSlideInOut === 'in') {
      this.toggleInnerChat();
    }
    if (this.navRight === 'nav-off' && this.chatSlideInOut === 'in') {
      this.toggleChat();
    }
  }

  toggleLiveNotification() {
    this.liveNotification = this.liveNotification === 'an-off' ? 'an-animate' : 'an-off';
    this.liveNotificationClass = this.liveNotification === 'an-animate' ? 'active' : '';

    if (this.liveNotification === 'an-animate' && this.innerChatSlideInOut === 'in') {
      this.toggleInnerChat();
    }
    if (this.liveNotification === 'an-animate' && this.chatSlideInOut === 'in') {
      this.toggleChat();
    }
  }

  toggleProfileNotification() {
    this.profileNotification = this.profileNotification === 'an-off' ? 'an-animate' : 'an-off';
    this.profileNotificationClass = this.profileNotification === 'an-animate' ? 'active' : '';

    if (this.profileNotification === 'an-animate' && this.innerChatSlideInOut === 'in') {
      this.toggleInnerChat();
    }
    if (this.profileNotification === 'an-animate' && this.chatSlideInOut === 'in') {
      this.toggleChat();
    }
  }

  notificationOutsideClick(ele: string) {
    if (ele === 'live' && this.liveNotification === 'an-animate') {
      this.toggleLiveNotification();
    } else if (ele === 'profile' && this.profileNotification === 'an-animate') {
      this.toggleProfileNotification();
    }
  }

  toggleChat() {
    this.chatSlideInOut = this.chatSlideInOut === 'out' ? 'in' : 'out';
    if (this.innerChatSlideInOut === 'in') {
      this.innerChatSlideInOut = 'out';
    }
  }

  toggleInnerChat() {
    this.innerChatSlideInOut = this.innerChatSlideInOut === 'out' ? 'in' : 'out';
  }

  searchOn() {
    document.querySelector('#main-search')?.classList.add('open');
    const searchInterval = setInterval(() => {
      if (this.searchWidth >= 200) {
        clearInterval(searchInterval);
        return;
      }
      this.searchWidth = this.searchWidth + 15;
      this.searchWidthString = this.searchWidth + 'px';
    }, 35);
  }

  searchOff() {
    const searchInterval = setInterval(() => {
      if (this.searchWidth <= 0) {
        document.querySelector('#main-search')?.classList.remove('open');
        clearInterval(searchInterval);
        return;
      }
      this.searchWidth = this.searchWidth - 15;
      this.searchWidthString = this.searchWidth + 'px';
    }, 35);
  }

  toggleOpened() {
    if (this.windowWidth < 992) {
      this.toggleOn = this.verticalNavType === 'offcanvas' ? true : this.toggleOn;
      if (this.navRight === 'nav-on') {
        this.toggleHeaderNavRight();
      }
    }
    this.verticalNavType = this.verticalNavType === 'expanded' ? 'offcanvas' : 'expanded';
    document.querySelector('#main_navbar')?.classList.toggle('show-menu');
  }

  onClickedOutsideSidebar(e: Event) {
    if ((this.windowWidth < 992 && this.toggleOn && this.verticalNavType !== 'offcanvas') || this.verticalEffect === 'overlay') {
      this.toggleOn = true;
      this.verticalNavType = 'offcanvas';
      document.querySelector('#main_navbar')?.classList.remove('show-menu');
    }
  }

  toggleRightbar() {
    this.configOpenRightBar = this.configOpenRightBar === 'open' ? '' : 'open';
  }

  setNavBarTheme(theme: string) {
    if (theme === 'themelight1') {
      this.navBarTheme = 'themelight1';
      this.menuTitleTheme = 'theme1';
      this.sidebarImg = 'false';
    } else {
      this.menuTitleTheme = 'theme6';
      this.navBarTheme = 'theme1';
      this.sidebarImg = 'false';
    }
  }

  setLayoutType(type: string) {
    this.layoutType = type;
    if (type === 'dark') {
      this.headerTheme = 'theme1';
      this.sidebarImg = 'false';
      this.navBarTheme = 'theme1';
      this.menuTitleTheme = 'theme6';
      document.querySelector('body')?.classList.add('dark');
    } else if (type === 'light') {
      this.sidebarImg = 'false';
      this.headerTheme = 'theme5';
      this.navBarTheme = 'themelight1';
      this.menuTitleTheme = 'theme1';
      document.querySelector('body')?.classList.remove('dark');
    } else if (type === 'img') {
      this.sidebarImg = 'true';
      this.headerTheme = 'theme1';
      this.navBarTheme = 'theme1';
      this.menuTitleTheme = 'theme6';
      document.querySelector('body')?.classList.remove('dark');
    }
  }

  setVerticalLayout() {
    this.isVerticalLayoutChecked = !this.isVerticalLayoutChecked;
    if (this.isVerticalLayoutChecked) {
      this.verticalLayout = 'box';
      this.displayBoxLayout = '';
    } else {
      this.verticalLayout = 'wide';
      this.displayBoxLayout = 'd-none';
    }
  }

  setBackgroundPattern(pattern: string) {
    document.querySelector('body')?.setAttribute('themebg-pattern', pattern);
  }

  setSidebarPosition() {
    this.isSidebarChecked = !this.isSidebarChecked;
    this.pcodedSidebarPosition = this.isSidebarChecked === true ? 'fixed' : 'absolute';
    this.sidebarFixedHeight = this.isHeaderChecked === true ? 'calc(100vh + 56px)' : 'calc(100vh - 56px)';
  }

  setHeaderPosition() {
    this.isHeaderChecked = !this.isHeaderChecked;
    this.pcodedHeaderPosition = this.isHeaderChecked === true ? 'fixed' : 'relative';
    this.headerFixedMargin = this.isHeaderChecked === true ? '56px' : '';
  }

}

@Directive({
  selector: '[appTrigger]'
})
export class LinkTriggerDirective {
  constructor(private elements: ElementRef) {}

  @HostListener('click', ['$event'])
  onToggle($event: any) {
    $event.preventDefault();
    let eleClass = '';
    let parentEle = (this.elements).nativeElement;
    while (eleClass !== 'menu-main-item') {
      if (parentEle.classList.contains('menu-main-item')) {
        eleClass = 'menu-main-item';
      } else {
        parentEle = parentEle.parentNode;
      }
    }
    parentEle.querySelector('.pcoded-hasmenu').classList.remove('pcoded-trigger');
    parentEle.querySelector('.main-click-trigger').click();
    document.querySelector('#main_navbar')?.classList.remove('show-menu');
  }
}
