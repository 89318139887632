<div class="page-body">
    <div class="page-encabezado col-sm-12">
        <div class="row p-10">
            <div class="col-10">
                <h1><i class="titulo-seguridad fa-titulo color-3 fa ti-layers p-r-5"></i> {{title}} </h1>
                <ul class="breadcrumb-title titulo-seguridad color-3">
                    <li class="breadcrumb-item color-2" *ngFor="let link of breadcrumbs">
                        <a [routerLink]="[link.url]">{{link.text}}</a>
                    </li>
                    <li class="breadcrumb-item color-2"><a href="javascript:">{{title}}</a></li>
                </ul>
            </div>
            <!-- <div class="ayuda col-2">
          <a href="javascript:" class="box-ayuda" title="ayuda" placement="left" ngbPopover="En esta sección es posible hacer cambios relacionados con el contrato que actualmente esta operando" popoverTitle="Ayuda">?</a>
        </div> -->
        </div>
    </div>
    <div class="col-sm-12 col-md-12 contenido administracion">
        <!-- <div class="row"> -->
            <ng-content></ng-content>
        <!-- </div> -->
    </div>
</div>
<br><br><br><br><br><br>