<ng-container *ngIf="form && input && input.hidden!=true">
    <label class="col-sm-12 col-form-label" *ngIf="input.label">{{input.label}}</label>
    <div class="input-group" [formGroup]="form" [ngStyle]="{'align-items': input.type=='image-picker'?'center':'unset'}">
        <span class="input-group-prepend" *ngIf="input.iconClass && input.type!='date' && input.type!='password'">
            <label class="input-group-text">
                <i class="{{input.iconClass}}"></i>
            </label>
        </span>
        <ng-container [ngSwitch]="input.type">
            <input *ngSwitchCase="'text'" type="text" class="form-control tooltip-form" name="{{input.name}}"
                placeholder="{{input.placeholder}}" [formControlName]="input.name">
            <ng-container *ngSwitchCase="'password'">
                <app-show-hide-password
                (onToggle)="toggleShow($event)"
                >
                    <span class="input-group-prepend" *ngIf="input.iconClass">
                        <label class="input-group-text">
                            <i class="{{input.iconClass}}"></i>
                        </label>
                    </span>
                    <input type="{{show?'text':'password'}}" class="form-control tooltip-form" name="{{input.name}}"
                        placeholder="{{input.placeholder}}" [formControlName]="input.name">
                </app-show-hide-password>
            </ng-container>
            <!-- <select *ngSwitchCase="'list'" name="{{input.name}}" [formControlName]="input.name"
                class="form-control tooltip-form">
                <option *ngFor="let option of input.optionList" [value]="option.key">{{option.value}}</option>
            </select> -->
            <ng-container *ngSwitchCase="'list'">
                <ng-select *ngIf="input.optionList.length>0" class="ng-select modal-ng-select" formControlName="{{input.name}}"
                [items]="input.optionList" bindLabel="label" bindValue="value" [multiple]="false" (change)="input.onSelected($event)"
                (deselected)="input.onDeselected($event)">
                </ng-select>
            </ng-container>

            <div class="file-drop-area" *ngSwitchCase="'file'" [ngClass]="{'active':active}">
                <ng-container [ngSwitch]="input.style">
                    <ng-container *ngSwitchCase="'default'">
                        <span class="fake-btn">Buscar archivo</span>
                        <span class="file-msg">{{fileLoaded? (input.filename || filename):  'O arrastra un archivo aquí'}}</span>
                        <input type="file" class="file-input"
                            name="{{input.name}}" [formControlName]="input.name" (change)="onFileChange($event)"
                            (dragenter)="setActive(true)" (focus)="setActive(true)" (click)="setActive(true)"
                            (dragleave)="setActive(false)" (blur)="setActive(false)" (drop)="setActive(false)">
                    </ng-container>
                    <ng-container *ngSwitchCase="'pdf'">
                        <label class="btn-upload-img mx-auto d-block">
                            <img *ngIf="!fileLoaded" class="empresa-form-img"src="assets/images/empty.png" height="160" width="160">
                            <img *ngIf="fileLoaded" class="empresa-form-img"src="assets/images/pdf_empty_file_512.png" height="160" width="160">
                            <input  type="file" accept=".csv" style="display:none"
                                name="{{input.name}}" [formControlName]="input.name" (change)="onFileChange($event)"
                                (dragenter)="setActive(true)" (focus)="setActive(true)" (click)="setActive(true)"
                                (dragleave)="setActive(false)" (blur)="setActive(false)" (drop)="setActive(false)"/>
                        </label>
                    </ng-container>
                    <ng-container *ngSwitchCase="'csv'">
                        <label class="btn-upload-img mx-auto d-block">
                            <img *ngIf="!fileLoaded" class="empresa-form-img"src="assets/images/empty.png" height="160" width="160">
                            <img *ngIf="fileLoaded" class="empresa-form-img"src="assets/images/csv.png" height="160" width="160">
                            <input  type="file" accept=".csv" style="display:none"
                                name="{{input.name}}" [formControlName]="input.name" (change)="onFileChange($event)"
                                (dragenter)="setActive(true)" (focus)="setActive(true)" (click)="setActive(true)"
                                (dragleave)="setActive(false)" (blur)="setActive(false)" (drop)="setActive(false)"/>
                        </label>
                    </ng-container>
                </ng-container>
            </div>
            <ng-container *ngSwitchCase="'date'">
                <input class="form-control" placeholder="yyyy-mm-dd" name="dp" formControlName="{{input.name}}" ngbDatepicker #d="ngbDatepicker">
                <div class="input-group-append" (click)="d.toggle()" >
                    <span class="fa fa-calendar input-group-text"></span>
                </div>
            </ng-container>
            <div class="form-check" *ngSwitchCase="'checkbox'">
                <label class="switch">
                    <input type="checkbox" class="form-check-input" name="{{input.name}}"
                        [formControlName]="input.name">
                    <span class="slider round"></span>
                </label>
            </div>
            <ng-container *ngSwitchCase="'radio'">
                <div class="radio-btn-group-wrapper" style="width: 100%">
                    <ng-container *ngFor="let option of input.optionList">
                        <div class="col">
                            <p>{{option.text}}</p>
                            <input type="radio" [value]="option.value" formControlName="{{input.name}}">
                        </div>
                    </ng-container>
                </div>
                <!-- <mat-radio-group aria-label="Selecciona una opción" formControlName="{{input.name}}">
                    <mat-radio-button [value]="option.value" *ngFor="let option of input.optionList">{{option.text}}</mat-radio-button>
                </mat-radio-group> -->
            </ng-container>
            <ng-container *ngSwitchCase="'image-picker'">
                <ng-container *ngFor="let option of input.optionList">
                    <div class="col-sm-6" style="justify-content:center">
                        <div class="image-picker-super-wrapper">
                            <div
                                class="image-picker-wrapper"
                                [ngClass]="{'big-img-wrapper': input.selected == option.value, 'little-img-wrapper': input.selected != option.value}"
                                (click)="selectImage(option.value)">
                                <img src="{{option.path}}" class="image-picker-img">
                            </div>
                        </div>
                        <h5 class="image-picker-text">{{option.text|titlecase}}</h5>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>

        <div *ngIf="input.error" class="messages text-danger tooltip-error" placement="top"
            ngbTooltip="{{input.error.text}}">
            <i class="{{input.error.iconClass}}"></i>
        </div>
    </div>
</ng-container>
