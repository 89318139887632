import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { WizardComponent } from '@y3krulez/angular-archwizard';

export interface Step{
  inputList: any[];
  tabList: any[];
  maxWidth: any;
  maxHeight: any;
  fileInput?: any;
  templateName: string;
  alertList: any[];
  columnsPerRow: number;
  csvColumnList: any[];
  csvData: any[];
  validateFcn?: any;
  validated?: boolean;
  onSort?: any;
  shouldHideActionBtn?: any;
  totalCount: number;
  onClickActionBtn: any;
  getRows: any;
  getActionBtnList: any;
  rowsObs: any;
  columnList: any;
  filledFieldList: any[];
  type: any;
  img: string;
  subtitle: string;
  title: string;
}

@Component({
  selector: 'app-wizzard',
  templateUrl: './wizzard.component.html',
  styleUrls: ['./wizzard.component.scss']
})
export class WizzardComponent implements OnInit {
  @ViewChild(WizardComponent) public wizard!: WizardComponent;
  @Input() steps: Step[] = [];
  validated: boolean = false;
  successText: string = "Exito!"
  form !:any;
  loading = false;
  constructor() { }

  ngOnInit() {
  }

  validateStep(step: Step){
    if(step.validateFcn == undefined){
      step.validated = false;
      return;
    }
    step.validated = step.validateFcn();
  }
}
