import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
declare let Plotly: any;

export interface PieData {
  values: number[],
  labels: string[],
  name: string,
  opacity?: number,
  marker?: {
    colors: string[]
  },
  domain?: {
    row: number,
    column: number
  },
  hoverinfo?: string,
  textinfo?: string
}

function makeid(length: any) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function ColorGenerator() {
  const colorList = [
    '#9F1717',
    '#EFAB58',
    '#D6925F',
    '#70A16F',
    '#3F5B4F',
    '#044B7F',
    '#B9D6F2',
    '#242325'
  ];
  let colorIdx = 0;
  return {
    generate: ()=>{
      if(colorIdx > colorList.length) colorIdx = 0;
      const color = colorList[colorIdx];
      colorIdx += 1;
      return color;
    }
  }
}

@Component({
  selector: 'app-pie',
  templateUrl: './pie.component.html',
  styleUrls: ['./pie.component.scss']
})
export class PieComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {


  @Input() series: PieData[] = [];
  
  data: any[] = [];
  // [
  //   {
  //     "data": [
  //       500
  //     ],
  //     "name": "Vigente",
  //     "marker": {
  //       "color": "#b31919",
  //       "line": {
  //         "color": "#b31919",
  //         "width": 1
  //       }
  //     },
  //     "y": [
  //       500
  //     ],
  //     "x": [
  //       "2023-12-19"
  //     ],
  //     "type": "bar"
  //   },
  //   {
  //     "data": [
  //       0
  //     ],
  //     "name": "Compra realizada",
  //     "marker": {
  //       "color": "#b31919",
  //       "line": {
  //         "color": "#b31919",
  //         "width": 1
  //       }
  //     },
  //     "y": [
  //       0
  //     ],
  //     "x": [
  //       "2023-12-19"
  //     ],
  //     "type": "bar"
  //   }
  // ]
  
  layout: any = {
    // barmode: 'stack', 
    autosize: true, 
    width: '100%', 
    height: '100%',
    // bargap: 0,
    showlegend: true,
    margin: {"t": 30, "b": 30, "l": 30, "r": 30},
    // xaxis: {
    //   range: ['2024-01-01', '2024-01-03']
    // }
  };

  config = {responsive: true, locale: 'es'}

  loaded = false;

  divId = makeid(7)

  changes$ = new Subject();
  subscriptions: Subscription[] = [];
  
  constructor() { }

  ngOnInit() {

    // this.resize();
    this.subscriptions.push(
      this.changes$.pipe(
        throttleTime(500)
      ).subscribe(()=>{
        this.buildData();
        this.buildLayout();
        if(!this.loaded){
          console.log("data: ", this.data)
          this.loaded = true;
          setTimeout(()=>Plotly.newPlot(this.divId, this.data, this.layout, this.config))
          
        }
        else
          setTimeout(()=>Plotly.newPlot(this.divId, this.data, this.layout, this.config))
          // Plotly.update(this.divId, this.data, this.layout, this.config)
      })
    )
  }

  ngAfterViewInit(): void {
    this.resize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.changes$.next(true);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s=>s.unsubscribe())
  }

  buildData(){
    const data = [];
    for(let serie of this.series){
      const serie_ = Object.assign({}, serie, {
        type: 'pie',
        showlegend: true,
        automargin: true
      })
      data.push(serie_);
    }
    console.log("data created: ");console.dir(data);
    // console.log("working data: ");console.dir([this.trace1, this.trace2])
    this.data = data;// [this.trace1, this.trace2];
  }

  buildLayout(){
    // if(this.xRange){
    //   this.layout['xaxis'] = {
    //     range: this.xRange,
    //     tickmode: 'array'
    //   }
    // }
  }

  resize(){
    const div = document.getElementById(this.divId);
    this.layout.width = div?.getBoundingClientRect().width;
    this.layout.height = div?.getBoundingClientRect().height;
    console.log("new layout: ");console.dir(this.layout)
    this.changes$.next(true);
  }
}