<ng-container [ngSwitch]="templateName">
  <ng-container *ngSwitchCase="'default'">
      <app-basic-form [alertList]="alertList" [inputList]="inputList" [columnsPerRow]="columnsPerRow"
          [form]="form" [alertTemplate]="alertTemplate" *ngIf="!loading"></app-basic-form>
  </ng-container>
  <ng-container *ngSwitchCase="'tabset'">
      <app-tabset-form [alertList]="alertList" [tabList]="tabList" [form]="form" [mainInputList]="inputList"
      [alertTemplate]="alertTemplate" *ngIf="!loading" [filledFieldList]="filledFieldList"></app-tabset-form>
  </ng-container>
  <ng-container *ngSwitchCase="'csvFile'">
      <app-csv-file-upload-form
          [alertTemplate]="alertTemplate"
          [alertList]="alertList"
          [inputList]="inputList"
          [fileInput]="fileInput"
          [csvData]="csvData"
          [csvColumnList]="csvColumnList"
          [form]="form"
      ></app-csv-file-upload-form>
  </ng-container>
</ng-container>