export const RETRO_METO_SCHEME = ["#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6"];
export const DUTCH_FIELD_SCHEME = ["#e60049", "#0bb4ff", "#50e991", "#e6d800", "#9b19f5", "#ffa300", "#dc0ab4", "#b3d4ff", "#00bfa0"];
export const RIVER_NIGHTS_SCHEME = ["#b30000", "#7c1158", "#4421af", "#1a53ff", "#0d88e6", "#00b7c7", "#5ad45a", "#8be04e", "#ebdc78"];
export const SPRING_PASTELS_SCHEME = ["#fd7f6f", "#7eb0d5", "#b2e061", "#bd7ebe", "#ffb55a", "#ffee65", "#beb9db", "#fdcce5", "#8bd3c7"];
export const BLUE_TO_YELLOW_SCHEME = ["#115f9a", "#1984c5", "#22a7f0", "#48b5c4", "#76c68f", "#a6d75b", "#c9e52f", "#d0ee11", "#d0f400"];
export const GREY_TO_RED_SCHEME = ["#d7e1ee", "#cbd6e4", "#bfcbdb", "#b3bfd1", "#a4a2a8", "#df8879", "#c86558", "#b04238", "#991f17"];
export const BLACK_TO_PINK_SCHEME = ["#2e2b28", "#3b3734", "#474440", "#54504c", "#6b506b", "#ab3da9", "#de25da", "#eb44e8", "#ff80ff"];
export const BLUES_SCHEME = ["#0000b3", "#0010d9", "#0020ff", "#0040ff", "#0060ff", "#0080ff", "#009fff", "#00bfff", "#00ffff"];
export const BLUE_TO_RED_SCHEME = ["#1984c5", "#22a7f0", "#63bff0", "#a7d5ed", "#e2e2e2", "#e1a692", "#de6e56", "#e14b31", "#c23728"];
export const ORANGE_TO_PURPLE = ["#ffb400", "#d2980d", "#a57c1b", "#786028", "#363445", "#48446e", "#5e569b", "#776bcd", "#9080ff"];
export const PINK_FOAM_SCHEME = ["#54bebe", "#76c8c8", "#98d1d1", "#badbdb", "#dedad2", "#e4bcad", "#df979e", "#d7658b", "#c80064"];
export const SALMON_TO_AQUA_SCHEME = ["#e27c7c", "#a86464", "#6d4b4b", "#503f3f", "#333333", "#3c4e4b", "#466964", "#599e94", "#6cd4c5"];
export const GREEN_TO_RED_SCHEME = ['#00876c', '#439981', '#6aaa96', '#8cbcac', '#aecdc2', '#cfdfd9', '#f1f1f1', '#f1d4d4', '#f0b8b8', '#ec9c9d', '#e67f83', '#de6069', '#d43d51'];
export const D3JS_SCHEME = [
    "#2ca02c",  // green
    "#1f77b4",  // blue
    "#ff7f0e",  // orange
    "#d62728",  // red
    "#9467bd",  // purple
    "#8c564b",  // brown
    "#e377c2",  // pink
    "#7f7f7f",  // gray
    "#bcbd22",  // yellow-green
    "#17becf",  // teal
    "#393b79",  // dark blue
    "#8c6d31",  // dark brown
    "#d6616b",  // light red
    "#e7ba52",  // mustard
    "#7b4173",  // dark pink
    "#a55194",  // violet
    "#c7c7c7",  // light gray
    "#1f77b4",  // light blue
    "#ff9896",  // salmon
    "#98df8a"   // light green
];

export const CLERTICKET_SCHEME = [
    '#9F1717',
    '#EFAB58',
    '#D6925F',
    '#70A16F',
    '#3F5B4F',
    '#044B7F',
    '#B9D6F2',
    '#242325'
];

export function ColorGenerator(colorList:string[]=CLERTICKET_SCHEME) {
    let colorIdx = 0;
    return {
      generate: ()=>{
        if(colorIdx > colorList.length) colorIdx = 0;
        const color = colorList[colorIdx];
        colorIdx += 1;
        return color;
      },
      reset: ()=>{
        colorIdx = 0;
      }
    }
}
