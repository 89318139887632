<div class="col-md-12">
  <!-- <div *ngIf="errorForm == 10" class="alert alert-danger">
    <div class="text-center">
      <p><strong>Nombre de la empresa</strong><code>no válido</code> ó <strong>Vacío</strong></p>
    </div>
  </div> -->
  <ng-container *ngFor="let alert of alertList" [ngTemplateOutlet]="alertTemplate"
                [ngTemplateOutletContext]="{ alert: alert }"></ng-container>
</div>
<div class="col-sm-12 p-30">
  <form>
    <fieldset>
      <div class="row">
        <!-- INPUT FILE CSV -->
        <div class="col-sm-5 form-group" [ngClass]="{'col-sm-12': inputList.length==0, 'col-sm-5': inputList.length>0}">
          <app-gen-ngb-input *ngIf="fileInput"
                             [input]="fileInput"
                             [form]="form"
          ></app-gen-ngb-input>
        </div>
        <div class="col-sm-7">
          <div class="col-sm-12 form-group" *ngFor="let input of inputList">
            <!-- <div [ngClass]="columnClass"> -->
            <app-gen-ngb-input [input]="input" [form]="form">
            </app-gen-ngb-input>
            <!-- </div> -->
          </div>
        </div>

      </div>
    </fieldset>
    <fieldset *ngIf="csvData && csvData.length>0 && csvColumnList && csvColumnList.length>0">
      <div class="datatable col-sm-12">
        <ngx-datatable class="admin-table admvouchers-table data-table selection-cell"
                       [rows]="csvData" [columnMode]="'force'" [sortType]="sortType"
                       [rowHeight]="'auto'" [reorderable]="false" [headerHeight]="50"
                       [footerHeight]="50" [limit]="10" [scrollbarH]="true">
          <ng-container *ngFor="let column of csvColumnList">
            <ngx-datatable-column name="{{column.text}}" prop="{{column.name}}"></ngx-datatable-column>
          </ng-container>
        </ngx-datatable>
        <br><br>
      </div>
    </fieldset>
  </form>
</div>
