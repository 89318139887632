import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
@Component({
    selector: 'app-gen-ngb-input',
    templateUrl: './gen-ngb-input.component.html',
    styleUrls: ['./gen-ngb-input.component.scss',

    ]
})
export class GenNgbInputComponent implements OnInit, OnDestroy, AfterViewInit {

    private subscriptions: Subscription[] = [];

    @Input() input: any = null;
    @Input() form!: UntypedFormGroup;
    active: boolean = false;
    filename: string = '';
    fileLoaded: boolean = false;
    show:boolean =false;

    constructor(
        // private cdr: ChangeDetectorRef
    ) {

    }
    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    onFileChange(event: any) {
        if (this.input.onFileChange == undefined) return;
        const rawFilename = this.form.controls[this.input.name].value;
        this.filename = rawFilename.split('\\').pop();
        this.fileLoaded = true;
        this.input.onFileChange(event);

    }

    ngOnInit() {
        if(this.form!=undefined && this.form.controls[this.input.name]!=undefined && this.input.type === 'file'){
            this.subscriptions.push(
                this.form.controls[this.input.name].valueChanges.subscribe(file=>{
                    console.log("file changes");console.dir(file);
                    if(file==undefined || file==null) this.fileLoaded = false;
                })
            )
        }
    }

    toggleShow(show: boolean){
        this.show = show;
    }

    ngAfterViewInit() {
    }

    setActive(active: boolean) {
        this.active = active;
    }

    selectImage(value: any){
        this.input.selected = value;
        this.form.controls[this.input.name].patchValue(value);
    }
}
